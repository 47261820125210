import { StackLayout } from '@progress/kendo-react-layout';
import { combineClassNames } from '../../services/common';
import { FormFieldProps } from '../ui/inputs';

export function DurationPicker({ id, value, onChange, disabled, valid, predefinedValues }: FormFieldProps<number> & { predefinedValues?: number[] }) {
    const availableValues =
        value === undefined
            ? predefinedValues ?? []
            : predefinedValues
            ? predefinedValues.includes(value)
                ? predefinedValues
                : [...predefinedValues, value].sort()
            : [value];

    return (
        <StackLayout id={id} align={{ horizontal: 'stretch', vertical: 'stretch' }} className="k-gap-2">
            {availableValues.map(v => {
                const isSelected = v === value;
                const canSelectValue = !disabled && !isSelected && Boolean(onChange);

                return (
                    <div
                        key={v}
                        className={combineClassNames(
                            'k-icp-panel k-px-4 k-pt-2 k-pb-4 k-text-center',
                            disabled ? 'k-disabled' : undefined,
                            canSelectValue ? 'k-cursor-pointer k-icp-interactive-card-small' : undefined,
                            isSelected ? '!k-border-secondary' : !valid ? 'k-invalid' : undefined
                        )}
                        onClick={canSelectValue ? () => onChange!({ value: v }) : undefined}
                    >
                        <div className="k-display-4 k-mb--2">{v}</div>
                        <div className="k-icp-subtle-text k-fs-sm k-text-uppercase">minutes</div>
                    </div>
                );
            })}
        </StackLayout>
    );
}
