import { ErrorWithOperationDisplayName } from './common';
import { HttpServiceBase, PagedResponse, RequestMethod } from './httpServiceBase';

export interface MinimalDocument {
    id: number;
    docVersion: number;
    locked: boolean;
}

export interface EditorDocument extends MinimalDocument {
    document: string;
}

export interface DocumentChange {
    id: number;
    sourceVersion: number;
    targetVersion: number;
    steps: string[];
}

export type DocumentsResponse = PagedResponse<'documents', MinimalDocument>;

export interface DocumentChangeRequest {
    steps: string[];
    sourceVersion: number;
}

class DocumentsService extends HttpServiceBase {
    constructor() {
        super('/api/documents');
    }

    @ErrorWithOperationDisplayName('Get documents')
    async getDocuments(ideaId: string, afterId = 0, skip = 0, take = 1000): Promise<DocumentsResponse> {
        return this.performRequest<DocumentsResponse>({
            path: `/${ideaId}/documents`,
            queryParams: {
                afterId: afterId.toString(),
                skip: skip.toString(),
                take: take.toString()
            }
        });
    }

    @ErrorWithOperationDisplayName('Create document')
    async createDocument(ideaId: string): Promise<EditorDocument> {
        return this.performRequest<EditorDocument>({
            path: `/${ideaId}/documents`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Get document')
    async getDocument(ideaId: string, documentId: number): Promise<EditorDocument> {
        return this.performRequest<EditorDocument>({
            path: `/${ideaId}/documents/${documentId}`
        });
    }

    @ErrorWithOperationDisplayName('Delete document')
    deleteDocument(ideaId: string, documentId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/documents/${documentId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Restore document')
    async restoreDocument(ideaId: string, documentId: number): Promise<EditorDocument> {
        return this.performRequest<EditorDocument>({
            path: `/${ideaId}/documents/${documentId}/restore`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Reset document')
    async resetDocument(ideaId: string, documentId: number): Promise<EditorDocument> {
        return this.performRequest<EditorDocument>({
            path: `/${ideaId}/documents/${documentId}/reset`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Get document changes')
    async getDocumentChanges(ideaId: string, documentId: number, sinceVersion = 0): Promise<DocumentChange[]> {
        return this.performRequest<DocumentChange[]>({
            path: `/${ideaId}/documents/${documentId}/changes`,
            queryParams: {
                sinceVersion: sinceVersion.toString()
            }
        });
    }

    @ErrorWithOperationDisplayName('Apply document change')
    async applyDocumentChange(ideaId: string, documentId: number, change: DocumentChangeRequest): Promise<DocumentChange> {
        return this.performRequest<DocumentChange>({
            path: `/${ideaId}/documents/${documentId}/changes`,
            method: RequestMethod.POST,
            body: change
        });
    }
}

export const documentsService = new DocumentsService();
