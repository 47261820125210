import { ErrorWithOperationDisplayName, WithRequired } from './common';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { ReducedUser } from './usersService';

export enum Interview2Type {
    ProblemDiscoveryAndValidation = 'ProblemDiscoveryAndValidation',
    WinLoss = 'WinLoss',
    BuyerPersona = 'BuyerPersona',
    Other = 'Other'
}

export type NewInterviewScript2Data = {
    name?: string;
    durationInMinutes?: number | null;
    interviewType: Interview2Type;
    customerSegmentId: number;
    useTemplate: boolean;
    file?: File;
};

export type InterviewScript2 = Omit<WithRequired<NewInterviewScript2Data, 'name'>, 'useTemplate' | 'file'> & {
    id: number;
    ready: boolean;
    error: boolean;
    createdBy?: ReducedUser | null;
    createdOn: Date;
    updatedBy?: ReducedUser | null;
    updatedOn?: Date | null;
    documentId?: number | null;
    initialDocumentId?: number | null;
    contributors: ReducedUser[];
    interviewsCount?: number;
};

class InterviewScripts2Service extends HttpServiceBase {
    constructor() {
        super('/api/interviews3');
    }

    private static ensureInterviewScriptDateFields(script: InterviewScript2): InterviewScript2 {
        dateTimeService.ensureDateField(script, 'createdOn');
        dateTimeService.ensureDateField(script, 'updatedOn');

        return script;
    }

    @ErrorWithOperationDisplayName('Get interview scripts')
    getScripts(ideaId: string): Promise<InterviewScript2[]> {
        return this.performRequest<InterviewScript2[]>({ path: `/${ideaId}/scripts` }).then(scripts => {
            scripts.forEach(InterviewScripts2Service.ensureInterviewScriptDateFields);
            return scripts;
        });
    }

    @ErrorWithOperationDisplayName('Delete interview script')
    deleteInterviewScript(ideaId: string, scriptId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/scripts/${scriptId}`,
            method: RequestMethod.DELETE
        });
    }

    @ErrorWithOperationDisplayName('Duplicate interview script')
    duplicateInterviewScript(ideaId: string, scriptId: number) {
        return this.performRequest<InterviewScript2>({
            path: `/${ideaId}/scripts/${scriptId}/duplicate`,
            method: RequestMethod.POST
        }).then(InterviewScripts2Service.ensureInterviewScriptDateFields);
    }

    @ErrorWithOperationDisplayName('Get interview script')
    getInterviewScript(ideaId: string, scriptId: number) {
        return this.performRequest<InterviewScript2>({
            path: `/${ideaId}/scripts/${scriptId}`
        }).then(InterviewScripts2Service.ensureInterviewScriptDateFields);
    }

    @ErrorWithOperationDisplayName('Create interview script')
    createInterviewScript(ideaId: string, data: NewInterviewScript2Data) {
        if (data.file) {
            const formData = new FormData();
            Object.entries(data).forEach(
                ([key, value]) =>
                    value !== undefined &&
                    value !== null &&
                    formData.append(key, typeof value === 'number' || typeof value === 'boolean' ? value.toString() : value)
            );

            return this.performRequest<InterviewScript2>({
                path: `/${ideaId}/scripts/from-file`,
                method: RequestMethod.POST,
                body: formData
            }).then(InterviewScripts2Service.ensureInterviewScriptDateFields);
        }

        return this.performRequest<InterviewScript2>({
            path: `/${ideaId}/scripts`,
            method: RequestMethod.POST,
            body: data
        }).then(InterviewScripts2Service.ensureInterviewScriptDateFields);
    }

    @ErrorWithOperationDisplayName('Update interview script name')
    updateInterviewScriptName(ideaId: string, scriptId: number, name: string) {
        return this.performRequest<InterviewScript2>({
            path: `/${ideaId}/scripts/${scriptId}`,
            method: RequestMethod.PUT,
            body: { name }
        }).then(InterviewScripts2Service.ensureInterviewScriptDateFields);
    }

    @ErrorWithOperationDisplayName('Append current user as contributor')
    appendCurrentUserAsContributor(ideaId: string, scriptId: number) {
        return this.performRequest<ReducedUser[]>({
            path: `/${ideaId}/scripts/${scriptId}/contributors`,
            method: RequestMethod.POST
        });
    }

    @ErrorWithOperationDisplayName('Reset interview script')
    resetInterviewScript(ideaId: string, scriptId: number) {
        return this.performRequest<InterviewScript2>({
            path: `/${ideaId}/scripts/${scriptId}/reset`,
            method: RequestMethod.POST
        }).then(InterviewScripts2Service.ensureInterviewScriptDateFields);
    }
}

export const interviewScripts2Service = new InterviewScripts2Service();
