import { Fade, Reveal } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import React, {
    createContext,
    forwardRef,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { useStickToBottom } from 'use-stick-to-bottom';
import { AIAvatar } from '../../components/ai/aiAvatar';
import { ChatTextBox, ChatTextBoxHandle } from '../../components/ai/chatTextBox';
import { InterviewMessageBox } from '../../components/interview/entries/interviewMessageLog';
import { BlockProps, ErrorBlock, GoToJourneyBlock, OptionsBlock, TextBlock, TrackingBlock } from '../../components/ui/chatComponents';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { TypeText } from '../../components/ui/typeText';
import { TypeComponents } from '../../components/ui/typingComponents';
import { TypingDots } from '../../components/ui/typingDots';
import UserAvatar from '../../components/user/userAvatar';
import { ResponsiveGroup, useAsRef, useResponsiveLayout } from '../../hooks/commonHooks';
import { useHiddenStartupSidebar, useStartupLayout } from '../../hooks/startupHooks';
import { ReactComponent as ArrowBottomIcon } from '../../icons/arrow-down.svg';
import { combineClassNames, generateInitials, getPreferredColorIndex } from '../../services/common';
import { domService } from '../../services/domService';
import { Chat, ChatMessage, ChatMessageBlock, ChatMessageBlockType, ChatMessageType, ideaOnboardingService } from '../../services/ideaOnboardingService';
import { RealTimeChatEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { loadIdeaById } from '../../state/idea/ideaSlice';

export const blockComponentMap: Record<ChatMessageBlockType, React.FC<BlockProps>> = {
    [ChatMessageBlockType.Text]: TextBlock,
    [ChatMessageBlockType.Options]: OptionsBlock,
    [ChatMessageBlockType.Canvas]: GoToJourneyBlock,
    [ChatMessageBlockType.GoToJourney]: GoToJourneyBlock,
    [ChatMessageBlockType.Tracking]: TrackingBlock,
    [ChatMessageBlockType.Error]: ErrorBlock
};

const SCROLL_CUSTOM_ANIMATION_PARAMS = {
    damping: 0.8, //default 0.7
    stiffness: 0.07, //default 0.05
    mass: 1.25 //default 1.25
};

interface ChatContextType {
    messages: ChatMessage[];
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export function ChatProvider({ messages, children }: { messages: ChatMessage[]; children: ReactNode }) {
    const chatValue = useMemo(
        () => ({
            messages
        }),
        [messages]
    );
    return <ChatContext.Provider value={chatValue}>{children}</ChatContext.Provider>;
}

export function useChatContext() {
    const context = useContext(ChatContext);
    if (context === undefined) {
        throw new Error('useChatContext must be used within a ChatProvider');
    }
    return context;
}

const SECONDS_TO_WAIT_FOR_FIX = 180;

export function StartupGetStartedPage() {
    useHiddenStartupSidebar();

    const avatarElement = useRef<HTMLDivElement>(null);
    const firstSentenceElementRef = useRef<HTMLSpanElement>(null);
    const secondSentenceElementRef = useRef<HTMLSpanElement>(null);
    const introAnimationRef = useRef<IvaIntroAnimationHandle>(null);
    const currentUser = useAppSelector(state => state.user);
    const { ideaId } = useParams();
    const { setPageContainerClassName } = useStartupLayout();
    const [chat, setChat] = useState<Chat | undefined>(undefined);
    const [streamingMessage, setStreamingMessage] = useState<ChatMessageBlock[]>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { role: ideaRole } = useAppSelector(s => s.idea);
    const [typingEnded, setTypingEnded] = useState(true);
    const [startMorph, setStartMorph] = useState<boolean>();
    const [introAnimationHidden, setIntroAnimationHidden] = useState<boolean>();
    const [fullIntroFinished, setFullIntroFinished] = useState(false);
    const [firstMessageTyped, setFirstMessageTyped] = useState(false);
    const [streamingEnded, setStreamingEnded] = useState(false);
    const responsiveGroup = useResponsiveLayout();
    const isMobile = responsiveGroup === ResponsiveGroup.xs;

    const messages = chat?.messages ?? [];
    // leave splitIntro for backwards compatibility with old intro chat
    // new intro chat should animate the first blocks of the first message after the "Hello" one
    const introText =
        messages.length > 1 && messages[1].type === ChatMessageType.Agent
            ? messages[1].blocks.length > 1
                ? {
                      firstIntroSentence: messages[1].blocks[0].content,
                      secondIntroSentence: messages[1].blocks[1].content,
                      restOfTheIntro:
                          messages[1].blocks.length > 2
                              ? messages[1].blocks
                                    .slice(2)
                                    .map(b => (b.type === ChatMessageBlockType.Text ? b.content : ''))
                                    .join('')
                              : ''
                  }
                : splitIntro((messages[1] as Extract<ChatMessage, { type: ChatMessageType.Agent }>).blocks[0].content)
            : { firstIntroSentence: '', secondIntroSentence: '', restOfTheIntro: '' };

    const triggerAnimation = messages.length < 3;
    const noAnimationMessages = triggerAnimation ? messages.slice(2) : messages.slice(1);
    const { scrollRef, contentRef, scrollToBottom, isAtBottom } = useStickToBottom({
        initial: 'instant' as ScrollBehavior,
        resize: 'instant' as ScrollBehavior
    });
    const textboxRef = useRef<ChatTextBoxHandle>(null);
    const isLastMessageUser = messages.length > 0 && messages[messages.length - 1].type === ChatMessageType.User;
    const isLastMessageUserRef = useAsRef(isLastMessageUser);

    // Add real-time chat updates subscription
    useEffect(() => {
        if (!ideaId || !chat) return;

        async function handleChatUpdate(e: RealTimeChatEventData) {
            if (e.ideaId !== ideaId || e.chatId !== chat!.id) return;

            if (streamingMessage) return;
            const newChat = await ideaOnboardingService.getChat(ideaId!);
            setChat(newChat);
        }

        async function handleChatClosed(e: RealTimeChatEventData) {
            if (e.ideaId !== ideaId || e.chatId !== chat!.id) return;

            await dispatch(loadIdeaById(ideaId));
            setChat(prev => (prev ? { ...prev, closed: true } : undefined));
        }

        realTimeUpdatesEventHub.addEventListener('chat', 'update', handleChatUpdate);
        realTimeUpdatesEventHub.addEventListener('chat', 'close', handleChatClosed);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('chat', 'update', handleChatUpdate);
            realTimeUpdatesEventHub.removeEventListener('chat', 'close', handleChatClosed);
        };
    }, [ideaId, chat, navigate, dispatch, streamingMessage]);

    // Load initial chat
    useEffect(() => {
        if (!ideaId) return;
        async function loadChat(ideaId: string) {
            const chat = await ideaOnboardingService.getChat(ideaId);
            setChat(chat);
        }
        loadChat(ideaId);
    }, [ideaId, setChat]);

    useEffect(() => {
        if (!isLastMessageUserRef.current || !chat || !ideaId) return;

        const fixChat = async () => {
            const generator = ideaOnboardingService.fixChat(ideaId);
            setStreamingMessage([]);
            setTypingEnded(false);
            setStreamingEnded(false);
            for await (const chunk of generator) {
                setStreamingMessage(msgs => [...(msgs ?? []), chunk]);
            }
            setStreamingEnded(true);
        };

        const messages = chat.messages;
        if (!messages?.length) return;
        const lastMessage = messages[messages.length - 1];
        const lastMessageTime = lastMessage.updatedOn ?? lastMessage.createdOn;
        if (!lastMessageTime) return;
        const messageTime = lastMessageTime.getTime();
        if (Date.now() - messageTime < SECONDS_TO_WAIT_FOR_FIX * 1000) return;
        fixChat();
    }, [chat, isLastMessageUserRef, ideaId]);

    useLayoutEffect(() => {
        setPageContainerClassName('k-p-0');

        return () => setPageContainerClassName(undefined);
    }, [setPageContainerClassName]);

    useBlockElementMorph(introAnimationRef.current?.avatarElement, avatarElement.current, !startMorph, () => {
        setIntroAnimationHidden(true);
        setTimeout(() => setFullIntroFinished(true), 300);
    });
    useTextElementMorph(introAnimationRef.current?.titleElement, firstSentenceElementRef.current, !startMorph);
    useTextElementMorph(introAnimationRef.current?.subtitleElement, secondSentenceElementRef.current, !startMorph);

    function splitIntro(introMsg: string) {
        const ivaIntroSentences = introMsg.match(/[^.!?]+(([.!?]+[\s]*)|$)/g)!.map(s => s.trim());
        const firstIntroSentence = ivaIntroSentences[0];
        const secondIntroSentence = ivaIntroSentences[1];
        const restOfTheIntro = [...ivaIntroSentences].slice(2).join(' ');
        return { firstIntroSentence, secondIntroSentence, restOfTheIntro };
    }

    useEffect(() => {
        if (!typingEnded || !streamingMessage || !chat) return;

        setStreamingMessage(undefined);
        const aiMessage: ChatMessage = {
            id: Date.now(),
            blocks: streamingMessage,
            type: ChatMessageType.Agent,
            createdOn: new Date()
        };

        if (streamingMessage.some(block => block.type === ChatMessageBlockType.Error)) return;

        setChat(prev => (prev ? { ...prev, messages: [...prev.messages, aiMessage] } : undefined));
    }, [typingEnded, chat, streamingMessage, setChat]);

    useLayoutEffect(() => {
        if (!typingEnded || (triggerAnimation && !firstMessageTyped) || isLastMessageUser) return;
        textboxRef.current?.focus();
    }, [isLastMessageUser, typingEnded, triggerAnimation, firstMessageTyped]);

    const handleSendMessage = useCallback(
        async (message: string) => {
            if (!chat || !ideaId || !message) return;

            // Add user message
            const userMessage: ChatMessage = {
                id: Date.now(),
                content: message,
                type: ChatMessageType.User,
                user: currentUser,
                createdOn: new Date()
            };

            setChat(prev => (prev ? { ...prev, messages: [...prev.messages, userMessage] } : undefined));

            // Stream AI response
            const generator = ideaOnboardingService.sendUserMessage(ideaId, message);
            setStreamingMessage([]);
            setTypingEnded(false);
            setStreamingEnded(false);
            for await (const chunk of generator) {
                setStreamingMessage(msgs => [...(msgs ?? []), chunk]);
            }
            setStreamingEnded(true);
        },
        [chat, ideaId, currentUser]
    );

    function groupMessageBlocks(blocks: ChatMessageBlock[]) {
        const result: (ChatMessageBlock | ChatMessageBlock[])[] = [];
        let currentTextGroup: ChatMessageBlock[] = [];

        blocks.forEach(block => {
            if (block.type === ChatMessageBlockType.Text) {
                currentTextGroup.push(block);
            } else {
                if (currentTextGroup.length > 0) {
                    result.push(currentTextGroup);
                    currentTextGroup = [];
                }
                result.push(block);
            }
        });

        if (currentTextGroup.length > 0) {
            result.push(currentTextGroup);
        }

        return result;
    }

    const groupedStreamingMessage = streamingMessage ? groupMessageBlocks(streamingMessage) : undefined;
    const firstStreamingMessageTextBlockIdx = groupedStreamingMessage?.findIndex(b => Array.isArray(b));
    return (
        <ChatProvider messages={noAnimationMessages}>
            <StackLayout orientation="vertical" align={{ vertical: 'top', horizontal: 'stretch' }} className="k-h-full ">
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=1.0, minimum-scale=1.0, maximum-scale=1.0" />
                </Helmet>

                <div className="k-overflow-auto k-flex-1 k-px-4" ref={scrollRef}>
                    <div
                        ref={contentRef}
                        className={combineClassNames(
                            'k-stack-layout k-vstack k-align-items-stretch k-gap-4 page-content-middle page-content--xxl k-py-6',
                            isMobile ? 'k-min-h-full k-justify-content-end' : 'k-justify-content-start'
                        )}
                    >
                        {triggerAnimation && introText && (
                            <InterviewMessageBox messageBoxClassName="-maxw-160" avatar={<AIAvatar ref={avatarElement} className="k-shrink-0" />} hideShadow>
                                <span ref={firstSentenceElementRef}>{introText.firstIntroSentence}</span>{' '}
                                <span ref={secondSentenceElementRef}>{introText.secondIntroSentence}</span>{' '}
                                <TypeText paused={!fullIntroFinished} onTypeEnd={() => setTimeout(() => setFirstMessageTyped(true), 500)}>
                                    {introText.restOfTheIntro}
                                </TypeText>
                            </InterviewMessageBox>
                        )}
                        {noAnimationMessages.map((message, msgIndex) => {
                            const groupedMessages = message.type === ChatMessageType.Agent ? groupMessageBlocks(message.blocks) : undefined;
                            const firstTextBlockIdx = groupedMessages?.findIndex(b => Array.isArray(b));
                            return (
                                <InterviewMessageBox
                                    key={msgIndex}
                                    right={message.type === ChatMessageType.User}
                                    avatar={
                                        message.type === ChatMessageType.User ? (
                                            <UserAvatar
                                                className="k-shrink-0"
                                                initials={generateInitials(2, message.user!.firstName, message.user!.lastName)}
                                                picture={message.user!.picture}
                                                colorIndex={getPreferredColorIndex(message.user!.userId)}
                                            />
                                        ) : (
                                            <AIAvatar className="k-shrink-0" />
                                        )
                                    }
                                    hideShadow
                                    messageBoxClassName={message.type === ChatMessageType.User ? 'k-icp-panel-base -maxw-160' : 'k-w-full !k-pr-0'}
                                >
                                    {message.type === ChatMessageType.Agent ? (
                                        groupedMessages!.map((block, blockIdx) => {
                                            if (Array.isArray(block)) {
                                                return (
                                                    <div className="-maxw-160" key={blockIdx}>
                                                        {blockIdx === firstTextBlockIdx!
                                                            ? block
                                                                  .map(b => b.content)
                                                                  .join('')
                                                                  .trimStart()
                                                            : block.map(b => b.content).join('')}
                                                    </div>
                                                );
                                            } else {
                                                const BlockComponent = blockComponentMap[block.type];
                                                return (
                                                    <BlockComponent
                                                        key={blockIdx}
                                                        messageIdx={msgIndex}
                                                        text={block.content}
                                                        readonly={
                                                            (block.type === ChatMessageBlockType.Options && ideaRole === UserRole.Viewer) ||
                                                            //If the chat is not yet finished, buttons should not be active
                                                            (!chat?.closed &&
                                                                (block.type === ChatMessageBlockType.Canvas || block.type === ChatMessageBlockType.GoToJourney))
                                                        }
                                                        onClick={params => {
                                                            if (block.type === ChatMessageBlockType.Options) {
                                                                handleSendMessage(params);
                                                            } else if (
                                                                block.type === ChatMessageBlockType.Canvas ||
                                                                block.type === ChatMessageBlockType.GoToJourney
                                                            ) {
                                                                navigate(`../journey`);
                                                            }
                                                        }}
                                                    />
                                                );
                                            }
                                        })
                                    ) : (
                                        <React.Fragment key={msgIndex}>{message.content}</React.Fragment>
                                    )}
                                </InterviewMessageBox>
                            );
                        })}
                        {(streamingMessage !== undefined || isLastMessageUser) && (
                            <InterviewMessageBox messageBoxClassName="k-w-full !k-pr-0" avatar={<AIAvatar animate className="k-shrink-0" />} hideShadow>
                                {streamingMessage !== undefined && streamingMessage.length > 0 ? (
                                    <>
                                        <TypeComponents
                                            onTypeEnd={() => {
                                                setTypingEnded(true);
                                            }}
                                            waitForMore={!streamingEnded}
                                            WrapperComponent={({ children }) => <div className="-maxw-160">{children}</div>}
                                        >
                                            {groupedStreamingMessage!.map((block, idx) => {
                                                if (Array.isArray(block)) {
                                                    if (idx === firstStreamingMessageTextBlockIdx!) {
                                                        return block
                                                            .map(b => b.content)
                                                            .join('')
                                                            .trimStart();
                                                    }
                                                    return block.map(b => b.content).join('');
                                                }
                                                const BlockComponent = blockComponentMap[block.type];
                                                return <BlockComponent key={idx} readonly text={block.content} />;
                                            })}
                                        </TypeComponents>
                                    </>
                                ) : (
                                    <TypingDots />
                                )}
                            </InterviewMessageBox>
                        )}
                    </div>
                </div>

                {(!triggerAnimation || firstMessageTyped) && (
                    <div className="k-pos-relative k-px-4">
                        <ChatTextBox
                            ref={textboxRef}
                            disabled={!typingEnded || ideaRole === UserRole.Viewer || chat?.closed || isLastMessageUser}
                            onResize={() => {
                                scrollToBottom({ animation: 'instant' as ScrollBehavior });
                            }}
                            textBoxAttributes={{ rows: isMobile ? 1 : 2 }}
                            className={`${isMobile ? 'k-mb-2' : 'k-mb-6'} page-content-middle page-content--xxl`}
                            onSend={message => handleSendMessage(message)}
                        />
                        {!isAtBottom && (
                            <div className="chat-to-bottom-arrow">
                                <Button
                                    type="button"
                                    fillMode="solid"
                                    themeColor="light"
                                    rounded="full"
                                    size="large"
                                    selected
                                    className="!k-p-2 !k-icp-shadow-sm !k-min-w-0 "
                                    onClick={() => scrollToBottom({ animation: { ...SCROLL_CUSTOM_ANIMATION_PARAMS } })}
                                >
                                    <SvgIconButtonContent icon={ArrowBottomIcon}></SvgIconButtonContent>
                                </Button>
                            </div>
                        )}
                    </div>
                )}
                {!introAnimationHidden && triggerAnimation && (
                    <IvaIntroAnimation
                        ref={introAnimationRef}
                        title={introText.firstIntroSentence}
                        subtitle={introText.secondIntroSentence}
                        onCompleted={() => setTimeout(() => setStartMorph(true), 1000)}
                        isMobile={isMobile}
                    />
                )}
            </StackLayout>
        </ChatProvider>
    );
}

type IvaIntroAnimationProps = { title: string; subtitle: string; onCompleted?: () => void; isMobile?: boolean };
type IvaIntroAnimationHandle = { avatarElement: HTMLElement | null; titleElement: HTMLElement | null; subtitleElement: HTMLElement | null };

const IvaIntroAnimation = forwardRef<IvaIntroAnimationHandle, IvaIntroAnimationProps>(function IvaIntroAnimation(
    { title, subtitle, onCompleted, isMobile },
    ref
) {
    const [showContent, setShowContent] = useState(false);
    const [showAvatarBorder, setShowAvatarBorder] = useState(false);
    const [showText, setShowText] = useState(false);
    const [typeTitle, setTypeTitle] = useState(false);
    const [typeSubtitle, setTypeSubtitle] = useState(false);

    useEffect(() => {
        const showContentTimeoutId = setTimeout(() => setShowContent(true), 800);
        return () => clearTimeout(showContentTimeoutId);
    }, []);

    const avatarRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);
    const subtitleRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
        ref,
        () => ({
            get avatarElement() {
                return avatarRef.current;
            },
            get titleElement() {
                return titleRef.current;
            },
            get subtitleElement() {
                return subtitleRef.current;
            }
        }),
        []
    );

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-absolute k-inset-0 k-icp-bg-component k-px-4">
            <Fade transitionEnterDuration={300} onEntered={() => setTimeout(() => setShowAvatarBorder(true), 800)} className="k-overflow-visible">
                {showContent && (
                    <StackLayout key="gg" orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }}>
                        <AIAvatar
                            ref={avatarRef}
                            size={isMobile ? 128 : 184}
                            borderSize="k-border-2"
                            draw
                            iconSize={isMobile ? 80 : 120}
                            hideBorder={!showAvatarBorder}
                            onBorderDrawn={() => setShowText(true)}
                        />
                        <Reveal transitionEnterDuration={100} onEntered={() => setTypeTitle(true)}>
                            {showText && (
                                <StackLayout
                                    orientation="vertical"
                                    align={{ horizontal: 'center', vertical: 'top' }}
                                    className="k-text-center k-pt-12 k-gap-1.5"
                                >
                                    <h1 ref={titleRef} className="k-h1 ai-text-color">
                                        <TypeText mode="letter" reserveSpace paused={!typeTitle} onTypeEnd={() => setTypeSubtitle(true)}>
                                            {title}
                                        </TypeText>
                                    </h1>
                                    <h2 ref={subtitleRef} className="k-h2">
                                        <TypeText mode="letter" reserveSpace paused={!typeSubtitle} onTypeEnd={onCompleted}>
                                            {subtitle}
                                        </TypeText>
                                    </h2>
                                </StackLayout>
                            )}
                        </Reveal>
                    </StackLayout>
                )}
            </Fade>
        </StackLayout>
    );
});

const morphAnimationDuration = 600;
function useBlockElementMorph(avatarSourceElement?: HTMLElement | null, avatarTargetElement?: HTMLElement | null, paused?: boolean, onEnd?: () => void) {
    const onEndRef = useAsRef(onEnd);
    useEffect(() => {
        if (paused || !avatarSourceElement || !avatarTargetElement) return;

        avatarSourceElement.style.transition = `all ${morphAnimationDuration}ms`;
        avatarSourceElement.style.scale = String(avatarTargetElement.clientWidth / avatarSourceElement.clientWidth);

        avatarSourceElement.style.zIndex = '1';
        avatarSourceElement.style.transformOrigin = '0 0';
        const sourceElementOffset = domService.getRelativeOffset(avatarSourceElement, avatarTargetElement.offsetParent);
        avatarSourceElement.style.translate = `${avatarTargetElement.offsetLeft - sourceElementOffset.left}px ${avatarTargetElement.offsetTop -
            sourceElementOffset.top}px`;

        onEndRef.current && avatarSourceElement.addEventListener('transitionend', onEndRef.current, { once: true });
    }, [avatarSourceElement, avatarTargetElement, onEndRef, paused]);
}

function useTextElementMorph(textSourceElement?: HTMLElement | null, textTargetElement?: HTMLElement | null, paused?: boolean) {
    useEffect(() => {
        if (paused || !textSourceElement || !textTargetElement) return;

        const textElementWrapper = document.createElement('div');
        textElementWrapper.style.position = 'fixed';
        // Multiple transitions are defined to avoid breaking the text on multiple lines while animating the size of the element.
        textElementWrapper.style.transition = `all ${morphAnimationDuration}ms ease-out, width ${morphAnimationDuration /
            2}ms ease-in ${morphAnimationDuration / 2}ms, paddingInline ${morphAnimationDuration / 2}ms ease-in ${morphAnimationDuration / 2}ms`;

        const textElementSourceWrapperRect = textSourceElement.getBoundingClientRect();
        textElementWrapper.style.top = textElementSourceWrapperRect.top + 'px';
        textElementWrapper.style.left = textElementSourceWrapperRect.left + 'px';
        textElementWrapper.style.width = textElementSourceWrapperRect.width + 'px';
        textElementWrapper.style.height = textElementSourceWrapperRect.height + 'px';
        textElementWrapper.style.padding = '0px';

        const sourceTextElementCopy = textSourceElement.cloneNode(true) as HTMLElement;
        sourceTextElementCopy.style.display = 'inline';
        sourceTextElementCopy.style.transition = `all ${morphAnimationDuration}ms ease-out, margin-left ${morphAnimationDuration /
            2}ms ease-in ${morphAnimationDuration / 2}ms`;
        textElementWrapper.appendChild(sourceTextElementCopy);
        document.body.appendChild(textElementWrapper);

        textSourceElement.style.visibility = 'hidden';

        // The timeout is added to avoid flickering when the element is removed
        textElementWrapper.addEventListener('transitionend', () => setTimeout(() => document.body.removeChild(textElementWrapper), 20), {
            once: true
        });

        window.requestAnimationFrame(() => {
            const textTargetElementParent = textTargetElement.parentElement;
            if (!textTargetElementParent) throw new Error();

            const textElementTargetWrapperRect = textTargetElementParent.getBoundingClientRect();
            textElementWrapper.style.top = textElementTargetWrapperRect.top + 'px';
            textElementWrapper.style.left = textElementTargetWrapperRect.left + 'px';
            textElementWrapper.style.width = textElementTargetWrapperRect.width + 'px';
            textElementWrapper.style.height = textElementTargetWrapperRect.height + 'px';

            const textTargetElementParentComputedStyles = window.getComputedStyle(textTargetElementParent);
            textElementWrapper.style.padding = textTargetElementParentComputedStyles.padding;

            const textTargetElementParentOffsetLeft = textTargetElementParent.offsetLeft;
            const textTargetElementOffsetLeft = domService.getRelativeOffset(textTargetElement, textTargetElementParent.offsetParent).left;

            const textTargetElementComputedStyles = window.getComputedStyle(textTargetElement);
            sourceTextElementCopy.style.font = textTargetElementComputedStyles.font;
            sourceTextElementCopy.style.color = textTargetElementComputedStyles.color;
            sourceTextElementCopy.style.marginLeft =
                textTargetElementOffsetLeft - textTargetElementParentOffsetLeft - parseFloat(textTargetElementParentComputedStyles.paddingRight) + 'px';
        });
    }, [paused, textSourceElement, textTargetElement]);
}
