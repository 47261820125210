import { ErrorWithOperationDisplayName } from './common';
import { dateTimeService } from './dateTimeService';
import { HttpServiceBase, RequestMethod } from './httpServiceBase';
import { ReducedUser } from './usersService';

export enum ChatMessageType {
    User = 'User',
    Agent = 'Agent'
}

export enum ChatMessageBlockType {
    Text = 'Text',
    Options = 'Options',
    Canvas = 'Canvas',
    GoToJourney = 'GoToJourney',
    Tracking = 'Tracking',
    Error = 'error'
}

export interface ChatMessageBlock {
    id?: number;
    type: ChatMessageBlockType;
    content: string;
}

export type ChatMessage = {
    id?: number;
    createdOn?: Date;
    updatedOn?: Date;
} & (
    | {
          type: ChatMessageType.Agent;
          blocks: ChatMessageBlock[];
      }
    | {
          type: ChatMessageType.User;
          user: ReducedUser | null;
          content: string;
      }
);

export interface Chat {
    id: number;
    tag: string;
    closed: boolean;
    messages: ChatMessage[];
}

class IdeaOnboardingService extends HttpServiceBase {
    constructor() {
        super('/api/chats');
    }

    @ErrorWithOperationDisplayName('Get chat messages')
    async getChatMessages(
        ideaId: string,
        tag: string = 'intro',
        order: 'asc' | 'desc' = 'desc',
        afterId: number = 0,
        skip: number = 0,
        take: number = 10
    ): Promise<{ messages: ChatMessage[]; totalCount: number }> {
        const queryParams = new URLSearchParams();

        this.addQueryParamIfPresent(queryParams, 'order', order);
        this.addQueryParamIfPresent(queryParams, 'afterId', afterId?.toString());
        this.addQueryParamIfPresent(queryParams, 'skip', skip?.toString());
        this.addQueryParamIfPresent(queryParams, 'take', take?.toString());

        const result = await this.performRequest<{ messages: ChatMessage[]; totalCount: number }>({
            path: `/${ideaId}/${tag}/messages`,
            queryParams
        });

        // Ensure dates are properly formatted
        dateTimeService.ensureDateType(m => m, 'createdOn', ...result.messages);
        dateTimeService.ensureDateType(m => m, 'updatedOn', ...result.messages);

        return result;
    }

    @ErrorWithOperationDisplayName('Get chat with messages')
    async getChat(ideaId: string, tag: string = 'intro'): Promise<Chat> {
        const chat = await this.performRequest<Chat>({
            path: `/${ideaId}/${tag}`
        });

        // Ensure dates are properly formatted
        dateTimeService.ensureDateType(m => m, 'createdOn', ...chat.messages);
        dateTimeService.ensureDateType(m => m, 'updatedOn', ...chat.messages);

        return chat;
    }

    @ErrorWithOperationDisplayName('Fix chat')
    async *fixChat(ideaId: string, tag: string = 'intro'): AsyncIterable<ChatMessageBlock> {
        const response = await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/${tag}/fix`,
            method: RequestMethod.POST,
            headers: {
                Accept: 'text/event-stream'
            }
        });

        const eventsIterator = this.processSseResponse(response);
        for await (const event of eventsIterator) yield { type: event.event as ChatMessageBlockType, content: event.data };
    }

    @ErrorWithOperationDisplayName('Send message to chat')
    async *sendUserMessage(ideaId: string, message: string, tag: string = 'intro'): AsyncIterable<ChatMessageBlock> {
        const response = await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/${tag}/messages`,
            method: RequestMethod.POST,
            body: { text: message }, // Updated to match StringDto
            headers: {
                Accept: 'text/event-stream'
            }
        });
        const eventsIterator = this.processSseResponse(response);
        for await (const event of eventsIterator) yield { type: event.event as ChatMessageBlockType, content: event.data };
    }
}

export const ideaOnboardingService = new IdeaOnboardingService();
