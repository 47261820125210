import { pdf } from '@progress/kendo-drawing';
import {
    changeTextBlock,
    formatBlockElements,
    htmlToFragment,
    InputRule,
    inputRules,
    pasteCleanup,
    PasteCleanupSettings,
    removeAttribute,
    replaceImageSourcesFromRtf,
    sanitize
} from '@progress/kendo-editor-common';
import { fragmentToHtml } from '@progress/kendo-editor-common/dist/npm/source';
import { Button } from '@progress/kendo-react-buttons';
import { Editor, EditorPasteEvent, EditorTools, EditorUtils } from '@progress/kendo-react-editor';
import { StackLayout } from '@progress/kendo-react-layout';
import { PageTemplateProps, PDFExportProps, savePDF } from '@progress/kendo-react-pdf';
import { keymap } from 'prosemirror-keymap';
import { Fragment, Node, Schema } from 'prosemirror-model';
import { EditorState, NodeSelection, Plugin, PluginKey, Selection, TextSelection, Transaction } from 'prosemirror-state';
import { ReplaceStep, Step, Transform } from 'prosemirror-transform';
import { Decoration, DecorationSet, DirectEditorProps, EditorView } from 'prosemirror-view';
import { ComponentType, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useAsRef } from '../../hooks/commonHooks';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { debounce, isMacOs } from '../../services/common';
import { DocumentChange, DocumentChangeRequest, documentsService, EditorDocument } from '../../services/documentsService';
import { HttpException } from '../../services/httpServiceBase';
import { RealTimeUpdateDocumentEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { BoundDropDownButton, DropDownButtonItem } from '../common/boundDropDownButton';
import { PopupPropsNestedContextProvider } from '../common/popupPropsNestedContextProvider';

const { Undo, Redo } = EditorTools;

const DEBOUNCE_TIME_MS = 100;

const PLACEHOLDERS_INITIAL = {
    heading: 'New section title...',
    paragraph: 'Type a question here...'
};

const inputRule = () => {
    return inputRules({
        rules: [
            new InputRule(/^\/$/, (state, match, start, end) => {
                const tr = state.tr;
                const currentNode = state.selection.$anchor.parent;
                changeTextBlock(tr, currentNode, state.schema.nodes.heading, { ...currentNode.attrs, level: 2 });

                if (tr.docChanged) {
                    return tr;
                }
                return null;
            })
        ]
    });
};

function preventHardBreaksPlugin() {
    return new Plugin({
        key: new PluginKey('preventHardBreaks'),
        filterTransaction(transaction, editorState) {
            let containsHardBreak = false;
            transaction.steps.forEach(step => {
                if (step instanceof ReplaceStep) {
                    const slice = (step as any).slice;
                    if (slice.content.content.some((node: any) => node.type.name === 'hard_break')) {
                        containsHardBreak = true;
                    }
                }
            });

            if (containsHardBreak) {
                return false;
            }

            return true;
        }
    });
}

function getNodeAtCursor(state: EditorState) {
    const indexInDoc = state.selection.$head.index(0);
    return state.doc.maybeChild(indexInDoc);
}

function getNodeBeforeCursor(state: EditorState) {
    const indexInDoc = state.selection.$head.index(0);
    return state.doc.maybeChild(indexInDoc - 1);
}

function getNodeAfterCursor(state: EditorState) {
    const indexInDoc = state.selection.$head.index(0);
    return state.doc.maybeChild(indexInDoc + 1);
}

const handleBackspaceDeleteEnterPlugin = keymap({
    Backspace: (state, dispatch, view) => {
        if (!state.selection.empty) {
            return false;
        }

        const { $head } = state.selection;
        const currentNode = getNodeAtCursor(state);
        const previousNode = getNodeBeforeCursor(state);

        if (!currentNode || !previousNode) {
            return false;
        }

        // If we are at the end of an empty element, allow default behavior.
        if (currentNode.content.size === 0) {
            return false;
        }

        // When between heading and paragraph instead of merging (deleting), move the caret at the end of the previous element.
        if (currentNode.type.name !== previousNode.type.name && $head.parentOffset === 0) {
            const newPos = state.selection.from - 2;
            const $pos = state.doc.resolve(newPos);
            const tr = state.tr.setSelection(TextSelection.near($pos));
            dispatch!(tr);

            return true;
        }

        return false;
    },
    Delete: (state, dispatch, view) => {
        if (!state.selection.empty) {
            return false;
        }

        const { $head } = state.selection;
        const currentNode = getNodeAtCursor(state);
        const nextNode = getNodeAfterCursor(state);
        if (!currentNode || !nextNode) {
            return false;
        }

        // If we are at the end of an empty heading, allow default behavior UNLESS it is the first in the document.
        if (currentNode.type.name === 'heading' && currentNode.content.size === 0 && $head.index(0) !== 0) {
            return false;
        }

        // If we are at teh end of an empty paragraph, always allow to delete it.
        if (currentNode.type.name === 'paragraph' && currentNode.content.size === 0) {
            return false;
        }

        // When between different node types and the cursor is at the end of the current node,
        // move the caret to the beginning of the next element instead of merging (deleting) content.
        if (currentNode.type.name !== nextNode.type.name && $head.parentOffset === currentNode.nodeSize - 2) {
            const newPos = state.selection.from + 1;
            const $pos = state.doc.resolve(newPos);
            const tr = state.tr.setSelection(TextSelection.near($pos));
            dispatch!(tr);
            return true;
        }

        return false;
    },
    Enter: (state, dispatch, view) => {
        if (!state.selection.empty) {
            return false;
        }

        const { $head } = state.selection;

        const currentNode = getNodeAtCursor(state);
        const nextNode = getNodeAfterCursor(state);
        if (!currentNode || !nextNode) {
            return false;
        }

        // If we are at the end of a heading and the next element is an empty paragraph, move the caret in it instead of adding new line.
        if (
            currentNode.type.name === 'heading' &&
            $head.parentOffset === currentNode.nodeSize - 2 &&
            nextNode.type.name === 'paragraph' &&
            nextNode.content.size === 0
        ) {
            const newPos = state.selection.from + 1;
            const $pos = state.doc.resolve(newPos);
            const tr = state.tr.setSelection(TextSelection.near($pos));
            dispatch!(tr);

            return true;
        }

        return false;
    }
});

function generateSectionsAndQuestionsPlugin() {
    return new Plugin({
        key: new PluginKey('generateSectionsAndQuestions'),

        appendTransaction(transactions, oldState, newState) {
            // Check if document is empty
            const doc = newState.doc;
            const tr = newState.tr;
            const isEmpty = doc.childCount === 1 && doc.textContent === '';
            const firstChildIsHeading = doc.firstChild && doc.firstChild.type.name === 'heading';

            if (isEmpty || !firstChildIsHeading) {
                const heading = newState.schema.nodes.heading.create({ level: 2 });
                tr.insert(0, heading);
                tr.setSelection(TextSelection.create(tr.doc, 0));
            }

            // let lastInsertedNodePos: number | undefined;
            newState.doc.descendants((node, pos, parent, index) => {
                const afterSiblingNode = parent?.maybeChild(index + 1);
                if (node.type.name === 'heading' && (!afterSiblingNode || afterSiblingNode.type.name !== 'paragraph')) {
                    const paragraphNodeType = newState.schema.nodes.paragraph;
                    if (EditorUtils.canInsert(newState, paragraphNodeType)) {
                        const paragraph = paragraphNodeType.create(null);
                        tr.insert(tr.mapping.map(pos + node.nodeSize), paragraph);
                    }
                    // lastInsertedNodePos = pos + node.nodeSize;
                }
            });

            //TODO FIX autofocus. The problem might not be here.
            // if (lastInsertedNodePos) {
            //     tr.setSelection(TextSelection.create(tr.doc, tr.mapping.map(lastInsertedNodePos)));
            // }

            return tr;
        }
    });
}

function addPlaceholdersPlugin() {
    return new Plugin({
        key: new PluginKey('addPlaceholders'),

        props: {
            decorations: state => {
                const { doc } = state;
                const decorations: Array<any> = [];

                doc.descendants((node, pos, parent, index) => {
                    const beforeSiblingNode = parent?.maybeChild(index - 1);
                    const isNodeSelection = state.selection instanceof NodeSelection;

                    if (node.type.name === 'heading' && node.textContent === '') {
                        decorations.push(
                            Decoration.node(pos, pos + node.nodeSize, {
                                class: 'placeholder',
                                'data-placeholder': PLACEHOLDERS_INITIAL[node.type.name as keyof typeof PLACEHOLDERS_INITIAL]
                            })
                        );
                    } else if (
                        node.type.name === 'paragraph' &&
                        node.textContent === '' &&
                        beforeSiblingNode != null &&
                        beforeSiblingNode.type.name === 'heading'
                    ) {
                        decorations.push(
                            Decoration.node(pos, pos + node.nodeSize, {
                                class: 'placeholder',
                                'data-placeholder': PLACEHOLDERS_INITIAL[node.type.name as keyof typeof PLACEHOLDERS_INITIAL]
                            })
                        );
                    } else if (
                        node.textContent === '' &&
                        ((!isNodeSelection && state.selection.$head.pos === state.selection.$anchor.pos && state.selection.$anchor.pos - 1 === pos) ||
                            (isNodeSelection && state.selection.anchor === pos))
                    ) {
                        decorations.push(
                            Decoration.widget(pos, () => {
                                const placeholderContent = document.createElement('span');
                                placeholderContent.className = 'placeholder-q-or-s';
                                placeholderContent.innerHTML =
                                    "Type a question or press <span class='placeholder-q-or-s-slash'></span> to start typing section title...";
                                return placeholderContent;
                            })
                        );
                    }
                });

                return DecorationSet.create(doc, decorations);
            }
        }
    });
}

interface SideButton {
    nodeIdx: number;
    domElement?: HTMLElement;
}

export interface BookmarkTitle {
    title: string;
}

enum SCRIPT_EDITOR_STATE {
    SENDING_CHANGES = 'SENDING_CHANGES',
    REBASING_SERVER_CHANGES = 'REBASING_SERVER_CHANGES',
    WAITING_FOR_CHANGES = 'WAITING_FOR_CHANGES'
}

export type EditorToolProps = { view?: EditorView; dir: string };
export type EditorTool = ComponentType<EditorToolProps>;

export enum ChangesStatus {
    PendingChanges = 'PendingChanges',
    SendingChanges = 'SavingChanges',
    ChangesSaved = 'ChangesSaved'
}
interface InterviewScriptDocumentEditorProps {
    ideaId: string;
    editorDocument: EditorDocument;
    handleNewTitles?: (titles: BookmarkTitle[]) => void;
    handleSectionIdxChanged?: (sectionIdx: number) => void;
    onSavingStatusChange?: (status: ChangesStatus) => void;
    exportTitle?: string;
    readonly?: boolean;
    additionalTools?: (EditorTool | EditorTool[])[];
}

export interface InterviewScriptDocumentEditorRef {
    selectSection: (sectionIdx: number) => void;
}

export const InterviewScriptDocumentEditor = forwardRef<InterviewScriptDocumentEditorRef, InterviewScriptDocumentEditorProps>((props, ref) => {
    const { ideaId, editorDocument, handleNewTitles, handleSectionIdxChanged, onSavingStatusChange, exportTitle, readonly, additionalTools } = props;

    const editorViewRef = useRef<EditorView | null>(null);

    useImperativeHandle(
        ref,
        () => ({
            selectSection: (sectionIdx: number) => {
                if (!editorViewRef.current) return;
                const newTransaction = editorViewRef.current.state.tr;
                const state = editorViewRef.current.state;
                const hNodePosition = sectionIndexToDocPos(state.doc, sectionIdx);
                const selection = TextSelection.create(state.doc, hNodePosition + 1);
                const tr = newTransaction.setSelection(selection).scrollIntoView();
                editorViewRef.current.dispatch(tr);
                editorViewRef.current.focus();

                const coords = editorViewRef.current.coordsAtPos(hNodePosition);
                const toolbar = document.querySelector('.k-editor-toolbar');
                const marginPx = 12;
                if (toolbar) {
                    const toolbarOffset = toolbar.getBoundingClientRect().bottom;
                    editorViewRef.current.dom.scrollBy({
                        top: coords.top - toolbarOffset - marginPx,
                        behavior: 'smooth'
                    });
                } else {
                    editorViewRef.current.dom.scrollBy({
                        top: coords.top - marginPx,
                        behavior: 'smooth'
                    });
                }
            }
        }),
        []
    );

    const stepsToSendRef = useRef<Step[]>([]);
    const onSavingStatusChangeRef = useAsRef(onSavingStatusChange);
    const scriptEditorStateRef = useRef(SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES);
    const [sideButtons, setSideButtons] = useState<SideButton[] | undefined>(undefined);
    const [isMounted, setIsMounted] = useState(false);

    const pasteSettings: PasteCleanupSettings = {
        convertMsLists: true,
        attributes: {
            '*': removeAttribute
        }
    };

    useEffect(() => {
        if (!isMounted) return;

        const handleKeyDownEvent = (event: KeyboardEvent) => {
            const view = editorViewRef.current;
            if (!view || readonly) return;

            if (
                (!isMacOs && event.ctrlKey && !event.shiftKey && event.code === 'Slash') ||
                (isMacOs && event.metaKey && !event.shiftKey && event.code === 'Slash')
            ) {
                event.preventDefault();
                switchHeadingAndQuestionAtSelection(true, view);
            } else if (
                (!isMacOs && event.ctrlKey && event.shiftKey && event.code === 'Slash') ||
                (isMacOs && event.metaKey && event.shiftKey && event.code === 'Slash')
            ) {
                event.preventDefault();
                switchHeadingAndQuestionAtSelection(false, view);
            } else if ((!isMacOs && event.ctrlKey && event.code === 'KeyD') || (isMacOs && event.metaKey && event.code === 'KeyD')) {
                event.preventDefault();
                duplicateNodeAtSelection(view);
            } else if (event.code === 'Delete') {
                deleteNodeAtSelection(view);
            }
        };

        document.addEventListener('keydown', handleKeyDownEvent);

        return () => {
            document.removeEventListener('keydown', handleKeyDownEvent);
        };
    }, [isMounted, readonly]);

    const sendLatestChanges = useCallback(
        async (view: EditorView, stepsToSend: Step[]) => {
            const docVersion = getVersion(view.state);
            const changeRequest: DocumentChangeRequest = {
                sourceVersion: docVersion,
                steps: stepsToSend.map(step => JSON.stringify(step.toJSON()))
            };

            const change = await documentsService.applyDocumentChange(ideaId, editorDocument.id, changeRequest);
            const tr = clearUncommittedAndUpdateVersion(view.state, change);
            scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES;
            view.dispatch(tr);
        },
        [editorDocument.id, ideaId]
    );

    const rebaseServerChanges = useCallback(
        async (view: EditorView) => {
            const docVersion = getVersion(view.state);
            const currentChanges = await documentsService.getDocumentChanges(ideaId, editorDocument.id, docVersion);
            if (currentChanges.length === 0) {
                scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES;
                view.dispatch(receiveTransaction(view.state, [], docVersion));
                return;
            }
            const newSteps = currentChanges.flatMap(change => change.steps.map(stepJson => Step.fromJSON(view.state.schema, JSON.parse(stepJson))));
            const targetVersion = currentChanges[currentChanges.length - 1].targetVersion;
            const tr = receiveTransaction(view.state, newSteps, targetVersion);

            scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES;
            view.dispatch(tr);
        },
        [ideaId, editorDocument.id]
    );

    const debouncedSendLatestChanges = useMemo(
        () =>
            debounce(async (view: EditorView) => {
                if (stepsToSendRef.current.length === 0 || scriptEditorStateRef.current !== SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES) return;

                onSavingStatusChangeRef.current?.(ChangesStatus.SendingChanges);
                scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.SENDING_CHANGES;
                const stepsToSend = stepsToSendRef.current;
                try {
                    await sendLatestChanges(view, stepsToSend);
                } catch (error) {
                    if (error instanceof HttpException && error.status === 409) {
                        scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.REBASING_SERVER_CHANGES;
                        await rebaseServerChanges(view);
                        scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES;
                    } else {
                        throw error;
                    }
                } finally {
                    scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES;
                    onSavingStatusChangeRef.current?.(ChangesStatus.ChangesSaved);
                }
            }, DEBOUNCE_TIME_MS),
        [onSavingStatusChangeRef, rebaseServerChanges, sendLatestChanges]
    );

    useEffect(() => {
        const handleDocumentUpdated = (e: RealTimeUpdateDocumentEventData) => {
            if (!editorViewRef.current || e.ideaId !== ideaId || e.documentId !== editorDocument?.id) return;
            if (scriptEditorStateRef.current !== SCRIPT_EDITOR_STATE.WAITING_FOR_CHANGES) return;
            scriptEditorStateRef.current = SCRIPT_EDITOR_STATE.REBASING_SERVER_CHANGES;
            rebaseServerChanges(editorViewRef.current);
        };

        realTimeUpdatesEventHub.addEventListener('document', 'update', handleDocumentUpdated);
        return () => {
            realTimeUpdatesEventHub.removeEventListener('document', 'update', handleDocumentUpdated);
        };
    }, [ideaId, editorDocument?.id, rebaseServerChanges]);

    const readonlyRef = useAsRef(readonly);
    const onMount = (event: { viewProps: DirectEditorProps; dom: any }) => {
        const { viewProps } = event;
        const { plugins, schema } = viewProps.state;
        if (readonly) viewProps.editable = () => !readonlyRef.current;

        const filteredNodes = schema.spec.nodes
            .remove('div')
            .remove('blockquote')
            .remove('horizontal_rule')
            .remove('code_block')
            .remove('image')
            .remove('ordered_list')
            .remove('bullet_list')
            .remove('list_item')
            .remove('table')
            .remove('table_row')
            .remove('table_cell')
            .remove('table_header');

        const filteredMarks = schema.spec.marks
            .remove('link')
            // .remove('strong')
            .remove('b')
            // .remove('em')
            .remove('i')
            // .remove('u')
            .remove('del')
            .remove('sub')
            .remove('sup')
            .remove('code')
            .remove('style');

        const textSchema = new Schema({
            nodes: filteredNodes,
            marks: filteredMarks
        });

        const doc = Node.fromJSON(textSchema, JSON.parse(editorDocument.document));
        const customPlugins = [handleBackspaceDeleteEnterPlugin, ...plugins, inputRule(), preventHardBreaksPlugin()];
        if (!readonly) {
            customPlugins.push(generateSectionsAndQuestionsPlugin(), addPlaceholdersPlugin());
        }
        if (handleNewTitles) customPlugins.push(collectTitlesPlugin(handleNewTitles, handleSectionIdxChanged));
        if (!readonly) customPlugins.push(addSideButtonsPlugin());
        customPlugins.push(collab({ version: editorDocument.docVersion }));
        const editorView = new EditorView(
            {
                mount: event.dom
            },
            {
                ...event.viewProps,
                state: EditorState.create({
                    doc,
                    plugins: customPlugins
                }),

                dispatchTransaction(transaction) {
                    const view = editorViewRef.current;
                    if (!view) return;
                    let newState = view.state.apply(transaction);
                    view.updateState(newState);
                    let sendable = sendableSteps(newState);
                    stepsToSendRef.current = sendable ? [...sendable.steps] : [];
                    if (sendable) {
                        onSavingStatusChange?.(ChangesStatus.PendingChanges);
                        debouncedSendLatestChanges(view);
                    }
                }
            }
        );

        editorView.focus();
        editorViewRef.current = editorView;
        setIsMounted(true);
        return editorView;
    };

    function generateDecorations(sideButtons: SideButton[], doc: Node) {
        const decorations: Decoration[] = sideButtons.map(pos => {
            const nodePositionInDoc = nodePositionInDocByIndex(doc, pos.nodeIdx);
            if (nodePositionInDoc === null) throw new Error('Node position in doc not found');
            const decWidget = Decoration.widget(
                nodePositionInDoc + 1,
                () => {
                    const span = document.createElement('span');
                    span.className = `side-button-marker`;
                    span.dataset.nodeIndex = pos.nodeIdx.toString();

                    sideButtons.forEach(btn => {
                        if (btn.nodeIdx === pos.nodeIdx) {
                            btn.domElement = span;
                        }
                    });
                    setSideButtons(sideButtons);
                    return span;
                },
                { key: `side-button-${pos.nodeIdx}` }
            );

            return decWidget;
        });

        return decorations;
    }

    function collectTitlesPlugin(onTitlesChanged: (titles: BookmarkTitle[]) => void, onSelectionInSectionChanged?: (sectionIdx: number) => void) {
        return new Plugin({
            key: new PluginKey('collectTitles'),
            state: {
                init(config, instance) {
                    const state = instance;
                    if (!state) return;
                    const titles = getTitles(state.doc);
                    onTitlesChanged(titles);
                },
                apply(tr, value, oldState, newState) {
                    if (tr.docChanged) {
                        const titles = getTitles(newState.doc);
                        onTitlesChanged(titles);
                    } else {
                        if (!onSelectionInSectionChanged) return;
                        const selection = newState.selection;
                        const sectionIdx = getSectionIdxOfSelection(newState.doc, selection);
                        onSelectionInSectionChanged(sectionIdx);
                    }
                }
            }
        });
    }

    function addSideButtonsPlugin() {
        const pluginKey = new PluginKey('sideButtons');

        function collectSideButtons(state: EditorState) {
            const positions: SideButton[] = [];
            state.doc.content.forEach((node, offset, index) => {
                positions.push({
                    nodeIdx: index,
                    domElement: document.querySelector(`.side-button-marker[data-node-index="${index}"]`) as HTMLElement
                });
            });
            return positions;
        }

        return new Plugin({
            key: pluginKey,
            state: {
                init(config, instance) {
                    const state = instance;
                    if (!state) return DecorationSet.empty;
                    const sideBtnPositions = collectSideButtons(state);
                    const initialDecorations = generateDecorations(sideBtnPositions, state.doc);
                    return DecorationSet.create(state.doc, initialDecorations);
                },
                apply(tr, value, oldState, newState) {
                    if (tr.docChanged) {
                        const sideBtnPositions = collectSideButtons(newState);
                        const decorations = generateDecorations(sideBtnPositions, newState.doc);
                        return DecorationSet.create(newState.doc, decorations);
                    }
                    return value;
                }
            },

            props: {
                decorations: state => {
                    return pluginKey.getState(state) as DecorationSet;
                }
            }
        });
    }

    const PdfExportTool = usePdfExportTool(exportTitle);
    return (
        <>
            <Editor
                className="interview-script-editor !k-border-0 k-h-full"
                tools={readonly ? additionalTools : [[Undo, Redo], [SectionTitleTool, QuestionTool], PdfExportTool, ...(additionalTools ?? [])]}
                onPasteHtml={(event: EditorPasteEvent) => {
                    let html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings);
                    const fragment = htmlToFragment(html);

                    const headings = fragment.querySelectorAll('h1, h3, h4, h5, h6');
                    headings.forEach(heading => {
                        const h2 = document.createElement('h2');
                        h2.innerHTML = heading.innerHTML;
                        heading.parentNode?.replaceChild(h2, heading);
                    });

                    html = fragmentToHtml(fragment);

                    // If the pasted HTML contains images with sources pointing to the local file system,
                    // `replaceImageSourcesFromRtf` will extract the sources from the RTF and place them to images 'src' attribute in base64 format.
                    if (event.nativeEvent.clipboardData) {
                        html = replaceImageSourcesFromRtf(html, event.nativeEvent.clipboardData);
                    }

                    return html;
                }}
                defaultContent="<p>Select any text and Bold, Italic and Underline tools will appear above the selection.</p>"
                defaultEditMode="div"
                onMount={onMount}
            />

            {editorViewRef && <SideButtons viewRef={editorViewRef} sideButtons={sideButtons} />}
        </>
    );
});

const SideMenuButton = ({ viewRef, sideButton }: { viewRef: React.RefObject<EditorView>; sideButton: SideButton }) => {
    const view = viewRef.current;

    if (!view) return null;

    function createNodeSelection(view: EditorView) {
        const doc = view.state.doc;
        const nodeIdx = nodePositionInDocByIndex(doc, sideButton.nodeIdx);
        if (nodeIdx === null) return;
        const selection = NodeSelection.create(doc, nodeIdx);
        view.dispatch(view.state.tr.setSelection(selection));
    }

    const ctrlKey = isMacOs ? '⌘' : 'Ctrl';
    const shiftKey = isMacOs ? '⇧' : 'Shift';

    const actions: DropDownButtonItem[] = [
        {
            action: () => {
                switchHeadingAndQuestionAtSelection(true, view);
            },
            children: <SideMenuButtonContent text="Format as Section" sideText={`${ctrlKey}+/`} />
        },
        {
            action: () => {
                switchHeadingAndQuestionAtSelection(false, view);
            },
            children: <SideMenuButtonContent text="Format as Question" sideText={`${ctrlKey}+${shiftKey}+/`} />
        },
        { separated: true, action: () => duplicateNodeAtSelection(view), children: <SideMenuButtonContent text="Duplicate" sideText={`${ctrlKey}+D`} /> },
        {
            danger: true,
            separated: true,
            action: () => deleteNodeAtSelection(view),
            children: <SideMenuButtonContent text="Delete" sideText={`Del`} />
        }
    ];

    return (
        <BoundDropDownButton
            popupSettings={{
                popupClass: 'interview-script-document-side-popup',
                anchorAlign: { horizontal: 'left', vertical: 'center' },
                popupAlign: { horizontal: 'right', vertical: 'center' }
            }}
            items={actions}
            size="small"
            onOpen={() => {
                createNodeSelection(view);
            }}
            fillMode="flat"
            icon="more-horizontal"
            className="side-menu-button k-mr-0.5"
        />
    );
};

function nodePositionInDocByNode(doc: Node, node: Node): number | null {
    let nodePositionInDoc: number | null = null;
    doc.nodesBetween(0, doc.content.size, (curNode, pos, parent, index) => {
        if (curNode === node) {
            nodePositionInDoc = pos;
        }

        return false;
    });

    return nodePositionInDoc;
}

function nodePositionInDocByIndex(doc: Node, nodeIdx: number): number | null {
    const node = doc.content.child(nodeIdx);
    return nodePositionInDocByNode(doc, node);
}

const SideMenuButtonContent = ({ text, sideText }: { text: string; sideText?: string }) => {
    return (
        <StackLayout align={{ vertical: 'middle', horizontal: 'start' }} className="k-gap-1 k-justify-content-between k-w-full">
            <span>{text}</span>
            {sideText && (
                <div className="k-icp-subtle-text k-text-right" style={{ width: '56px', fontSize: '9px', lineHeight: '18px' }}>
                    {sideText}
                </div>
            )}
        </StackLayout>
    );
};

const SideButtons = ({ sideButtons, viewRef }: { sideButtons?: SideButton[]; viewRef: React.RefObject<EditorView> }) => {
    const mainPageContentSection = useMemo(() => document.querySelector('.k-window'), []);
    if (!sideButtons) return null;
    if (!mainPageContentSection) return null;
    return (
        <PopupPropsNestedContextProvider value={props => ({ ...props, collision: { horizontal: 'flip', vertical: 'fit' } })}>
            {sideButtons.map((sideBtn, index) => {
                if (!sideBtn.domElement) return null;

                return ReactDOM.createPortal(<SideMenuButton sideButton={sideBtn} viewRef={viewRef} />, sideBtn.domElement, `side-button-${index}`);
            })}
        </PopupPropsNestedContextProvider>
    );
};

const PdfExportTool = (scriptTitle: string | null | undefined, props: any) => {
    const { view }: { view: EditorView } = props;

    scriptTitle = scriptTitle || 'Interview Script';

    const pdfExportProps: PDFExportProps = {
        fileName: `${scriptTitle.toLocaleLowerCase().replaceAll(' ', '-')}.pdf`,
        title: scriptTitle,
        creator: 'Icanpreneur (https://www.icanpreneur.com)',
        margin: '1in',
        paperSize: 'A4',
        date: new Date(),
        pageTemplate: (props: PageTemplateProps) => {
            return (
                <>
                    {/* Header */}
                    <div
                        className="k-display-flex k-justify-content-between k-fs-xs k-align-items-center k-pos-absolute"
                        style={{ top: '20px', left: '70px', right: '70px' }}
                    >
                        <div>
                            <Logo className="top-nav-logo-image" />
                        </div>
                        <div className="k-text-disabled">
                            <a href="https://www.icanpreneur.com">www.icanpreneur.com</a>
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="k-display-flex k-justify-content-center k-text-disabled k-fs-xs k-mt-5">
                        {props.pageNum} of {props.totalPages}
                    </div>
                </>
            );
        }
    };

    const onClick = () => {
        view.dom.classList.add('k-pdf-export');

        pdf.defineFont({
            'sans-serif': process.env.PUBLIC_URL + '/fonts/Roboto/Roboto-Regular.ttf',
            'sans-serif|Bold': process.env.PUBLIC_URL + '/fonts/Roboto/Roboto-Bold.ttf',
            'sans-serif|Italic': process.env.PUBLIC_URL + '/fonts/Roboto/Roboto-Italic.ttf',
            'sans-serif|BoldItalic': process.env.PUBLIC_URL + '/fonts/Roboto/Roboto-BoldItalic.ttf'
        });
        savePDF(view.dom, pdfExportProps);
        view.dom.classList.remove('k-pdf-export');
    };

    return <Button iconClass="k-icon k-i-file-pdf k-button-icon" title="Export as PDF" onClick={onClick} />;
};

function usePdfExportTool(scriptTitle: string | null | undefined) {
    return useMemo(() => PdfExportTool.bind(undefined, scriptTitle), [scriptTitle]);
}

const QuestionTool = (props: any) => {
    const { view }: { view: EditorView } = props;
    const onClick = () => {
        formatBlockElements('p', 'QuestionTool')(view.state, view.dispatch);
        view.focus();
    };
    return <Button onClick={onClick}>Question</Button>;
};

const SectionTitleTool = (props: any) => {
    const { view }: { view: EditorView } = props;

    const onClick = () => {
        formatBlockElements('h2', 'SectionTitleTool')(view.state, view.dispatch);
        view.focus();

        // EditorUtils.applyInlineStyle(view, { style: 'background-color', value: colorToApply });
    };

    return <Button onClick={onClick}>Section title</Button>;
};

function getTitles(doc: Node) {
    const titles: BookmarkTitle[] = [];
    doc.descendants((node, pos) => {
        if (node.type.name !== 'heading') return;
        titles.push({ title: node.textContent });
    });
    return titles;
}

function nodeAtSelection(selection: Selection) {
    let selectedNode: Node | null = null;
    if (selection instanceof NodeSelection) {
        selectedNode = selection.node;
    } else {
        selectedNode = selection.$anchor.parent;
    }
    return selectedNode;
}

function sectionIndexToDocPos(doc: Node, sectionIdx: number) {
    let resultNodePosition = -1;
    let curHeadingIdx = 0;
    let found = false;
    doc.nodesBetween(0, doc.content.size, (node, pos, parent, index) => {
        if (node.type.name === 'heading' && curHeadingIdx === sectionIdx && !found) {
            resultNodePosition = pos;
            found = true;
            return false;
        }

        if (node.type.name === 'heading') {
            curHeadingIdx++;
        }
        return false;
    });

    return resultNodePosition;
}

function getSectionIdxOfSelection(doc: Node, selection: Selection) {
    const selectedNode = nodeAtSelection(selection);
    let resultHeadingIdx: number = -1;
    let curHeadingIdx = -1;

    doc.nodesBetween(0, doc.content.size, (node, pos, parent, index) => {
        if (node === selectedNode && node.type.name === 'heading') {
            resultHeadingIdx = curHeadingIdx + 1;
            return false;
        }

        if (node === selectedNode) {
            resultHeadingIdx = curHeadingIdx;
            return false;
        }

        if (node.type.name === 'heading') {
            curHeadingIdx++;
        }
        return false;
    });
    return resultHeadingIdx;
}

function switchHeadingAndQuestionAtSelection(toHeading: boolean, view: EditorView) {
    const newTransaciton = view.state.tr;
    const selectedNode = nodeAtSelection(view.state.selection);
    if (!selectedNode) return;
    const schema = view.state.schema;
    const switchType = toHeading ? schema.nodes.heading : schema.nodes.paragraph;
    const switchAttrs = toHeading ? { ...selectedNode.attrs, level: 2 } : {};
    changeTextBlock(newTransaciton, selectedNode, switchType, switchAttrs);
    if (newTransaciton.docChanged) {
        view.dispatch(newTransaciton);
        view.focus();
    }
}

function duplicateNodeAtSelection(view: EditorView) {
    const selection = view.state.selection;

    const selectedNode = nodeAtSelection(selection);

    //COPY fragment to generate node with different reference
    let copiedContent: Fragment | null = Fragment.fromJSON(view.state.schema, selectedNode.content.toJSON());
    if (copiedContent.size === 0) {
        copiedContent = null;
    }

    let baseNodePosition = selection instanceof NodeSelection ? selection.$anchor.pos : selection.$anchor.pos - selection.$anchor.parentOffset - 1;

    const newNode = selectedNode.copy(copiedContent);
    const tr = view.state.tr.insert(baseNodePosition + selectedNode.nodeSize, newNode);
    view.dispatch(tr);
    view.focus();
}

function deleteNodeAtSelection(view: EditorView) {
    const tr = view.state.tr.deleteSelection();
    view.dispatch(tr);
    view.focus();
}

class Rebaseable {
    constructor(readonly step: Step, readonly inverted: Step, readonly origin: Transform) {}
}

/// Undo a given set of steps, apply a set of other steps, and then redo them
function rebaseSteps(steps: readonly Rebaseable[], over: readonly Step[], transform: Transform) {
    for (let i = steps.length - 1; i >= 0; i--) transform.step(steps[i].inverted);
    for (let i = 0; i < over.length; i++) transform.step(over[i]);
    let result = [];
    for (let i = 0, mapFrom = steps.length; i < steps.length; i++) {
        let mapped = steps[i].step.map(transform.mapping.slice(mapFrom));
        mapFrom--;
        if (mapped && !transform.maybeStep(mapped).failed) {
            (transform.mapping as any).setMirror(mapFrom, transform.steps.length - 1);
            result.push(new Rebaseable(mapped, mapped.invert(transform.docs[transform.docs.length - 1]), steps[i].origin));
        }
    }
    return result;
}

class CollabState {
    constructor(readonly version: number, readonly unconfirmed: readonly Rebaseable[]) {}
}

function unconfirmedFrom(transform: Transform) {
    let result = [];
    for (let i = 0; i < transform.steps.length; i++) result.push(new Rebaseable(transform.steps[i], transform.steps[i].invert(transform.docs[i]), transform));
    return result;
}

const collabKey = new PluginKey('collab');

type CollabConfig = {
    version?: number;
};

/// Creates a plugin that enables the collaborative editing framework
/// for the editor.
function collab(config: CollabConfig = {}): Plugin {
    let conf: Required<CollabConfig> = {
        version: config.version || 0
    };

    return new Plugin({
        key: collabKey,

        state: {
            init: () => new CollabState(conf.version, []),
            apply(tr, collab) {
                let newState = tr.getMeta(collabKey);
                if (newState) return newState;
                if (tr.docChanged) return new CollabState(collab.version, collab.unconfirmed.concat(unconfirmedFrom(tr)));
                return collab;
            }
        },

        config: conf,
        historyPreserveItems: true
    });
}

function receiveTransaction(state: EditorState, steps: readonly Step[], version: number) {
    let collabState = collabKey.getState(state);
    let unconfirmed = collabState.unconfirmed.slice(0);

    let nUnconfirmed = unconfirmed.length;
    let tr = state.tr;
    if (nUnconfirmed) {
        unconfirmed = rebaseSteps(unconfirmed, steps, tr);
    } else {
        for (let i = 0; i < steps.length; i++) tr.step(steps[i]);
        unconfirmed = [];
    }

    // If the current selection is a text selection, its
    // sides are mapped with a negative bias for this transaction, so
    // that content inserted at the cursor ends up after the cursor.
    tr.setSelection(
        TextSelection.between(tr.doc.resolve(tr.mapping.map(state.selection.anchor, -1)), tr.doc.resolve(tr.mapping.map(state.selection.head, -1)), -1)
    );

    // Set selection raises a flag that selection was updated.
    // The below code removes that flag
    (tr as any).updated &= ~1;

    return tr
        .setMeta('rebased', nUnconfirmed)
        .setMeta('addToHistory', false)
        .setMeta(collabKey, new CollabState(version, unconfirmed));
}

function clearUncommittedAndUpdateVersion(state: EditorState, change: DocumentChange) {
    const unconfirmed = (collabKey.getState(state) as CollabState).unconfirmed;
    return state.tr.setMeta(collabKey, new CollabState(change.targetVersion, unconfirmed.slice(change.steps.length)));
}

function sendableSteps(
    state: EditorState
): {
    version: number;
    steps: readonly Step[];
    origins: readonly Transaction[];
} | null {
    let collabState = collabKey.getState(state) as CollabState;
    if (collabState.unconfirmed.length === 0) return null;
    return {
        version: collabState.version,
        steps: collabState.unconfirmed.map(s => s.step),
        get origins() {
            return (this as any)._origins || ((this as any)._origins = collabState.unconfirmed.map(s => s.origin));
        }
    };
}

function getVersion(state: EditorState): number {
    return collabKey.getState(state).version;
}
