import { Slide } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogHandle } from '@progress/kendo-react-dialogs';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import { forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AIAvatar } from '../../components/ai/aiAvatar';
import { canvasItemsZoneDependent } from '../../components/canvas/canvasItemsZone';
import { CanvasItemInContextSimpleView } from '../../components/canvas/customerSegmentInContextSimpleView';
import { interviewTypeToLabelMap } from '../../components/interviewScript';
import {
    BookmarkTitle,
    ChangesStatus,
    EditorToolProps,
    InterviewScriptDocumentEditor,
    InterviewScriptDocumentEditorRef
} from '../../components/interviewScript/InterviewScriptDocumentEditor';
import { StartupMembershipList } from '../../components/startup/startupMembershipList';
import { ClickableStackLayout } from '../../components/ui/clickableStackLayout';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { TypingDots } from '../../components/ui/typingDots';
import { useAsRef, useSingleClickCallback } from '../../hooks/commonHooks';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { useRealTimeUpdatesPipe } from '../../hooks/realTimeUpdatesHooks';
import { useIdeaParams, useIntParam } from '../../hooks/routerHooks';
import { ReactComponent as SavedIcon } from '../../icons/check-circle-closed.svg';
import { ReactComponent as InterviewScriptIcon } from '../../icons/file-text.svg';
import { ReactComponent as SavingIcon } from '../../icons/refresh-cw.svg';
import failedInterviewScriptIllustrationUrl from '../../images/failed-interview-script-illustration.svg';
import { BoxType } from '../../services/canvasService';
import { combineClassNames, debounceWithCancel } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { documentsService, EditorDocument } from '../../services/documentsService';
import { domService } from '../../services/domService';
import { Interview2Type, InterviewScript2, interviewScripts2Service } from '../../services/interviewScripts2Service';
import { RealTimeUpdateInterviewScript2Data, RealTimeUpdateInterviewScript2UpdateData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { buildUserViewModel, ReducedUser, UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';

export const InterviewScriptPage = canvasItemsZoneDependent(function InterviewScriptPage() {
    const { ideaId } = useIdeaParams();
    const scriptId = useIntParam('scriptId');
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEditInterviewScript = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;

    const [bookmarkTitles, setBookmarkTitles] = useState<BookmarkTitle[] | undefined>(undefined);
    const userId = useAppSelector(state => state.user?.userId);
    const [editorDocument, setEditorDocument] = useState<EditorDocument | undefined>(undefined);
    const interviewSaveIndicatorRef = useRef<InterviewScriptSaveIndicatorHandle>(null);
    const navigate = useNavigate();
    const dialogRef = useRef<DialogHandle>(null);
    const [selectedSectionIdx, setSelectedSectionIdx] = useState<number>(0);
    const [script, setScript] = useState<InterviewScript2>();

    const dispatch = useAppDispatch();

    const interviewScriptDocumentEditorRef = useRef<InterviewScriptDocumentEditorRef>(null);

    useEffect(() => {
        interviewScripts2Service.getInterviewScript(ideaId, scriptId).then(setScript);
    }, [ideaId, scriptId]);

    const documentIdToLoad = script ? (script.ready ? script.documentId : script.initialDocumentId) : undefined;
    useEffect(() => {
        if (typeof documentIdToLoad !== 'number') return;

        documentsService.getDocument(ideaId, documentIdToLoad).then(setEditorDocument);
    }, [documentIdToLoad, ideaId]);

    const registerContribution = useEnsureCurrentUserAsContributor(ideaId, userId, canEditInterviewScript ? script : undefined, contributors =>
        setScript(s => (s ? { ...s, contributors } : s))
    );

    useEffect(() => {
        function onLeaving(e: BeforeUnloadEvent) {
            if (interviewSaveIndicatorRef.current?.hasPendingChanges) e.preventDefault();
        }

        window.addEventListener('beforeunload', onLeaving);

        return () => window.removeEventListener('beforeunload', onLeaving);
    });

    useEffect(() => {
        let getScriptTimeoutId: NodeJS.Timer | undefined;

        function getScript(delayed?: boolean) {
            if (delayed) {
                if (getScriptTimeoutId) return;
                getScriptTimeoutId = setTimeout(() => getScript(false), 15000);
            } else {
                if (getScriptTimeoutId) {
                    clearTimeout(getScriptTimeoutId);
                    getScriptTimeoutId = undefined;
                }

                interviewScripts2Service.getInterviewScript(ideaId, scriptId).then(setScript);
            }
        }

        function onUpdateScript(e: RealTimeUpdateInterviewScript2UpdateData) {
            if (e.ideaId !== ideaId || e.interviewScriptId !== scriptId) return;

            getScript(e.lowPriority);
        }

        function onDeleteScript(e: RealTimeUpdateInterviewScript2Data) {
            if (e.ideaId !== ideaId || e.interviewScriptId !== scriptId) return;

            dispatch(addNotification({ content: 'The script was deleted' }));
            navigate('./..');
        }

        realTimeUpdatesEventHub.addEventListener('interview3', 'scriptUpdate', onUpdateScript);
        realTimeUpdatesEventHub.addEventListener('interview3', 'scriptDelete', onDeleteScript);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview3', 'scriptUpdate', onUpdateScript);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'scriptDelete', onDeleteScript);
            if (getScriptTimeoutId) {
                clearTimeout(getScriptTimeoutId);
                getScriptTimeoutId = undefined;
            }
        };
    }, [dispatch, ideaId, navigate, scriptId]);

    const hasScriptError = !!script && script.error;

    return (
        <PopupPropsContext.Provider value={p => (dialogRef.current?.element ? { ...p, appendTo: dialogRef.current?.element } : p)}>
            <Dialog
                ref={dialogRef}
                className="k-icp-dialog-maximized k-icp-dialog-no-padding k-icp-dialog-with-title-shadow"
                title={
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-4 k-flex-1 k-mr-4 k-py-1 k-min-w-0">
                        <InterviewScriptNameEditor
                            ideaId={ideaId}
                            scriptId={scriptId}
                            name={script?.name}
                            canEditName={canEditInterviewScript}
                            onNameUpdated={setScript}
                        />

                        <InterviewScriptSaveIndicator ref={interviewSaveIndicatorRef} />

                        <DocumentViewers documentId={documentIdToLoad} className="k-ml-auto" />
                    </StackLayout>
                }
                onClose={() =>
                    (!interviewSaveIndicatorRef.current?.hasPendingChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) &&
                    navigate('./..')
                }
            >
                <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-icp-panel-base k-h-full">
                    <div className="k-flex-1 k-overflow-auto" style={{ flexBasis: '18.75%' }}>
                        <div className="k-p-4 k-pr-6 -maxw1">
                            {!hasScriptError && (
                                <InterviewScriptInfoSection title="Script sections" relaxed>
                                    <InterviewScriptBookmarksList
                                        bookmarks={bookmarkTitles}
                                        selectedSectionIdx={selectedSectionIdx}
                                        onBookmarkSelected={interviewScriptDocumentEditorRef.current?.selectSection}
                                    />
                                </InterviewScriptInfoSection>
                            )}
                        </div>
                    </div>
                    <div className="k-flex-1 k-content k-border-r k-border-r-solid k-border-l k-border-l-solid" style={{ flexBasis: '62.5%', maxWidth: 900 }}>
                        {hasScriptError ? (
                            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="k-gap-2 k-h-full k-p-4">
                                <img src={failedInterviewScriptIllustrationUrl} width="64" height="65" alt="Failed interview script" className="k-mb-2" />
                                <strong className="k-fs-lg">Script not initialized</strong>
                                <div>We were unable to generate your interview script. Go back and try again.</div>
                            </StackLayout>
                        ) : editorDocument && currentUserRole ? (
                            <InterviewScriptDocumentEditor
                                key={editorDocument.id}
                                ref={interviewScriptDocumentEditorRef}
                                ideaId={ideaId}
                                editorDocument={editorDocument}
                                handleSectionIdxChanged={setSelectedSectionIdx}
                                handleNewTitles={setBookmarkTitles}
                                onSavingStatusChange={changesStatus => {
                                    interviewSaveIndicatorRef.current?.onSaveStatusChange &&
                                        interviewSaveIndicatorRef.current.onSaveStatusChange(changesStatus);
                                    changesStatus === ChangesStatus.ChangesSaved && registerContribution();
                                }}
                                exportTitle={script?.name}
                                readonly={!canEditInterviewScript || editorDocument.id === script?.initialDocumentId}
                                additionalTools={editorDocument.id === script?.initialDocumentId ? [AIDocumentGenerationTool] : undefined}
                            />
                        ) : (
                            <LoadingIndicator size="big" className="k-centered" />
                        )}
                    </div>
                    <div className="k-flex-1 k-overflow-auto" style={{ flexBasis: '18.75%' }}>
                        <StackLayout
                            orientation="vertical"
                            align={{ horizontal: 'stretch', vertical: 'top' }}
                            className="k-p-4 k-pl-6 k-gap-6 k-icp-component-border -maxw1"
                        >
                            <InterviewScriptInfoSection title="Script details">
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                                    <InterviewScriptInfo title="Interview duration">
                                        {script ? (
                                            script.durationInMinutes ? (
                                                `${script.durationInMinutes} min`
                                            ) : (
                                                'Not available'
                                            )
                                        ) : (
                                            <Skeleton shape="text" style={{ width: 40 }} />
                                        )}
                                    </InterviewScriptInfo>
                                    <InterviewScriptInfo title="Interview type">
                                        {script ? (
                                            `${interviewTypeToLabelMap[script.interviewType]} Interviews`
                                        ) : (
                                            <Skeleton shape="text" style={{ width: '100%' }} />
                                        )}
                                    </InterviewScriptInfo>
                                </StackLayout>
                            </InterviewScriptInfoSection>
                            <div className="k-separator" />
                            <InterviewScriptInfoSection title="Customer Segment">
                                <CanvasItemInContextSimpleView
                                    box={BoxType.CustomerSegments}
                                    itemId={script?.customerSegmentId}
                                    showLoading
                                    size="mediumsmall"
                                />
                            </InterviewScriptInfoSection>
                            {script && typeof script.initialDocumentId === 'number' && script.ready && (
                                <>
                                    <div className="k-separator" />
                                    <InterviewScriptInfoSection title="Initial script">
                                        <InitialInterviewScriptModalViewer
                                            ideaId={ideaId}
                                            scriptId={scriptId}
                                            interviewType={script.interviewType}
                                            initialDocumentId={script.initialDocumentId}
                                            onScriptReset={canEditInterviewScript ? setScript : undefined}
                                        />
                                    </InterviewScriptInfoSection>
                                </>
                            )}
                            <div className="k-separator" />
                            <InterviewScriptInfoSection title="Contributors">
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                                    {script ? (
                                        <StartupMembershipList
                                            users={script.contributors.map(contributor => buildUserViewModel(contributor))}
                                            className="k-align-self-start"
                                        />
                                    ) : (
                                        <Skeleton shape="circle" style={{ width: 40, height: 40 }} />
                                    )}
                                    <InterviewScriptInfo title="Created">
                                        <InterviewScriptInfoUserActionDate date={script?.createdOn} user={script?.createdBy} />
                                    </InterviewScriptInfo>
                                    {script && script.updatedOn && (
                                        <InterviewScriptInfo title="Last modified">
                                            <InterviewScriptInfoUserActionDate date={script.updatedOn} user={script?.createdBy} />
                                        </InterviewScriptInfo>
                                    )}
                                </StackLayout>
                            </InterviewScriptInfoSection>
                        </StackLayout>
                    </div>
                </StackLayout>
            </Dialog>
        </PopupPropsContext.Provider>
    );
});

function InterviewScriptNameEditor({
    ideaId,
    scriptId,
    name,
    canEditName,
    onNameUpdated
}: {
    ideaId?: string;
    scriptId?: number;
    name?: string;
    canEditName?: boolean;
    onNameUpdated?: (script: InterviewScript2) => void;
}) {
    const [isEditingName, setIsEditingName] = useState(false);
    const [updatedName, setUpdatedName] = useState<string>();
    const nameInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!isEditingName || !nameInputRef.current) return;
        nameInputRef.current.focus();
        nameInputRef.current.select();
    }, [isEditingName]);

    function startEditingName() {
        setUpdatedName(name);
        setIsEditingName(true);
    }

    function cancelEditingName() {
        setUpdatedName(undefined);
        setIsEditingName(false);
    }

    const [isUpdatingName, updateName] = useSingleClickCallback(async () => {
        if (!ideaId || scriptId === undefined) throw new Error('Invalid state');

        if ((nameInputRef.current && !nameInputRef.current.validity.valid) || !updatedName || updatedName === name) {
            cancelEditingName();
            return;
        }
        const updatedScript = await interviewScripts2Service.updateInterviewScriptName(ideaId, scriptId, updatedName);
        setIsEditingName(false);
        onNameUpdated?.(updatedScript);
    });

    return (
        <StackLayout
            align={{ horizontal: 'start', vertical: 'middle' }}
            className={combineClassNames('k-gap-3 k-min-w-0', isEditingName ? 'k-flex-grow' : undefined)}
        >
            <InterviewScriptIcon className="k-icp-icon k-icp-icon-size-6 k-shrink-0" />
            {isEditingName && canEditName ? (
                <input
                    required={true}
                    maxLength={150}
                    ref={nameInputRef}
                    type="text"
                    value={updatedName}
                    onChange={e => setUpdatedName(e.target.value)}
                    onBlur={updateName}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            updateName();
                            e.stopPropagation();
                        } else if (e.key === 'Escape') {
                            cancelEditingName();
                            e.stopPropagation();
                        }
                    }}
                    className="k-input k-input-lg !k-border-none !k-py-0 !k-px-thin !k-font-weight-medium"
                    disabled={isUpdatingName}
                />
            ) : (
                <span
                    className={combineClassNames(
                        'k-text-ellipsis k-fs-lg k-font-weight-medium k-px-thin',
                        canEditName && name !== undefined ? 'k-cursor-pointer k-icp-hover-bg-base-8' : undefined
                    )}
                    onClick={canEditName && name !== undefined ? startEditingName : undefined}
                >
                    {name === undefined ? <Skeleton shape="text" style={{ width: 250 }} /> : name}
                </span>
            )}
        </StackLayout>
    );
}

function InterviewScriptInfoSection({ title, children, relaxed }: { title: string; children?: ReactNode; relaxed?: boolean }) {
    return (
        <div>
            <div className={combineClassNames('k-font-weight-bold', relaxed ? 'k-mb-4' : 'k-mb-2')}>{title}</div>
            {children}
        </div>
    );
}

function InterviewScriptInfo({ title, children }: { title: string; children?: ReactNode }) {
    return (
        <div className="k-fs-sm">
            <div className="k-icp-subtle-text k-mb-thin">{title}</div>
            {children}
        </div>
    );
}

function InterviewScriptInfoUserActionDate({ date, user }: { date?: Date | null; user?: ReducedUser | null }) {
    if (date === null) return null;
    if (date === undefined) return <Skeleton shape="text" style={{ width: '100%' }} />;

    return (
        <>
            {dateTimeService.stringifyToDay(date, false, true)}
            {user && (
                <>
                    {' '}
                    by {user.firstName} {user.lastName}
                </>
            )}
        </>
    );
}

function InterviewScriptBookmarksList({
    bookmarks,
    selectedSectionIdx,
    onBookmarkSelected
}: {
    bookmarks?: BookmarkTitle[];
    selectedSectionIdx?: number;
    onBookmarkSelected?: (sectionIdx: number) => void;
}) {
    if (bookmarks && !bookmarks.length) return <div className="k-fs-sm k-icp-subtle-text">No sections yet</div>;

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4 k-fs-sm">
            {bookmarks ? (
                bookmarks.map((bookmark, index) => (
                    <div
                        key={index}
                        className={combineClassNames('k-cursor-pointer max-lines-3', selectedSectionIdx === index ? 'k-font-weight-semibold' : undefined)}
                        onClick={onBookmarkSelected && (() => onBookmarkSelected(index))}
                    >
                        {bookmark.title || <span className="k-icp-subtle-text">New section title...</span>}
                    </div>
                ))
            ) : (
                <>
                    <Skeleton shape="text" style={{ width: '100%' }} />
                    <Skeleton shape="text" style={{ width: '100%' }} />
                </>
            )}
        </StackLayout>
    );
}

type InterviewScriptSaveIndicatorHandle = {
    onSaveStatusChange?: (status: ChangesStatus) => void;
    readonly hasPendingChanges: boolean;
};
enum InterviewScriptSaveIndicatorStatus {
    Hidden = 1,
    Saving = 2,
    Saved = 3
}
const InterviewScriptSaveIndicator = forwardRef<InterviewScriptSaveIndicatorHandle>(function InterviewScriptSaveIndicator(_, ref) {
    const [hideDebounced, cancelHideDebounced] = useMemo(() => debounceWithCancel(() => setStatus(InterviewScriptSaveIndicatorStatus.Hidden), 2000), []);
    const [showSavedDebounced, cancelShowSavedDebounced] = useMemo(
        () =>
            debounceWithCancel(() => {
                setStatus(InterviewScriptSaveIndicatorStatus.Saved);
                hideDebounced();
            }, 1000),
        [hideDebounced]
    );
    const [status, setStatus] = useState<InterviewScriptSaveIndicatorStatus>(InterviewScriptSaveIndicatorStatus.Hidden);
    const savingChangesCountRef = useRef(0);
    const hasPendingChangesRef = useRef(false);

    useImperativeHandle(
        ref,
        () => ({
            onSaveStatusChange(changesStatus: ChangesStatus) {
                if (changesStatus === ChangesStatus.SendingChanges) savingChangesCountRef.current += 1;
                else if (changesStatus === ChangesStatus.ChangesSaved) savingChangesCountRef.current -= 1;

                if (savingChangesCountRef.current < 0) savingChangesCountRef.current = 0;

                if (changesStatus === ChangesStatus.PendingChanges || savingChangesCountRef.current) {
                    hasPendingChangesRef.current = true;
                    setStatus(InterviewScriptSaveIndicatorStatus.Saving);
                    cancelShowSavedDebounced();
                    cancelHideDebounced();
                } else {
                    hasPendingChangesRef.current = false;
                    showSavedDebounced();
                    cancelHideDebounced();
                }
            },
            get hasPendingChanges() {
                return hasPendingChangesRef.current;
            }
        }),
        [cancelHideDebounced, cancelShowSavedDebounced, showSavedDebounced]
    );

    if (status === InterviewScriptSaveIndicatorStatus.Hidden) return null;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
            {status === InterviewScriptSaveIndicatorStatus.Saving ? (
                <SavingIcon className="k-icp-icon k-icp-icon-size-4 rotating-element" />
            ) : (
                <SavedIcon className="k-icp-icon k-icp-icon-size-4" />
            )}
            <span className="k-fs-sm k-font-normal">
                {status === InterviewScriptSaveIndicatorStatus.Saving ? (
                    <>
                        Saving
                        <TypingDots alwaysUseSpace />
                    </>
                ) : (
                    status === InterviewScriptSaveIndicatorStatus.Saved && 'Saved'
                )}
            </span>
        </StackLayout>
    );
});

export function DocumentViewers({ documentId, className }: { documentId?: number | null; className?: string }) {
    const realTimeUpdatesPipe = useRealTimeUpdatesPipe();
    const ideaConnectedMembers = useAppSelector(s => s.ideaMembers.membersActivity);
    const usersViewingDocument =
        typeof documentId === 'number' && ideaConnectedMembers
            ? ideaConnectedMembers
                  .filter(m => m.pendingActions.some(action => action.type === 'document' && action.operation === 'View' && action.documentId === documentId))
                  .map(m => m.user)
            : undefined;

    useEffect(() => {
        if (!realTimeUpdatesPipe || typeof documentId !== 'number') return;

        realTimeUpdatesPipe.setPendingAction({ type: 'document', documentId: documentId, operation: 'View' });

        function clearDocumentPendingAction() {
            if (!realTimeUpdatesPipe) return;
            realTimeUpdatesPipe.setPendingAction(undefined);
        }

        window.addEventListener('beforeunload', clearDocumentPendingAction);

        return () => {
            clearDocumentPendingAction();
            window.removeEventListener('beforeunload', clearDocumentPendingAction);
        };
    }, [documentId, realTimeUpdatesPipe]);

    return <StartupMembershipList users={usersViewingDocument?.map(u => buildUserViewModel(u))} className={combineClassNames('k-my--2', className)} />;
}

function useEnsureCurrentUserAsContributor(
    ideaId: string,
    currentUserId?: string,
    script?: InterviewScript2,
    onUpdatedContributors?: (contributors: ReducedUser[]) => void
) {
    const shouldAppendContributor = currentUserId !== undefined && script !== undefined && !script.contributors.some(c => c.userId === currentUserId);
    const currentUserAppendedRef = useRef(false);
    const hasContributionRef = useRef(false);

    const onUpdatedContributorsRef = useAsRef(onUpdatedContributors);
    const ensureCurrentUserAsContributor = useCallback(
        async function ensureCurrentUserAsContributor() {
            if (currentUserAppendedRef.current || !shouldAppendContributor) return;

            currentUserAppendedRef.current = true;
            try {
                const contributors = await interviewScripts2Service.appendCurrentUserAsContributor(ideaId, script.id);
                onUpdatedContributorsRef.current?.(contributors);
            } catch (e) {
                currentUserAppendedRef.current = false;
                throw e;
            }
        },
        [ideaId, onUpdatedContributorsRef, script?.id, shouldAppendContributor]
    );

    useEffect(() => {
        if (!shouldAppendContributor || !hasContributionRef.current) return;

        ensureCurrentUserAsContributor();
    }, [ensureCurrentUserAsContributor, shouldAppendContributor]);

    return function onContribution() {
        hasContributionRef.current = true;
        if (shouldAppendContributor) ensureCurrentUserAsContributor();
    };
}

function InitialInterviewScriptModalViewer({
    ideaId,
    scriptId,
    interviewType,
    initialDocumentId,
    onScriptReset
}: {
    ideaId: string;
    scriptId: number;
    interviewType: Interview2Type;
    initialDocumentId: number;
    onScriptReset?: (script: InterviewScript2) => void;
}) {
    const [viewInitialDocument, setViewInitialDocument] = useState<boolean>();
    const title = `Script for ${interviewTypeToLabelMap[interviewType]} interviews`;

    return (
        <>
            <ClickableStackLayout
                align={{ horizontal: 'start', vertical: 'middle' }}
                className="k-gap-2 k-p-1 k-border k-border-solid k-icp-component-border k-cursor-pointer k-rounded k-fs-sm k-icp-hover-bg-base-4"
                onClick={() => setViewInitialDocument(true)}
            >
                <InterviewScriptIcon className="k-icp-icon k-w-8 k-h-8 k-shrink-0" />
                <span>{title}</span>
            </ClickableStackLayout>
            {viewInitialDocument && (
                <InitialInterviewScriptDocumentModal
                    ideaId={ideaId}
                    title={title}
                    documentId={initialDocumentId}
                    onClose={() => setViewInitialDocument(undefined)}
                    onReset={
                        onScriptReset &&
                        (async () => {
                            const resetScript = await interviewScripts2Service.resetInterviewScript(ideaId, scriptId);
                            setViewInitialDocument(undefined);
                            onScriptReset(resetScript);
                        })
                    }
                />
            )}
        </>
    );
}

function InitialInterviewScriptDocumentModal({
    ideaId,
    title,
    documentId,
    onReset,
    onClose
}: {
    ideaId: string;
    title: string;
    documentId: number;
    onReset?: () => Promise<void>;
    onClose: () => void;
}) {
    const [document, setDocument] = useState<EditorDocument>();
    const { show: showResetDialog, element: resetDialog } = useConfirmDialog();
    const [hideModal, setHideModal] = useState<boolean>();

    useEffect(() => {
        documentsService.getDocument(ideaId, documentId).then(setDocument);
    }, [documentId, ideaId]);

    useEffect(() => {
        function closeOnEscape(e: KeyboardEvent) {
            if (e.key !== 'Escape') return;
            e.stopPropagation();
            setHideModal(true);
        }

        window.addEventListener('keydown', closeOnEscape, { capture: true });

        return () => window.removeEventListener('keydown', closeOnEscape, { capture: true });
    }, []);

    return (
        <Slide
            direction="left"
            appear
            className="k-pos-fixed k-top-0 k-bottom-0 k-right-0 k-w-1/2 !k-overflow-visible"
            componentChildClassName="k-w-full k-h-full"
            onExited={onClose}
        >
            {!hideModal && (
                <StackLayout
                    orientation="vertical"
                    align={{ horizontal: 'stretch', vertical: 'top' }}
                    className="k-content k-icp-shadow-md k-icp-dialog-with-title-shadow k-w-full k-h-full"
                >
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-window-titlebar k-justify-content-between">
                        <InterviewScriptNameEditor name={title} />

                        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                            {onReset && (
                                <Button
                                    onClick={() =>
                                        showResetDialog({
                                            title: 'Reset script',
                                            content: 'Are you sure you want to reset this script to its initial version?',
                                            confirmButtonText: 'Reset script',
                                            callback: onReset
                                        })
                                    }
                                >
                                    <span className="k-text-error">Reset to this script</span>
                                </Button>
                            )}
                            <Button type="button" icon="x" fillMode="flat" onClick={() => setHideModal(true)} />
                        </StackLayout>
                    </StackLayout>

                    {document ? (
                        <InterviewScriptDocumentEditor ideaId={ideaId} editorDocument={document} readonly />
                    ) : (
                        <div className="k-px-8 k-py-7 ProseMirror k-flex-1 k-overflow-auto">
                            <InterviewScriptSectionLoader />
                            <InterviewScriptSectionLoader />
                        </div>
                    )}
                    {resetDialog}
                </StackLayout>
            )}
        </Slide>
    );
}

function InterviewScriptSectionLoader() {
    return (
        <>
            <h2>
                <Skeleton shape="text" />
            </h2>
            <p>
                <Skeleton shape="text" />
                <Skeleton shape="text" />
                <Skeleton shape="text" style={{ width: '60%' }} />
            </p>
        </>
    );
}

function AIDocumentGenerationTool({ view }: EditorToolProps) {
    const shouldScrollToBottom = !!view && view.dom.scrollHeight > view.dom.clientHeight && view.dom.scrollTop < domService.getMaxScrollTop(view.dom);
    if (shouldScrollToBottom) view.dom.scrollTop = domService.getMaxScrollTop(view.dom);

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
            <AIAvatar animate />
            <strong>
                Generating interview script
                <TypingDots alwaysUseSpace />
            </strong>
        </StackLayout>
    );
}
