import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Card, CardBody, CardSubtitle, CardTitle, StackLayout } from '@progress/kendo-react-layout';
import { PopupPropsContext } from '@progress/kendo-react-popup';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AILabel } from '../../components/ai/aiLabel';
import { CanvasItemFromBoxPicker } from '../../components/canvas/canvasItemFromBoxPicker';
import { canvasItemsZoneDependent, useRequireCanvasBoxItemsInZone } from '../../components/canvas/canvasItemsZone';
import { BoundDropDownButton, DropDownButtonItem } from '../../components/common/boundDropDownButton';
import { DurationPicker } from '../../components/common/durationPicker';
import { allowedInterviewFileExtensions, maxInterviewFileSizeInBytes } from '../../components/experiments/interviewCollections/common';
import { interviewTypeToIllustrationUrlMap, interviewTypeToLabelMap } from '../../components/interviewScript';
import { StartupMembershipList } from '../../components/startup/startupMembershipList';
import { FileSelector, FormFieldProps, minLengthValidator, requiredValidator, useFileSelectorValidator, ValidatedInput } from '../../components/ui/inputs';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { PredefinedAction, PredefinedActionsList } from '../../components/ui/predefinedActions';
import { SvgIconButtonContent } from '../../components/ui/svgIconButtonContent';
import { TextMarker } from '../../components/ui/textMarker';
import { H1 } from '../../components/ui/typography';
import { appConfig } from '../../config';
import { useSingleClickCallback } from '../../hooks/commonHooks';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { useIdeaParams } from '../../hooks/routerHooks';
import { ReactComponent as InterviewScriptIcon } from '../../icons/file-text.svg';
import { ReactComponent as AddIcon, ReactComponent as CreateScriptIcon } from '../../icons/plus.svg';
import { ReactComponent as ImportScriptIcon } from '../../icons/upload.svg';
import emptyInterviewScriptsIllustrationUrl from '../../images/empty-interview-scripts-illustration.svg';
import { BoxType } from '../../services/canvasService';
import { combineClassNames, immutableRemove } from '../../services/common';
import { dateTimeService } from '../../services/dateTimeService';
import { Interview2Type, InterviewScript2, interviewScripts2Service, NewInterviewScript2Data } from '../../services/interviewScripts2Service';
import { RealTimeUpdateInterviewScript2Data, RealTimeUpdateInterviewScript2UpdateData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { buildUserViewModel, UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';

export const InterviewScriptsPage = canvasItemsZoneDependent(function InterviewScriptsPage() {
    const { ideaId } = useIdeaParams();
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEditInterviewCollections = currentUserRole === UserRole.Editor || currentUserRole === UserRole.Administrator;

    const [scripts, setScripts] = useState<InterviewScript2[]>();
    const { show: showDeleteDialog, element: deleteDialog } = useConfirmDialog();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [createScriptConfig, setCreateScriptConfig] = useState<CreateInterviewScriptModalConfigProps>();

    const loadScripts = useCallback(() => interviewScripts2Service.getScripts(ideaId).then(setScripts), [ideaId]);

    useEffect(() => {
        loadScripts();
    }, [loadScripts]);

    useEffect(() => {
        function onAddOrRestoreScript(e: RealTimeUpdateInterviewScript2Data) {
            if (e.ideaId !== ideaId) return;
            loadScripts();
        }

        function onUpdateScript(e: RealTimeUpdateInterviewScript2UpdateData) {
            if (e.ideaId !== ideaId || e.lowPriority) return;
            loadScripts();
        }

        function onDeleteScript(e: RealTimeUpdateInterviewScript2Data) {
            if (e.ideaId !== ideaId) return;

            setScripts(s => immutableRemove(s, s => s.id !== e.interviewScriptId));
        }

        realTimeUpdatesEventHub.addEventListener('interview3', 'scriptAdd', onAddOrRestoreScript);
        realTimeUpdatesEventHub.addEventListener('interview3', 'scriptUpdate', onUpdateScript);
        realTimeUpdatesEventHub.addEventListener('interview3', 'scriptDelete', onDeleteScript);
        realTimeUpdatesEventHub.addEventListener('interview3', 'scriptRestore', onAddOrRestoreScript);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('interview3', 'scriptAdd', onAddOrRestoreScript);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'scriptUpdate', onUpdateScript);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'scriptDelete', onDeleteScript);
            realTimeUpdatesEventHub.removeEventListener('interview3', 'scriptRestore', onAddOrRestoreScript);
        };
    }, [ideaId, loadScripts]);

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6 k-min-h-full">
            <TextMarker text="Early Preview">
                <H1>Interview Scripts</H1>
            </TextMarker>
            <PredefinedActionsList className="k-shrink-0">
                <PredefinedAction text="Create a new Interview Script" action={() => setCreateScriptConfig({})} disabled={!canEditInterviewCollections}>
                    <CreateScriptIcon width="48" height="48" className="k-icp-icon k-my-2 k-mx-auto" />
                </PredefinedAction>
                <PredefinedAction
                    text="Import an Interview Script"
                    action={() => setCreateScriptConfig({ import: true })}
                    disabled={!canEditInterviewCollections}
                >
                    <ImportScriptIcon width="48" height="48" className="k-icp-icon k-my-2 k-mx-auto" />
                </PredefinedAction>
                {[Interview2Type.ProblemDiscoveryAndValidation, Interview2Type.WinLoss, Interview2Type.BuyerPersona].map(interviewType => {
                    const interviewTypeLabel = interviewTypeToLabelMap[interviewType];
                    return (
                        <PredefinedAction
                            key={interviewType}
                            text={`Get an IVA generated ${interviewTypeLabel} interview script`}
                            cardClassName="k-pos-relative"
                            action={() => setCreateScriptConfig({ automaticGenerationType: interviewType })}
                            disabled={!canEditInterviewCollections}
                        >
                            <img
                                src={interviewTypeToIllustrationUrlMap[interviewType]}
                                alt={interviewTypeLabel}
                                width="64"
                                height="64"
                                className="k-display-block k-mx-auto"
                            />
                            <AILabel className="k-pos-absolute k-top-1 k-left-1">Create with IVA</AILabel>
                        </PredefinedAction>
                    );
                })}
            </PredefinedActionsList>
            {scripts ? (
                scripts.length ? (
                    <>
                        <InterviewScriptsList
                            scripts={scripts}
                            onOpen={scriptId => navigate(String(scriptId))}
                            onDuplicate={
                                canEditInterviewCollections
                                    ? async scriptId => {
                                          const duplicatedScript = await interviewScripts2Service.duplicateInterviewScript(ideaId, scriptId);
                                          setScripts(s => (s ? [duplicatedScript, ...s] : [duplicatedScript]));
                                          dispatch(addNotification({ content: 'Interview script duplicated' }));
                                      }
                                    : undefined
                            }
                            onDelete={
                                canEditInterviewCollections
                                    ? scriptId => {
                                          const scriptToDelete = scripts.find(s => s.id === scriptId);
                                          if (!scriptToDelete) return;

                                          showDeleteDialog({
                                              title: 'Delete interview script',
                                              content: (
                                                  <>
                                                      Are you sure you want to delete <strong>“{scriptToDelete.name}”</strong>?
                                                  </>
                                              ),
                                              confirmCheckboxText: 'I acknowledge that this cannot be undone',
                                              confirmButtonText: 'Delete interview script',
                                              callback: async () => {
                                                  await interviewScripts2Service.deleteInterviewScript(ideaId, scriptId);
                                                  setScripts(scripts => immutableRemove(scripts, i => i.id !== scriptId));
                                              }
                                          });
                                      }
                                    : undefined
                            }
                        />
                        {deleteDialog}
                    </>
                ) : (
                    <InterviewScriptsEmptyView className="k-flex-1" />
                )
            ) : (
                <LoadingIndicator size="big" className="k-align-self-center" />
            )}
            {createScriptConfig && (
                <CreateInterviewScriptModal
                    ideaId={ideaId}
                    {...createScriptConfig}
                    onCancel={() => setCreateScriptConfig(undefined)}
                    onCreated={s => navigate(String(s.id))}
                />
            )}
        </StackLayout>
    );
});

function InterviewScriptsEmptyView({ className }: { className?: string }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className={combineClassNames(className, 'k-gap-6')}>
            <img src={emptyInterviewScriptsIllustrationUrl} alt="No interview scripts list" width="240" height="120" />
            <div>Get an IVA generated script or craft one yourself</div>
        </StackLayout>
    );
}

function InterviewScriptsList({
    scripts,
    onOpen,
    onDuplicate,
    onDelete
}: {
    scripts: InterviewScript2[];
    onOpen: (scriptId: number) => void;
    onDuplicate?: (scriptId: number) => void;
    onDelete?: (scriptId: number) => void;
}) {
    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const clickedElement = e.target as HTMLElement;
        if (!clickedElement) return;
        if (!e.currentTarget.contains(clickedElement) || clickedElement.closest('.card-actions-area')) e.preventDefault();
    };

    return (
        <div className="cards-relaxed-grid">
            {scripts.map(script => (
                <Link key={script.id} className="k-link" to={`${script.id}`} onClick={onClick}>
                    <InterviewScriptCard
                        script={script}
                        onOpen={() => onOpen(script.id)}
                        onDuplicate={onDuplicate && script.ready && !script.error ? () => onDuplicate(script.id) : undefined}
                        onDelete={onDelete && (() => onDelete(script.id))}
                    />
                </Link>
            ))}
        </div>
    );
}

function InterviewScriptCard({
    script,
    onOpen,
    onDuplicate,
    onDelete
}: {
    script: InterviewScript2;
    onOpen: () => void;
    onDuplicate?: () => void;
    onDelete?: () => void;
}) {
    const actions: DropDownButtonItem[] = [];
    actions.push({ text: 'Open', action: onOpen });
    actions.push({ text: 'Duplicate', action: onDuplicate, disabled: !onDuplicate });
    actions.push({ text: 'Delete', danger: true, separated: true, action: onDelete, disabled: !onDelete });

    return (
        <Card className="k-icp-interactive-card">
            <CardBody>
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-mb-4">
                    <div className="k-rounded-full k-p-2 k-icp-bg-primary-30">
                        <InterviewScriptIcon className="k-icp-icon k-icp-icon-size-4" />
                    </div>
                    <div className="k-flex-1 k-fs-xs k-text-uppercase">Used in {script.interviewsCount ?? 0} interviews</div>
                    <InterviewScriptCustomerSegmentView customerSegmentId={script.customerSegmentId} />
                </StackLayout>
                <CardTitle className="max-lines-2 !k-mb-1">{script.name}</CardTitle>
                <CardSubtitle className="!k-mb-4">
                    {script.durationInMinutes ? (
                        <>
                            {script.durationInMinutes} min
                            <span className="dot-separator k-display-inline-block k-mx-1.5 k-align-middle" />
                        </>
                    ) : (
                        undefined
                    )}
                    {interviewTypeToLabelMap[script.interviewType]} script
                </CardSubtitle>
                <StackLayout align={{ vertical: 'middle', horizontal: 'start' }} className="k-justify-content-between k-gap-2 k-flex-nowrap">
                    {script.contributors.length ? (
                        <PopupPropsContext.Provider value={p => ({ ...p, appendTo: document.body })}>
                            <StartupMembershipList
                                users={script.contributors.map(contributor => buildUserViewModel(contributor))}
                                className="k-icp-avatar-compact-list-sm"
                                avatarSize="small"
                                maxCount={8}
                            />
                        </PopupPropsContext.Provider>
                    ) : (
                        <div />
                    )}

                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
                        <span className="k-icp-text-smaller k-icp-subtle-text">
                            Edited {dateTimeService.stringifyDuration(new Date(), dateTimeService.getMaxDate(script.createdOn, script.updatedOn)!)}
                        </span>
                        <div className="k-separator" />
                        <BoundDropDownButton
                            fillMode="flat"
                            size="small"
                            icon="more-horizontal"
                            popupSettings={{
                                anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                                popupAlign: { horizontal: 'right', vertical: 'top' }
                            }}
                            items={actions}
                            className="card-actions-area"
                        />
                    </StackLayout>
                </StackLayout>
            </CardBody>
        </Card>
    );
}

function InterviewScriptCustomerSegmentView({ customerSegmentId }: { customerSegmentId: number }) {
    const customerSegments = useRequireCanvasBoxItemsInZone(BoxType.CustomerSegments);
    if (!customerSegments) return <Skeleton shape="rectangle" className="palette-color" />;

    const customerSegment = customerSegments.find(i => i.id === customerSegmentId);
    if (!customerSegment || !customerSegment.colorCode) return null;

    return (
        <Tooltip anchorElement="target" position="left" openDelay={0} style={{ maxWidth: 250 }}>
            <div className="palette-color" style={{ backgroundColor: `#${customerSegment.colorCode}` }} title={customerSegment.content} />
        </Tooltip>
    );
}

const customerSegmentIdValidator = requiredValidator('Customer Segment');
const durationValidator = requiredValidator('Duration');
const interviewTypeValidator = requiredValidator('Type');
const requiredInterviewScriptFileErrorMessage = 'Script file is required';

type CreateInterviewScriptModalConfigProps = { automaticGenerationType: Interview2Type } | { import?: boolean };
type CreateInterviewScriptModalProps = {
    ideaId: string;
    onCancel?: () => void;
    onCreated?: (interviewScript: InterviewScript2) => void;
} & CreateInterviewScriptModalConfigProps;
function CreateInterviewScriptModal(props: CreateInterviewScriptModalProps) {
    const automaticGenerationType = 'automaticGenerationType' in props ? props.automaticGenerationType : undefined;
    const isImporting = 'import' in props ? props.import : undefined;

    const customerSegments = useRequireCanvasBoxItemsInZone(BoxType.CustomerSegments);

    const [interviewScriptFileValidator, interviewFileErrors] = useFileSelectorValidator(allowedInterviewFileExtensions, maxInterviewFileSizeInBytes);
    const interviewScriptFileValidators = useMemo(
        () => [
            interviewScriptFileValidator,
            requiredValidator(requiredInterviewScriptFileErrorMessage, false),
            minLengthValidator(requiredInterviewScriptFileErrorMessage, 1, false)
        ],
        [interviewScriptFileValidator]
    );

    const [createDisabled, createInterviewScriptCallback] = useSingleClickCallback(async (data: Record<string, any>) => {
        const newScriptData: NewInterviewScript2Data = {
            customerSegmentId: data.customerSegmentId,
            interviewType: automaticGenerationType ?? data.interviewType,
            durationInMinutes: automaticGenerationType ? data.durationInMinutes : undefined,
            useTemplate: Boolean(automaticGenerationType),
            file: isImporting ? data.file[0] : undefined
        };

        const newInterviewScript = await interviewScripts2Service.createInterviewScript(props.ideaId, newScriptData);
        props.onCreated?.(newInterviewScript);
    });

    return (
        <Dialog
            width={640}
            title={
                automaticGenerationType
                    ? `Generate a ${interviewTypeToLabelMap[automaticGenerationType]} Interview Script`
                    : isImporting
                    ? 'Import an Interview Script'
                    : 'Create an Interview Script'
            }
            className="k-icp-dialog-no-padding k-icp-dialog-with-form"
            closeIcon={false}
        >
            <Form
                ignoreModified
                onSubmit={createInterviewScriptCallback}
                initialValues={automaticGenerationType ? { durationInMinutes: 30 } : undefined}
                render={formRenderProps => (
                    <FormElement className="k-icp-component-border">
                        <div className="k-window-content">
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8">
                                {isImporting && (
                                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                                        <Field
                                            name="file"
                                            component={ValidatedInput}
                                            inputType={FileSelector}
                                            label="Script file"
                                            labelClassName="!k-mb-4 k-h3"
                                            acceptFileExtensions={allowedInterviewFileExtensions.join(', ')}
                                            validator={interviewScriptFileValidators}
                                            fileErrors={interviewFileErrors}
                                            hideErrorMessage={Boolean(interviewFileErrors)}
                                            className="!k-min-h-0 !k-p-4"
                                        />
                                    </StackLayout>
                                )}
                                {automaticGenerationType ? (
                                    <Field
                                        name="durationInMinutes"
                                        component={ValidatedInput}
                                        inputType={DurationPicker}
                                        label="Choose interview duration"
                                        labelClassName="!k-mb-4 k-h3"
                                        validator={durationValidator}
                                        predefinedValues={[10, 15, 30, 45, 60]}
                                    />
                                ) : (
                                    <Field
                                        name="interviewType"
                                        component={ValidatedInput}
                                        inputType={InterviewTypePicker}
                                        label="Interview type"
                                        labelClassName="!k-mb-4 k-h3"
                                        validator={interviewTypeValidator}
                                    />
                                )}
                                <Field
                                    name="customerSegmentId"
                                    component={ValidatedInput}
                                    inputType={CanvasItemFromBoxPicker}
                                    label="Relate to Customer Segment"
                                    labelClassName="!k-mb-4 k-h3"
                                    validator={customerSegmentIdValidator}
                                    items={customerSegments}
                                    emptyView={<MissingCustomerSegmentsWarning />}
                                />
                            </StackLayout>
                        </div>
                        <DialogActionsBar layout="center">
                            <Button disabled={!formRenderProps.allowSubmit || createDisabled} type="submit" themeColor="primary">
                                {automaticGenerationType ? 'Generate script' : 'Create script'}
                            </Button>
                            <Button type="reset" onClick={props.onCancel}>
                                Cancel
                            </Button>
                        </DialogActionsBar>
                    </FormElement>
                )}
            />
        </Dialog>
    );
}

export function MissingCustomerSegmentsWarning() {
    return (
        <div className="k-px-8 k-py-4 k-icp-bg-warning-8 k-rounded k-text-center">
            <div className="k-mb-4 k-text-left">
                Focusing on a specific Customer Segment is essential for conducting any type of interview. Define one before proceeding.
            </div>
            <Link
                className="k-button k-button-md k-button-outline k-button-outline-base k-rounded-md"
                to={`../canvas?highlight=${encodeURIComponent(appConfig.canvas.boxes.CustomerSegments.highlight)}`}
            >
                <SvgIconButtonContent icon={AddIcon}>Add Customer Segment</SvgIconButtonContent>
            </Link>
        </div>
    );
}

function InterviewTypePicker({ id, value, onChange, disabled, valid }: FormFieldProps<Interview2Type>) {
    const interviewTypes = [Interview2Type.ProblemDiscoveryAndValidation, Interview2Type.WinLoss, Interview2Type.BuyerPersona];

    return (
        <StackLayout id={id} align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
            {interviewTypes.map(v => {
                const isSelected = v === value;
                const canSelectValue = !disabled && !isSelected && Boolean(onChange);

                return (
                    <div key={v} className="k-text-center">
                        <div
                            className={combineClassNames(
                                'k-p-4 k-icp-panel k-mb-1',
                                disabled ? 'k-disabled' : undefined,
                                canSelectValue ? 'k-cursor-pointer k-icp-interactive-card-small' : undefined,
                                isSelected ? '!k-border-secondary' : undefined
                            )}
                            onClick={canSelectValue ? () => onChange!({ value: v }) : undefined}
                        >
                            <img src={interviewTypeToIllustrationUrlMap[v]} alt={interviewTypeToLabelMap[v]} width="48" height="48" />
                        </div>
                        <div className="k-fs-sm">{interviewTypeToLabelMap[v]} Interviews</div>
                    </div>
                );
            })}
        </StackLayout>
    );
}
