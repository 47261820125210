export const composeFunctions = <T extends (...args: any) => any>(firstFunction?: T, secondFunction?: T): T | undefined => {
    if (!firstFunction && !secondFunction) return undefined;
    if (!firstFunction) return secondFunction;
    if (!secondFunction) return firstFunction;

    return ((...args: Parameters<T>) => {
        firstFunction.apply(this, args);
        return secondFunction.apply(this, args);
    }) as T;
};

export const debounceWithCancel = <T extends (...args: any) => any>(callback: T, delay: number): [(...args: Parameters<T>) => void, () => void] => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    const cancel = () => clearTimeout(timeout);

    return [
        function(...args: Parameters<T>) {
            cancel();
            timeout = setTimeout(() => {
                callback(...args);
            }, delay);
        },
        cancel
    ];
};

export const debounce = <T extends (...args: any) => any>(callback: T, delay: number): ((...args: Parameters<T>) => void) => {
    const [debounced] = debounceWithCancel(callback, delay);

    return debounced;
};
