import { StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';
import { combineClassNames } from '../../services/common';

export function PredefinedActionsList({ children, className }: { children?: ReactNode; className?: string }) {
    return (
        <StackLayout
            align={{ horizontal: 'start', vertical: 'top' }}
            className={combineClassNames('k-rounded-lg k-icp-panel-base k-p-4 k-gap-4 k-overflow-auto hide-scrollbar-on-touch-device', className)}
        >
            {children}
        </StackLayout>
    );
}

export type PredefinedActionProps = { text: string; disabled?: boolean; cardClassName?: string; children?: ReactNode } & ({ to: To } | { action?: () => void });
export function PredefinedAction(props: PredefinedActionProps) {
    return (
        <div className="k-text-center k-flex-1 predefined-action">
            <PredefinedActionCard props={props}>{props.children}</PredefinedActionCard>
            <strong className="max-lines-2" title={props.text}>
                {props.text}
            </strong>
        </div>
    );
}

function PredefinedActionCard({ props, children }: { props: PredefinedActionProps; children?: ReactNode }) {
    const className = combineClassNames('k-icp-panel k-mb-1 k-p-2', props.cardClassName, props.disabled ? 'k-disabled' : 'k-icp-interactive-card');

    if (props.disabled) return <div className={className}>{children}</div>;

    if ('to' in props)
        return (
            <Link to={props.to} className={combineClassNames('k-display-block', className)}>
                {children}
            </Link>
        );

    return (
        <div className={combineClassNames(className, 'k-cursor-pointer')} onClick={props.action}>
            {children}
        </div>
    );
}
