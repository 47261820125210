import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { Popover } from '@progress/kendo-react-tooltip';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../icons/x.svg';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { activateInfoTip, deactivateInfoTip } from '../../state/idea/infoTipsSlice';

export function InfoTip({ name, children, anchor }: { name: string; children: string; anchor: HTMLElement | null | undefined }) {
    const { ideaId } = useParams();
    const isActive = useInfoTip(ideaId, name);
    const dispatch = useDispatch();

    useInfoTipQueryStringActivation(ideaId, name);

    return (
        <Popover
            anchor={anchor}
            show={isActive && !!anchor}
            popoverClass="k-icp-tooltip-popover k-icp-tooltip-popover-closable"
            position="right"
            margin={{ horizontal: 20, vertical: 0 }}
        >
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }}>
                <span className="k-icp-tooltip-popover-content">{children}</span>
                <span className="k-separator" />
                <Button
                    fillMode="flat"
                    size="small"
                    className="k-icp-svg-icon-button"
                    onClick={() => ideaId && dispatch(deactivateInfoTip({ ideaId, infoTip: name }))}
                >
                    <CloseIcon className="k-icp-icon" />
                </Button>
            </StackLayout>
        </Popover>
    );
}

function useInfoTipQueryStringActivation(ideaId: string | undefined, name: string) {
    const [queryParams] = useSearchParams();
    const dispatch = useAppDispatch();

    const shouldActivateInfoTip = queryParams.getAll('highlight').includes(name);
    useEffect(() => {
        if (!shouldActivateInfoTip || !ideaId) return;

        dispatch(activateInfoTip({ ideaId: ideaId, infoTip: name }));
    }, [dispatch, ideaId, name, shouldActivateInfoTip]);
}

export function useInfoTipDeactivation(ideaId: string | undefined, name: string) {
    const isActive = useInfoTip(ideaId, name);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isActive || !ideaId) return;

        dispatch(deactivateInfoTip({ ideaId: ideaId, infoTip: name }));
    }, [dispatch, ideaId, isActive, name]);
}

function useInfoTip(ideaId: string | undefined, name: string) {
    const ideaInfoTips = useAppSelector(s => (ideaId ? s.infoTips[ideaId] : undefined));
    const isActive = !!ideaInfoTips && ideaInfoTips.includes(name);

    return isActive;
}
