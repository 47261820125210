import buyerIllustrationUrl from '../../images/buyer-illustration.svg';
import customerProblemIllustrationUrl from '../../images/customer-problem-illustration.svg';
import diceIllustrationUrl from '../../images/dice-illustration.svg';
import { Interview2Type } from '../../services/interviewScripts2Service';

export const interviewTypeToLabelMap: Record<Interview2Type, string> = {
    [Interview2Type.ProblemDiscoveryAndValidation]: 'Problem Discovery and Validation',
    [Interview2Type.WinLoss]: 'Win/Loss',
    [Interview2Type.BuyerPersona]: 'Buyer Persona',
    [Interview2Type.Other]: 'Other'
};

export const interviewTypeToIllustrationUrlMap: Record<Interview2Type, string> = {
    [Interview2Type.ProblemDiscoveryAndValidation]: customerProblemIllustrationUrl,
    [Interview2Type.WinLoss]: diceIllustrationUrl,
    [Interview2Type.BuyerPersona]: buyerIllustrationUrl,
    [Interview2Type.Other]: customerProblemIllustrationUrl
};
