import { Dialog } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { ComponentType, forwardRef, ReactNode, useRef, useState } from 'react';
import { To } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as AssumptionsIcon } from '../../icons/assumption.svg';
import { ReactComponent as GlossaryIcon } from '../../icons/book-open.svg';
import { ReactComponent as CanvasIcon } from '../../icons/canvas.svg';
import { ReactComponent as ContactsIcon } from '../../icons/contacts.svg';
import { ReactComponent as ScriptsIcon } from '../../icons/file-text.svg';
import { ReactComponent as InterviewsIcon } from '../../icons/interview.svg';
import { ReactComponent as JourneyIcon } from '../../icons/map.svg';
import { ReactComponent as CalendarIcon } from '../../icons/schedule.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import { ReactComponent as TestsIcon } from '../../icons/test.svg';
import { JourneyTaskNavigationHighlight } from '../../services/journeyService';
import { UserRole, usersService } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setCurrentUser } from '../../state/user/userSlice';
import { InterviewCollectionsAnnouncement } from '../experiments/interviewCollections/interviewCollectionsAnnouncement';
import { InfoTip } from '../idea/infoTip';
import { H1 } from '../ui/typography';

export default function Sidebar() {
    const currentRole = useAppSelector(s => s.idea.role);
    const journeyLinkElementRef = useRef<HTMLAnchorElement>(null);

    return (
        <StackLayout
            orientation="vertical"
            align={{ horizontal: 'stretch', vertical: 'top' }}
            className="side-nav k-px-2 k-pt-6 k-pb-4 k-gap-2 k-border-r-solid k-border-r k-icp-component-border"
        >
            <NavLinksList>
                <SidebarNavLink ref={journeyLinkElementRef} icon={JourneyIcon} to="./journey">
                    Journey
                </SidebarNavLink>
                <InfoTip name={JourneyTaskNavigationHighlight.TaskUnlockedNotice} anchor={journeyLinkElementRef.current}>
                    Start your next task
                </InfoTip>
                <SidebarNavLink icon={CanvasIcon} to="./canvas">
                    Canvas
                </SidebarNavLink>
                <SidebarNavLink icon={AssumptionsIcon} to="./hypotheses">
                    Hypotheses
                </SidebarNavLink>
                <SidebarNavLink icon={TestsIcon} to="./research">
                    Research
                </SidebarNavLink>
                <SidebarNavLink icon={ScriptsIcon} to="./scripts">
                    Scripts
                </SidebarNavLink>
                <SidebarNavLink icon={CalendarIcon} to="./calendar">
                    Calendar
                </SidebarNavLink>
                <SidebarNavLink icon={ContactsIcon} to="./contacts">
                    Contacts
                </SidebarNavLink>
            </NavLinksList>
            <ExperimentsNavItemsList />
            <div className="k-separator" />
            <NavLinksList>
                <SidebarNavLink icon={GlossaryIcon} to="./glossary">
                    Glossary
                </SidebarNavLink>
                {currentRole && currentRole !== UserRole.Viewer && (
                    <SidebarNavLink icon={SettingsIcon} to="./settings">
                        Settings
                    </SidebarNavLink>
                )}
            </NavLinksList>
        </StackLayout>
    );
}

function NavLinksList({ children }: { children?: ReactNode }) {
    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }}>
            {children}
        </StackLayout>
    );
}

type SidebarNavLinkProps = {
    icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    children: string;
    to: To;
};
const SidebarNavLink = forwardRef<HTMLAnchorElement, SidebarNavLinkProps>(function SidebarNavLink({ icon: Icon, children: text, to }, ref) {
    return (
        <NavLink
            to={to}
            className="k-button k-button-sm k-button-flat k-button-flat-base k-rounded-md !k-font-normal !k-justify-content-start !k-p-2 !k-pl-3 !k-border-0 !k-white-space-normal !k-text-left"
            ref={ref}
        >
            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-icp-svg-icon-button-content k-gap-2">
                <Icon className="k-icp-icon k-mt-1px k-shrink-0" />
                {text}
            </StackLayout>
        </NavLink>
    );
});

type ExperimentDefinition = {
    key: string;
    icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    text: string;
    link: To;
    announcement?: {
        title: string;
        ctaText: string;
        content: ComponentType;
    };
};
const activeExperiments: ExperimentDefinition[] = [
    {
        key: 'ExperimentalInterviewProcessing',
        icon: InterviewsIcon,
        text: 'Interviews Collections',
        link: './interviews-collections',
        announcement: {
            title: 'Transform Interviews into Actionable Insights',
            ctaText: 'Start now',
            content: InterviewCollectionsAnnouncement
        }
    }
];
function ExperimentsNavItemsList() {
    const currentUserFlags = useAppSelector(s => s.user?.flags);
    const dispatch = useAppDispatch();
    const [suppressAnnouncements, setSuppressAnnouncements] = useState<boolean>();
    if (!currentUserFlags) return null;

    function getExperimentUserFlag(experimentKey: string, dismissed: boolean) {
        return dismissed ? `${experimentKey}_Dismissed` : experimentKey;
    }

    const availableExperiments = activeExperiments.filter(experiment => currentUserFlags.includes(getExperimentUserFlag(experiment.key, false)));
    if (!availableExperiments.length) return null;

    const experimentsToBeAnnounced = availableExperiments.filter(
        experiment => experiment.announcement && !currentUserFlags.includes(getExperimentUserFlag(experiment.key, true))
    );

    async function ensureUserFlag(flag: string) {
        if (!currentUserFlags) throw new Error('Flags not loaded');
        if (currentUserFlags.includes(flag)) return;

        const updatedUser = await usersService.partiallyUpdateCurrent({ flags: [...currentUserFlags, flag] });
        dispatch(setCurrentUser(updatedUser));
    }

    async function closeAnnouncement(experimentKey: string) {
        setSuppressAnnouncements(true);
        try {
            await ensureUserFlag(getExperimentUserFlag(experimentKey, true));
        } finally {
            setSuppressAnnouncements(false);
        }
    }

    return (
        <>
            <div className="k-separator" />
            <NavLinksList>
                {availableExperiments.map(experiment => (
                    <SidebarNavLink key={experiment.key} icon={experiment.icon} to={experiment.link}>
                        {experiment.text}
                    </SidebarNavLink>
                ))}
                {!suppressAnnouncements &&
                    experimentsToBeAnnounced.map(experiment => {
                        const announcementData = experiment.announcement!;
                        return (
                            <ExperimentAnnouncement
                                key={experiment.key}
                                title={announcementData.title}
                                ctaLink={experiment.link}
                                ctaText={announcementData.ctaText}
                                onClose={() => closeAnnouncement(experiment.key)}
                            >
                                <announcementData.content />
                            </ExperimentAnnouncement>
                        );
                    })}
            </NavLinksList>
        </>
    );
}

function ExperimentAnnouncement({
    title,
    ctaText,
    ctaLink,
    children,
    onClose
}: {
    title: string;
    ctaText: string;
    ctaLink: To;
    children?: ReactNode;
    onClose?: () => void;
}) {
    return (
        <Dialog title=" " className="k-icp-dialog-no-title k-icp-dialog-responsive" onClose={onClose} width={992}>
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-10 k-mb-8 k-mx-12">
                <H1 className="k-text-center">{title}</H1>
                <div>{children}</div>
                <Link
                    to={ctaLink}
                    onClick={onClose}
                    className="k-button k-button-lg k-button-solid k-button-solid-primary k-rounded-md"
                    style={{ minWidth: 240 }}
                >
                    {ctaText}
                </Link>
            </StackLayout>
        </Dialog>
    );
}
