import { ButtonItemProps, DropDownButton } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Avatar, AvatarProps, StackLayout } from '@progress/kendo-react-layout';
import { Popover } from '@progress/kendo-react-tooltip';
import React, { ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialogConfig, useConfirmDialog } from '../../hooks/dialogHooks';
import { ReactComponent as MailSendIcon } from '../../icons/mail-sent.svg';
import { Account } from '../../services/accountsService';
import { combineClassNames } from '../../services/common';
import { Invite, ideasService } from '../../services/ideasService';
import { RealTimeEventTypeMap, RealTimeUpdateMembershipEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { ReducedUserViewModel, UserMembershipEntry, UserPermissionsMap, UserRole, generateInitials, getPreferredColorIndex } from '../../services/usersService';
import { useAppDispatch } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';
import LoadingIndicator from '../ui/loadingIndicator';
import UserAvatar from '../user/userAvatar';

enum StartupMemberType {
    Member,
    Invite
}

class StartupMemberViewModel {
    readonly user?: ReducedUserViewModel;
    readonly invite?: Invite;

    constructor(readonly type: StartupMemberType, readonly email: string, data: ReducedUserViewModel | Invite) {
        if (type === StartupMemberType.Member) {
            this.user = data as ReducedUserViewModel;
        } else if (type === StartupMemberType.Invite) {
            this.invite = data as Invite;
        }
    }
}

export function StartupMembershipList({
    users,
    invites,
    accentedUsersIds,
    className,
    style,
    maxCount = 4,
    avatarSize
}: {
    users?: ReducedUserViewModel[];
    invites?: Invite[];
    accentedUsersIds?: string[];
    className?: string;
    style?: React.CSSProperties;
    maxCount?: number;
    avatarSize?: AvatarProps['size'];
}) {
    const [showingTeamMembersTooltip, setShowingTeamMembersTooltip] = useState(false);
    const canToggleTooltip = useRef(true);
    const toggleTooltipCallsQueue = useRef<Function[]>([]);
    const [hoveredMembers, setHoveredMembers] = useState<StartupMemberViewModel[]>([]);
    const hoveredMembersRef = useRef<HTMLElement | null>();

    const members: StartupMemberViewModel[] = users?.map(u => new StartupMemberViewModel(StartupMemberType.Member, u.emailAddress, u)) ?? [];
    invites?.forEach(i => members.push(new StartupMemberViewModel(StartupMemberType.Invite, i.emailAddress, i)));

    const invokeToggleFunction = (toggleFunction: Function) => {
        canToggleTooltip.current = false;
        toggleFunction();
        setTimeout(() => {
            if (toggleTooltipCallsQueue.current.length) {
                const toggleFunctionToCall = toggleTooltipCallsQueue.current.shift() as Function;
                invokeToggleFunction(toggleFunctionToCall);
            } else canToggleTooltip.current = true;
        }, 10);
    };

    const throttleTeamMembersToggle = (toggleFunction: Function) => {
        if (canToggleTooltip.current) {
            invokeToggleFunction(toggleFunction);
        } else {
            toggleTooltipCallsQueue.current.push(toggleFunction);
        }
    };

    const hideTeamMembersTooltip = () => {
        setShowingTeamMembersTooltip(false);
    };

    const showTeamMembersTooltip = (element: HTMLElement, ...members: StartupMemberViewModel[]) => {
        hoveredMembersRef.current = element.closest('.team-member') as HTMLElement;
        setHoveredMembers(members);
        setShowingTeamMembersTooltip(true);
    };

    if (!members.length) return null;

    return (
        <>
            <StackLayout className={combineClassNames('k-icp-avatar-compact-list', className)} style={style}>
                {members.slice(0, members.length > maxCount ? maxCount - 1 : maxCount).map((m, i) => {
                    const userAvatarClassName = m.user && accentedUsersIds && !accentedUsersIds.includes(m.user.userId) ? 'k-icp-avatar-dimmed' : undefined;
                    return (
                        <div
                            className="team-member"
                            onMouseEnter={e => throttleTeamMembersToggle(((t, m) => () => showTeamMembersTooltip(t, m))(e.currentTarget, m))}
                            onMouseLeave={e => throttleTeamMembersToggle(() => hideTeamMembersTooltip())}
                            key={m.email}
                            style={{ zIndex: i }}
                        >
                            {m.user && (
                                <UserAvatar
                                    picture={m.user.picture}
                                    initials={m.user.initials}
                                    colorIndex={m.user.colorIndex}
                                    className={userAvatarClassName}
                                    size={avatarSize}
                                />
                            )}
                            {m.invite && (
                                <Avatar
                                    className="k-icp-avatar-simple-bg-0 k-icp-avatar-gradient-bg"
                                    rounded="full"
                                    type="text"
                                    themeColor="base"
                                    size={avatarSize}
                                >
                                    <MailSendIcon className="k-icp-icon k-disabled" />
                                </Avatar>
                            )}
                        </div>
                    );
                })}
                {members.length > maxCount && (
                    <div
                        className="team-member"
                        onMouseEnter={e =>
                            throttleTeamMembersToggle(((t, m) => () => showTeamMembersTooltip(t, ...m))(e.currentTarget, members.slice(maxCount - 1)))
                        }
                        onMouseLeave={e => throttleTeamMembersToggle(() => hideTeamMembersTooltip())}
                        style={{ zIndex: maxCount }}
                    >
                        <UserAvatar className="k-icp-avatar-light-text" initials={`+${members.length - maxCount + 1}`} size={avatarSize} />
                    </div>
                )}
            </StackLayout>

            <Popover
                anchor={hoveredMembersRef.current}
                show={showingTeamMembersTooltip && hoveredMembers.length > 0}
                position="bottom"
                animate={false}
                margin={{ horizontal: 0, vertical: 8 }}
                popoverClass="k-icp-tooltip-popover"
            >
                <StackLayout className="k-text-center k-gap-1" orientation="vertical">
                    {hoveredMembers.map(m => (
                        <div key={m.email}>
                            {m.user && (
                                <>
                                    <div>
                                        {m.user.firstName} {m.user.lastName}
                                    </div>
                                    <div className="k-icp-white-ghost-text">{m.email}</div>
                                </>
                            )}
                            {m.invite && <div>{m.email}</div>}
                        </div>
                    ))}
                </StackLayout>
            </Popover>
        </>
    );
}

interface MemberAction {
    text: string;
    confirmation?: Omit<ConfirmDialogConfig, 'callback'>;
    separated: boolean;
    context: UserMembershipEntry | Invite;
    callback: Function;
}

function MemberActionDropDownItem({ item, itemIndex }: { item: MemberAction; itemIndex: number }) {
    return <span className={item.confirmation ? 'k-text-error' : undefined}>{item.text}</span>;
}

const roleDropDownValues = [UserRole.Administrator, UserRole.Editor, UserRole.Viewer].map(r => {
    return { text: UserPermissionsMap[r], value: r };
});

export const StartupMembershipDetailedList = forwardRef(
    (
        {
            ideaId,
            currentUserId,
            itemClassName,
            edit,
            ideaTitle,
            onMembersLoaded
        }: {
            ideaId: string;
            currentUserId?: string;
            itemClassName?: string;
            edit: boolean;
            ideaTitle?: string;
            onMembersLoaded?: (members: UserMembershipEntry[]) => void;
        },
        ref
    ) => {
        const [loading, setLoading] = useState(false);
        const [members, setMembers] = useState<UserMembershipEntry[]>();
        const [ideaAccount, setIdeaAccount] = useState<Account>();
        const [invites, setInvites] = useState<Invite[]>();
        const navigate = useNavigate();
        const dispatch = useAppDispatch();

        const loadDataForIdea = useCallback(async (ideaId: string) => {
            setLoading(true);

            const idea = await ideasService.get(ideaId, true, false, true);
            setMembers(idea?.memberships);
            setInvites(idea?.invites);
            setIdeaAccount(idea?.account);
            setLoading(false);

            return idea?.memberships;
        }, []);

        const loadDataForCurrentIdea = useCallback(async () => {
            const ideaMembers = await loadDataForIdea(ideaId);
            if (ideaMembers && onMembersLoaded) onMembersLoaded(ideaMembers);
        }, [ideaId, loadDataForIdea, onMembersLoaded]);

        useEffect(() => {
            loadDataForCurrentIdea();
        }, [loadDataForCurrentIdea]);

        useEffect(() => {
            const reloadData = (e: RealTimeEventTypeMap['membership'][keyof RealTimeEventTypeMap['membership']] & RealTimeUpdateMembershipEventData) => {
                if (e.type === 'revoke' && e.userId === currentUserId) return;

                if (e.ideaId === ideaId) loadDataForCurrentIdea();
            };

            realTimeUpdatesEventHub.addCategoryListener('membership', reloadData);

            return () => realTimeUpdatesEventHub.removeCategoryListener('membership', reloadData);
        }, [currentUserId, ideaId, loadDataForCurrentIdea]);

        useImperativeHandle(
            ref,
            () => ({
                refresh: () => loadDataForIdea(ideaId)
            }),
            [ideaId, loadDataForIdea]
        );

        const dialog = useConfirmDialog();

        let itemClasses = 'k-icp-panel-section k-align-items-center k-gap-2';
        if (itemClassName) itemClasses += ' ' + itemClassName;

        const currentUserRole = members?.find(m => m.user.userId === currentUserId)?.role;
        const canEditMembers = currentUserRole === UserRole.Administrator;
        const canLeaveIdea = ideaAccount && currentUserId && ideaAccount.owner.userId !== currentUserId;
        const canRevokeInvite = currentUserRole === UserRole.Administrator;

        if (currentUserRole === UserRole.Viewer) edit = false;
        if (edit && !ideaTitle) throw new Error('ideaTitle is required in edit mode!');

        const onMembershipAction = (action: MemberAction) => {
            if (action.confirmation) {
                dialog.show({ ...action.confirmation, callback: () => action.callback(action.context) });
                return;
            }

            action.callback(action.context);
        };

        const onLeave = async (member: UserMembershipEntry) => {
            await ideasService.leave(ideaId);

            navigate('/');
        };

        const onRemoveMember = (member: UserMembershipEntry) => {
            ideasService.removeMember(ideaId, member.user.userId);

            setMembers(members => members?.filter(m => m.user.userId !== member.user.userId));
        };

        const onResendInvite = async (invite: Invite) => {
            const resentInvite = await ideasService.resendInvite(ideaId, invite.id);

            setInvites(invites => invites?.map(i => (i.id === invite.id ? resentInvite : i)));
            dispatch(addNotification({ content: `Invitation to ${invite.emailAddress} was resent` }));
        };

        const onRevokeInvite = (invite: Invite) => {
            setInvites(invites => invites?.filter(i => i.id !== invite.id));
            ideasService.revokeInvite(ideaId, invite.id);
        };

        const renderRow = (key: React.Key, avatar: ReactNode, memberDetails: ReactNode, role: ReactNode, actions?: MemberAction[]) => (
            <div className={itemClasses} key={key}>
                <div>{avatar}</div>
                <div className="k-flex-grow">{memberDetails}</div>
                <div className="k-shrink-0" style={{ width: edit ? 144 : 110 }}>
                    {role}
                </div>
                {edit && (
                    <div>
                        <DropDownButton
                            icon="more-horizontal"
                            fillMode="flat"
                            onItemClick={e => onMembershipAction(e.item)}
                            disabled={!actions || actions.length === 0}
                            item={MemberActionDropDownItem}
                            itemRender={(li: React.ReactElement<HTMLLIElement>, props: ButtonItemProps) => {
                                const item: MemberAction = props.dataItem;
                                if (item.separated)
                                    return (
                                        <>
                                            <li className="k-separator !k-m-0"></li>
                                            {li}
                                        </>
                                    );

                                return li;
                            }}
                            items={actions}
                            style={{ visibility: !actions ? 'hidden' : undefined }}
                        ></DropDownButton>
                    </div>
                )}
            </div>
        );

        const resolveMemberActions = (membership: UserMembershipEntry): MemberAction[] | undefined => {
            if (!edit) return undefined;

            if (membership.user.userId === currentUserId) {
                return canLeaveIdea
                    ? [
                          {
                              text: 'Leave',
                              separated: false,
                              context: membership,
                              callback: onLeave,
                              confirmation: {
                                  title: 'Leave startup',
                                  content: (
                                      <>
                                          Are you sure you want to leave <strong>“{ideaTitle}”</strong> startup?
                                      </>
                                  ),
                                  confirmButtonText: 'Leave startup'
                              }
                          }
                      ]
                    : [];
            }

            return canEditMembers && membership.user.userId !== ideaAccount!.owner.userId
                ? [
                      {
                          text: 'Remove',
                          separated: false,
                          context: membership,
                          callback: onRemoveMember,
                          confirmation: {
                              title: 'Remove member',
                              content: (
                                  <>
                                      Are you sure you want to remove{' '}
                                      <strong>
                                          {membership.user.firstName} {membership.user.lastName}
                                      </strong>{' '}
                                      from <strong>“{ideaTitle}”</strong> startup?
                                  </>
                              ),
                              confirmButtonText: 'Remove member'
                          }
                      }
                  ]
                : membership.user.userId === ideaAccount!.owner.userId
                ? []
                : undefined;
        };

        const resolveInviteActions = (invite: Invite): MemberAction[] | undefined => {
            if (!edit) return undefined;

            const inviteActions: MemberAction[] = [
                {
                    text: 'Resend Invite',
                    separated: false,
                    context: invite,
                    callback: onResendInvite
                }
            ];

            if (canRevokeInvite || invite.creator.userId === currentUserId)
                inviteActions.push({
                    text: 'Revoke',
                    separated: true,
                    context: invite,
                    callback: onRevokeInvite,
                    confirmation: {
                        title: 'Revoke pending invite',
                        content: (
                            <>
                                Are you sure you want to revoke the pending invite to <strong>{invite.emailAddress}</strong> for the{' '}
                                <strong>“{ideaTitle}”</strong>
                                startup?
                            </>
                        ),
                        confirmButtonText: 'Revoke invite'
                    }
                });

            return inviteActions;
        };

        const onMemberRoleChange = async (userId: string, newRole: UserRole) => {
            setMembers(members => members?.map(m => (m.user.userId === userId ? { ...m, role: newRole } : m)));
            const updatedMembership = await ideasService.updateMember(ideaId, userId, newRole);

            dispatch(
                addNotification({
                    content: `${updatedMembership.user.firstName} ${updatedMembership.user.lastName}’s permissions changed to ${
                        UserPermissionsMap[updatedMembership.role]
                    }`
                })
            );
        };

        if (loading)
            return (
                <div className="k-text-center">
                    <LoadingIndicator size="big" />
                </div>
            );

        return (
            <>
                {members?.map(m =>
                    renderRow(
                        m.user.userId,
                        <UserAvatar picture={m.user.picture} initials={generateInitials(m.user, 2)} colorIndex={getPreferredColorIndex(m.user)} />,
                        <StackLayout orientation="vertical">
                            <div>
                                {m.user.firstName} {m.user.lastName} {m.user.userId === currentUserId ? '(You)' : ''}
                            </div>
                            <div className="k-fs-sm k-icp-subtle-text">{m.user.emailAddress}</div>
                        </StackLayout>,
                        edit && canEditMembers && m.user.userId !== currentUserId && m.user.userId !== ideaAccount?.owner.userId ? (
                            <DropDownList
                                value={roleDropDownValues.find(r => r.value === m.role)}
                                fillMode="outline"
                                data={roleDropDownValues}
                                textField="text"
                                dataItemKey="value"
                                onChange={e => onMemberRoleChange(m.user.userId, e.value.value)}
                            />
                        ) : (
                            <span className="k-pl-2 k-border k-border-solid k-border-transparent">{UserPermissionsMap[m.role]}</span>
                        ),
                        resolveMemberActions(m)
                    )
                )}
                {invites?.map(i =>
                    renderRow(
                        i.emailAddress,
                        <Avatar className="k-icp-avatar-simple-bg-0 k-icp-avatar-gradient-bg" rounded="full" type="text" themeColor="base">
                            <MailSendIcon className="k-icp-icon k-disabled" />
                        </Avatar>,
                        <span className="k-fs-sm k-icp-subtle-text">{i.emailAddress}</span>,
                        edit && canEditMembers ? (
                            <DropDownList value={UserPermissionsMap[i.role]} disabled={true} fillMode="outline" />
                        ) : (
                            <span className="k-pl-2 k-border k-border-solid k-border-transparent">{UserPermissionsMap[i.role]}</span>
                        ),
                        resolveInviteActions(i)
                    )
                )}
                {dialog.element}
            </>
        );
    }
);
