import { Slide } from '@progress/kendo-react-animation';
import { StackLayout } from '@progress/kendo-react-layout';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDeleteItem } from '../../hooks/canvasHooks';
import { useHighlight } from '../../hooks/highlightsHooks';
import { useRealTimeUpdatesPipe } from '../../hooks/realTimeUpdatesHooks';
import { BoxItem, BoxMetadata, BoxType, canvasService, IvaValidationHints, RelatedTaskData } from '../../services/canvasService';
import { combineClassNames } from '../../services/common';
import { ActionType } from '../../services/usersService';
import { BoxItemState, CanvasBoxState, reorderItem, saveItem, setEditingItem } from '../../state/canvas/canvasSlice';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { IVAValidationFunction } from '../common/IVAValidationPopup';
import { CanvasBoxItemsList, CanvasBoxItemsSortableList, CanvasBoxListItem } from './boxItemsList';
import CanvasBoxItem, { CanvasBoxItemProps } from './item';

export default function CanvasBox({
    box,
    relatableItems,
    canEdit = false,
    editContent,
    className,
    relatedIdsFilter,
    itemsIdsFilter,
    autoHideInsertControls = true,
    itemHints,
    ivaValidationHints,
    hintsValidationFunction,
    onBeginCommit,
    onEndCommit,
    onCancel,
    onSave,
    requireRelatedItems,
    mainRelatedItemsIds,
    hideMemberActivity,
    emptyView,
    hideFooter = false
}: {
    box: CanvasBoxState;
    relatableItems?: BoxItem[];
    canEdit?: boolean;
    editContent?: string;
    className?: string;
    relatedIdsFilter?: number[];
    itemsIdsFilter?: number[];
    autoHideInsertControls?: boolean;
    itemHints?: ReactElement;
    ivaValidationHints?: IvaValidationHints;
    hintsValidationFunction?: IVAValidationFunction;
    onBeginCommit?: () => void;
    onEndCommit?: () => void;
    onCancel?: () => void;
    onSave?: (explicit: boolean, content: string, relatedItemIds?: number[], colorCode?: string) => void;
    requireRelatedItems?: boolean;
    mainRelatedItemsIds?: number[];
    hideMemberActivity?: boolean;
    emptyView?: ReactElement | ((metadata: BoxMetadata) => ReactElement);
    hideFooter?: boolean;
}) {
    const dispatch = useAppDispatch();
    const metadata = useMemo(() => canvasService.getBoxMetadata(box.type), [box.type]);
    const boxElementRef = useRef<HTMLDivElement>(null);
    const setShowHighlight = useHighlight(boxElementRef, metadata.highlight);
    const [autoFocusItem, setAutoFocusItem] = useState(false);
    const [reorderInProgress, setReorderInProgress] = useState(false);
    const [insertItemKey, setInsertItemKey] = useState(0);
    const autoShowHints = !!ivaValidationHints || !!itemHints;
    const [deleteItem, confirmDeleteItemDialog] = useDeleteItem();

    useEffect(() => {
        setShowHighlight(true);
    }, [setShowHighlight]);

    useEffect(() => {
        if (editContent) setAutoFocusItem(true);
    }, [editContent]);

    const onItemDragStarted = () => {
        setReorderInProgress(true);
    };

    const onItemReordered = (id: number, newIndex: number) => {
        dispatch(reorderItem({ boxType: box.type, itemId: id, newIndex: newIndex }));
    };

    const resetInsertItem = () => {
        setInsertItemKey(k => ++k);
    };

    const onItemSave = async (isExplicit: boolean, content: string, relatedItemIds?: number[], colorCode?: string, itemId?: number) => {
        if (metadata.allowColoring && !colorCode) colorCode = canvasService.getLeastUsedColor(box.items);

        await dispatch(
            saveItem({
                boxType: box.type,
                content: content,
                relatedItemIds: relatedItemIds,
                colorCode: colorCode,
                itemId: itemId,
                editTruncatedCallback: () => {
                    setAutoFocusItem(true);
                }
            })
        );

        if (isExplicit) setAutoFocusItem(true);

        if (!itemId) resetInsertItem();
    };

    const stopAutoFocus = useCallback(() => setAutoFocusItem(false), []);
    let boxItems = box.items;
    if (relatedIdsFilter) boxItems = boxItems.filter(i => i.relatedItemIds?.some(relatedItemId => relatedIdsFilter.includes(relatedItemId)));
    if (itemsIdsFilter) boxItems = boxItems.filter(i => itemsIdsFilter.includes(i.id));
    const hasItemInEdit = boxItems.some(i => i.isEditing);
    const realTimeUpdatesPipe = useRealTimeUpdatesPipe();

    const getItemProperties = <T extends object | undefined>(item: BoxItemState, additionalProperties: T): CanvasBoxItemProps & T => ({
        ...additionalProperties,
        boxType: box.type,
        itemContent: item.content,
        itemColorCode: item.colorCode,
        relatedItemIds: item.relatedItemIds,
        isEditing: item.isEditing,
        editContent: item.editContent,
        onEdit: () => {
            setAutoFocusItem(true);
            dispatch(setEditingItem({ boxType: box.type, itemId: item.id, isEditing: true }));
            realTimeUpdatesPipe?.setPendingAction({ type: 'canvass-box', box: box.type, operation: ActionType.Edit });
        },
        onCancel: (isExplicit: boolean) => {
            dispatch(setEditingItem({ boxType: box.type, itemId: item.id, isEditing: false }));
            if (isExplicit) setAutoFocusItem(true);
            realTimeUpdatesPipe?.setPendingAction(undefined);
        },
        onSave: async (explicit: boolean, content: string, relatedItemIds?: number[], colorCode?: string) => {
            await onItemSave(explicit, content, relatedItemIds, colorCode, item.id);
            realTimeUpdatesPipe?.setPendingAction(undefined);
        },
        onDelete: () => deleteItem(box.type, item.id),
        relatableItems: relatableItems,
        filterRelatableItems: !!mainRelatedItemsIds?.length,
        allowedRelatableItemsIds: mainRelatedItemsIds?.length ? mainRelatedItemsIds : undefined,
        canEdit: canEdit,
        autoFocus: autoFocusItem,
        ivaValidationHints: ivaValidationHints,
        hintsValidationFunction: hintsValidationFunction,
        hints: itemHints,
        onAutoFocus: stopAutoFocus,
        showEmptyValidationMessage: true,
        autoShowHints: autoShowHints,
        allowColoring: metadata.allowColoring,
        onBeginCommit: onBeginCommit,
        onEndCommit: onEndCommit,
        requireRelatedItems: !metadata.allowColoring && requireRelatedItems
    });

    const insertItemElement: ReactElement | undefined =
        canEdit && !hasItemInEdit ? (
            <CanvasBoxItem
                boxType={box.type}
                key={insertItemKey}
                relatedItemIds={mainRelatedItemsIds?.length ? mainRelatedItemsIds : undefined}
                isEditing={true}
                onCancel={() => {
                    resetInsertItem();
                    realTimeUpdatesPipe?.setPendingAction(undefined);
                    onCancel?.();
                }}
                onSave={async (explicit: boolean, content: string, relatedItemIds?: number[], colorCode?: string) => {
                    await onItemSave(explicit, content, relatedItemIds, colorCode);
                    realTimeUpdatesPipe?.setPendingAction(undefined);
                    onSave?.(explicit, content, relatedItemIds, colorCode);
                }}
                editContent={editContent}
                canEdit={true}
                relatableItems={relatableItems}
                filterRelatableItems={!!mainRelatedItemsIds?.length}
                allowedRelatableItemsIds={mainRelatedItemsIds?.length ? mainRelatedItemsIds : undefined}
                autoFocus={autoFocusItem}
                onAutoFocus={stopAutoFocus}
                itemShorthand={metadata.itemShorthand}
                elementAttributes={{ style: reorderInProgress ? { visibility: 'hidden' } : undefined }}
                autoHideControls={autoHideInsertControls}
                hints={itemHints}
                ivaValidationHints={ivaValidationHints}
                hintsValidationFunction={hintsValidationFunction}
                autoShowHints={autoShowHints}
                allowColoring={metadata.allowColoring}
                onBeginCommit={onBeginCommit}
                onEndCommit={onEndCommit}
                requireRelatedItems={!metadata.allowColoring && requireRelatedItems}
                onEditorFocus={() => {
                    realTimeUpdatesPipe?.setPendingAction({ type: 'canvass-box', box: box.type, operation: ActionType.Add });
                }}
            />
        ) : (
            undefined
        );

    if (metadata.hide) return null;

    const emptyViewElement = emptyView ? (typeof emptyView === 'function' ? emptyView(metadata) : emptyView) : undefined;

    return (
        <div className={combineClassNames(`canvas-box k-d-flex-col canvas-box-${box.type.toLowerCase()}`, className)} ref={boxElementRef}>
            <div className="canvas-box-header">
                <div className="k-text-ellipsis">
                    <span title={metadata.title}>{metadata.title}</span>
                </div>
            </div>
            <div className="canvas-box-content k-flex-1">
                {!boxItems.length && emptyViewElement ? (
                    emptyViewElement
                ) : mainRelatedItemsIds?.length ? (
                    <GroupedCanvasBoxItemsLayout
                        mainRelatedItemsIds={mainRelatedItemsIds}
                        items={boxItems.map(i => getItemProperties(i, { key: i.id }))}
                        insertItemElement={insertItemElement}
                        itemShorthand={metadata.itemShorthand}
                    />
                ) : (
                    <>
                        {!!boxItems.length &&
                            (canEdit ? (
                                <CanvasBoxItemsSortableList
                                    items={boxItems.map(i => getItemProperties(i, { id: i.id }))}
                                    onItemDragStarted={onItemDragStarted}
                                    onItemReordered={onItemReordered}
                                    onItemDragEnded={() => setReorderInProgress(false)}
                                    dropAreaClueElement={boxElementRef.current}
                                />
                            ) : (
                                <CanvasBoxItemsList items={boxItems.map(i => getItemProperties(i, { key: i.id }))} />
                            ))}
                        {insertItemElement}
                    </>
                )}
            </div>
            {!hideFooter && (
                <StackLayout align={{ horizontal: 'end', vertical: 'bottom' }} className="canvas-box-footer k-gap-2">
                    {!hideMemberActivity && (
                        <div className="k-flex-1 k-fs-sm">
                            <MembersActivityComponent boxType={box.type} />
                        </div>
                    )}
                    <span className="canvas-box-index">{metadata.index}</span>
                </StackLayout>
            )}
            {confirmDeleteItemDialog}
        </div>
    );
}

const secondaryItemClassName = 'canvas-box-item-secondary';
function GroupedCanvasBoxItemsLayout({
    mainRelatedItemsIds,
    items,
    insertItemElement,
    itemShorthand
}: {
    mainRelatedItemsIds: number[];
    items?: CanvasBoxListItem[];
    insertItemElement: ReactElement | undefined;
    itemShorthand?: string;
}) {
    const mainItems: CanvasBoxListItem[] = [];
    const additionalItems: CanvasBoxListItem[] = [];

    items?.forEach(item => {
        const isRelatedToAllMainRelatedItems =
            mainRelatedItemsIds.length && item.relatedItemIds?.length && !mainRelatedItemsIds.some(r => !item.relatedItemIds!.includes(r));
        if (isRelatedToAllMainRelatedItems) mainItems.push(item);
        else
            additionalItems.push({
                ...item,
                elementAttributes: item.elementAttributes
                    ? { ...item.elementAttributes, className: combineClassNames(item.elementAttributes.className, secondaryItemClassName) }
                    : { className: secondaryItemClassName }
            });
    });

    return (
        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-8">
            <div>
                <CanvasBoxItemsList items={mainItems} />
                {insertItemElement}
            </div>
            {additionalItems.length > 0 && (
                <div>
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1 k-mb-1">
                        <div className="k-flex-1 full-horizontal-separator"></div>
                        <span className="k-text-uppercase k-fs-sm k-icp-subtle-text">Relate {itemShorthand ?? 'one'} from another segment</span>
                        <div className="k-flex-1 full-horizontal-separator"></div>
                    </StackLayout>
                    <CanvasBoxItemsList items={additionalItems} />
                </div>
            )}
        </StackLayout>
    );
}

export function MembersActivityComponent({ boxType }: { boxType: BoxType }) {
    const formatAdding = (names?: string[]) => {
        if (!names || names.length === 0) {
            return undefined;
        }

        if (names.length === 1) {
            return `${names[0]} is adding an item`;
        }

        if (names.length === 2) {
            return `${names[0]} and ${names[1]} are adding items`;
        }

        if (names.length === 3) {
            return `${names[0]}, ${names[1]} and ${names[2]} are adding items`;
        }

        if (names.length === 4) {
            return `${names[0]}, ${names[1]}, ${names[2]} and ${names[3]} are adding items`;
        }

        return `${names[0]}, ${names[1]}, ${names[2]} and ${names.length - 3} others are adding items`;
    };

    const formatEditing = (names?: string[]) => {
        if (!names || names.length === 0) {
            return undefined;
        }

        if (names.length === 1) {
            return `${names[0]} is editing`;
        }

        if (names.length === 2) {
            return `${names[0]} and ${names[1]} are editing`;
        }

        if (names.length === 3) {
            return `${names[0]}, ${names[1]} and ${names[2]} are editing`;
        }

        if (names.length === 4) {
            return `${names[0]}, ${names[1]}, ${names[2]} and ${names[3]} are editing`;
        }

        return `${names[0]}, ${names[1]}, ${names[2]} and ${names.length - 3} others are editing`;
    };

    const membersActivity = useAppSelector(s => s.ideaMembers.membersActivity);

    const namesAdding = membersActivity
        ?.filter(ma => ma.pendingActions.some(pa => pa.type === 'canvass-box' && pa.box === boxType && pa.operation === ActionType.Add))
        .map(ma => ma.user.firstName);

    const namesEditing = membersActivity
        ?.filter(ma => ma.pendingActions.some(pa => pa.type === 'canvass-box' && pa.box === boxType && pa.operation === ActionType.Edit))
        .map(ma => ma.user.firstName);

    const addingSentence = formatAdding(namesAdding);
    const editingSentence = formatEditing(namesEditing);

    return (
        <Slide appear={true} enter={true} exit={true} direction="up">
            {addingSentence && (
                <div key="collab-box-add" className="canvas-box-activity k-px-1 k-py-thin k-mb-thin">
                    <span>
                        <span className="-bw">{addingSentence}</span>
                        <TypingIndicator />
                    </span>
                </div>
            )}
            {editingSentence && (
                <div key="collab-box-edit" className="canvas-box-activity k-px-1 k-py-thin k-mb-thin">
                    <span>
                        <span className="-bw">{editingSentence}</span>
                        <TypingIndicator />
                    </span>
                </div>
            )}
        </Slide>
    );
}

export function TypingIndicator() {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="typing-indicator k-gap-thin k-display-inline-flex k-ml-1">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
        </StackLayout>
    );
}

export function TaskRelatedEmptyItemsView({ baseUrl, boxTitle, relatedTask }: { baseUrl?: string; boxTitle: string; relatedTask: RelatedTaskData }) {
    return (
        <div className="k-icp-subtle-text">
            Follow the{' '}
            <Link to={`${baseUrl ?? ''}${relatedTask.sequence}/${relatedTask.tag}`} className="k-icp-hover-text-base k-text-underline">
                guidance in Task {relatedTask.index}
            </Link>{' '}
            to define your {boxTitle}.
        </div>
    );
}
