import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { StackLayout } from '@progress/kendo-react-layout';
import { Country } from 'country-list';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { ReactComponent as CreateIcon } from '../../icons/check.svg';
import { ReactComponent as ExpandIcon } from '../../icons/chevrons-down.svg';
import { ReactComponent as CollapseIcon } from '../../icons/chevrons-up.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-2.svg';
import { ReactComponent as AddIcon } from '../../icons/plus.svg';
import { ReactComponent as DeleteIcon } from '../../icons/trash-2.svg';
import { ReactComponent as RemoveIcon } from '../../icons/x.svg';
import tagImageUrl from '../../images/tag-illustration.svg';
import { TagEventData, appEventHub } from '../../services/appEvents';
import { BoxItem, BoxType } from '../../services/canvasService';
import { combineClassNames, debounce } from '../../services/common';
import {
    CompanyEditData,
    ContactTag,
    ContactTagStats,
    EducationLevel,
    FullCompany,
    FullPerson,
    MaritalStatus,
    PersonEditData,
    PersonSex,
    contactsService
} from '../../services/contactsService';
import { RealTimeUpdateContactTagEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { useAppDispatch } from '../../state/hooks';
import { addNotification } from '../../state/notifications/platformNotificationsSlice';
import { CanvasItemSimpleView } from '../canvas/canvasItemSimpleView';
import { canvasItemsZoneDependent, useRequireCanvasBoxItemsInZone } from '../canvas/canvasItemsZone';
import { CountriesComboBox } from '../common/countries';
import { KeyboardNavigatableDateInput } from '../common/date';
import { CellTemplateProps, createCellTemplateFromComponent } from '../common/grid';
import {
    BadgeUploadInput,
    UrlInput,
    ValidatedInput,
    ValidatedInputHorizontalLayout,
    dateInThePastValidator,
    emailValidator,
    maxLengthValidator,
    phoneValidator,
    requiredValidator,
    urlValidator
} from '../ui/inputs';
import LoadingIndicator from '../ui/loadingIndicator';
import { H3, P } from '../ui/typography';
import { CompanyPicker, PersonPicker } from './contactPicker';
import { ContactSidePanel } from './view';

const companyNameValidators = [requiredValidator('Company name'), maxLengthValidator('Company name', 50)];
const companyWebsiteValidators = [urlValidator('Company website'), maxLengthValidator('Company website', 2048)];
const cityValidators = [maxLengthValidator('City', 50)];
const addressValidators = [maxLengthValidator('Mailing address', 350)];

export function CompanyEditor({ countriesList, className }: { countriesList?: Country[]; className?: string }) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className={combineClassNames('k-gap-10', className)}>
            <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4 k-flex-1">
                <H3>Basic information</H3>

                <Field
                    name="name"
                    component={ValidatedInput}
                    label="Name"
                    validator={companyNameValidators}
                    maxLength={50}
                    layout={ValidatedInputHorizontalLayout}
                    wrapperClass="k-align-self-stretch"
                    placeholder="Add details..."
                />

                <Field
                    name="website"
                    component={ValidatedInput}
                    inputType={UrlInput}
                    label="Website"
                    validator={companyWebsiteValidators}
                    maxLength={2048}
                    layout={ValidatedInputHorizontalLayout}
                    wrapperClass="k-align-self-stretch"
                    placeholder="Add details..."
                />

                <Field
                    name="country"
                    component={ValidatedInput}
                    label="Country"
                    inputType={CountriesComboBox}
                    data={countriesList}
                    layout={ValidatedInputHorizontalLayout}
                    wrapperClass="k-align-self-stretch"
                    placeholder="Add details..."
                />

                <Field
                    name="city"
                    component={ValidatedInput}
                    label="City"
                    validator={cityValidators}
                    maxLength={50}
                    layout={ValidatedInputHorizontalLayout}
                    wrapperClass="k-align-self-stretch"
                    placeholder="Add details..."
                />

                <Field
                    name="address"
                    component={ValidatedInput}
                    label="Mailing address"
                    validator={addressValidators}
                    maxLength={350}
                    layout={ValidatedInputHorizontalLayout}
                    wrapperClass="k-align-self-stretch"
                    placeholder="Add details..."
                />
            </StackLayout>
            <ContactSidePanel className="-w2">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6">
                    <div>
                        <H3 className="!k-mb-4">Picture</H3>
                        <Field
                            name="picture"
                            component={ValidatedInput}
                            inputType={BadgeUploadInput}
                            upload={(f: File) => contactsService.uploadCompanyImage(f)}
                        />
                    </div>
                    <div className="full-horizontal-separator" />
                    <div>
                        <H3 className="!k-mb-4">Social</H3>
                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                            <ContactSocialEditor />
                        </StackLayout>
                    </div>
                </StackLayout>
            </ContactSidePanel>
        </StackLayout>
    );
}

export function CreateCompanyDialog({
    ideaId,
    onCreated,
    onCancel,
    initialName
}: {
    ideaId: string;
    onCreated?: (company: FullCompany) => void;
    onCancel?: () => void;
    initialName?: string;
}) {
    const [createDisabled, createCompanyCallbackCreator] = useSingleClickButton();

    return (
        <Dialog title="Add new company" width={640} className="k-icp-dialog-no-padding k-icp-dialog-with-form" onClose={onCancel}>
            <Form
                onSubmit={createCompanyCallbackCreator(async (data: Record<string, any>) => {
                    const newCompanyData: CompanyEditData = {
                        name: data.nameDialog,
                        website: data.websiteDialog,
                        linkedIn: data.linkedInDialog,
                        twitter: data.twitterDialog,
                        facebook: data.facebookDialog,
                        picture: data.pictureDialog
                    };
                    const newCompany = await contactsService.createCompany(ideaId, newCompanyData);
                    onCreated?.(newCompany);
                })}
                ignoreModified={true}
                initialValues={initialName ? { nameDialog: initialName } : undefined}
                render={formRenderProps => (
                    <FormElement className="k-icp-component-border">
                        <div className="k-window-content">
                            <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1 k-gap-4">
                                    <Field
                                        name="nameDialog"
                                        component={ValidatedInput}
                                        label="Name"
                                        validator={companyNameValidators}
                                        maxLength={50}
                                        layout={ValidatedInputHorizontalLayout}
                                        placeholder="Add details..."
                                        wrapperClass="k-icp-horizontal-input-narrow"
                                    />

                                    <Field
                                        name="websiteDialog"
                                        component={ValidatedInput}
                                        inputType={UrlInput}
                                        label="Website"
                                        validator={companyWebsiteValidators}
                                        maxLength={2048}
                                        layout={ValidatedInputHorizontalLayout}
                                        placeholder="Add details..."
                                        wrapperClass="k-icp-horizontal-input-narrow"
                                    />

                                    <ValidatedInputHorizontalLayout
                                        className="k-icp-horizontal-input-narrow"
                                        label={<Label>Social</Label>}
                                        input={
                                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                                                <ContactSocialEditor fieldsSuffix="Dialog" />
                                            </StackLayout>
                                        }
                                    />
                                </StackLayout>

                                <Field
                                    name="pictureDialog"
                                    component={ValidatedInput}
                                    inputType={BadgeUploadInput}
                                    upload={(f: File) => contactsService.uploadCompanyImage(f)}
                                    wrapperClass="k-flex-basis-0"
                                />
                            </StackLayout>
                        </div>

                        <DialogActionsBar layout="center">
                            <Button disabled={!formRenderProps.allowSubmit || createDisabled} type="submit" themeColor="primary">
                                Save company
                            </Button>
                            <Button type="reset" onClick={onCancel}>
                                Cancel
                            </Button>
                        </DialogActionsBar>
                    </FormElement>
                )}
            />
        </Dialog>
    );
}

const linkedInValidators = [urlValidator('LinkedIn'), maxLengthValidator('LinkedIn', 2048)];
const facebookValidators = [urlValidator('Facebook'), maxLengthValidator('Facebook', 2048)];
const twitterValidators = [urlValidator('Twitter'), maxLengthValidator('Twitter', 2048)];
function ContactSocialEditor({ fieldsSuffix }: { fieldsSuffix?: string }) {
    return (
        <>
            <Field
                name={`linkedIn${fieldsSuffix ?? ''}`}
                component={ValidatedInput}
                inputType={UrlInput}
                icon="linkedin"
                placeholder="www.linkedin.com/in/..."
                validator={linkedInValidators}
                maxLength={2048}
            />

            <Field
                name={`twitter${fieldsSuffix ?? ''}`}
                component={ValidatedInput}
                inputType={UrlInput}
                icon="twitter"
                placeholder="twitter.com/..."
                validator={twitterValidators}
                maxLength={2048}
            />

            <Field
                name={`facebook${fieldsSuffix ?? ''}`}
                component={ValidatedInput}
                inputType={UrlInput}
                icon="facebook"
                placeholder="www.facebook.com/..."
                validator={facebookValidators}
                maxLength={2048}
            />
        </>
    );
}

const firstNameValidators = [requiredValidator('First name'), maxLengthValidator('First name', 50)];
const lastNameValidators = [requiredValidator('Last name'), maxLengthValidator('Last name', 50)];
const emailValidators = [emailValidator, maxLengthValidator('Email', 320)];
const phoneValidators = [maxLengthValidator('Phone', 50)];
const jobTitleValidators = [maxLengthValidator('Job title', 50)];
const workPhoneValidators = [maxLengthValidator('Work phone', 50)];
const otherAddressValidators = [maxLengthValidator('Other address', 350)];
const secondaryEmailValidators = [emailValidator, maxLengthValidator('Secondary email', 320)];
const educationFieldValidators = [maxLengthValidator('Field of study', 50)];
const birthDateValidators = [dateInThePastValidator('Date of birth')];
export const defaultDropDownItem = 'Add details...';

export function PersonEditor({
    countriesList,
    customerSegments,
    ideaId,
    personId,
    className,
    onPhoneFieldChange,
    phoneNumberErrorMessage
}: {
    countriesList?: Country[];
    customerSegments?: BoxItem[];
    ideaId: string;
    personId?: number;
    className?: string;
    onPhoneFieldChange?: () => void;
    phoneNumberErrorMessage?: string;
}) {
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className={combineClassNames('k-gap-10', className)}>
            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1 k-gap-8">
                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                    <H3>Basic details</H3>
                    <Field
                        name="firstName"
                        component={ValidatedInput}
                        label="First name"
                        validator={firstNameValidators}
                        maxLength={50}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                    />
                    <Field
                        name="lastName"
                        component={ValidatedInput}
                        label="Last name"
                        validator={lastNameValidators}
                        maxLength={50}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                    />
                </StackLayout>

                <div className="full-horizontal-separator" />

                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                    <H3>Reach out details</H3>
                    <Field
                        name="emailAddress"
                        component={ValidatedInput}
                        label="Email"
                        validator={emailValidators}
                        maxLength={320}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                    />
                    <Field
                        name="phoneNumber"
                        component={ValidatedInput}
                        label="Phone number"
                        validator={phoneValidators}
                        maxLength={50}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                        errorMessage={phoneNumberErrorMessage}
                        onChange={onPhoneFieldChange}
                    />
                </StackLayout>

                <div className="full-horizontal-separator" />

                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                    <H3>Additional details</H3>
                    <Field
                        name="country"
                        component={ValidatedInput}
                        label="Country"
                        inputType={CountriesComboBox}
                        data={countriesList}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                    />
                    <Field
                        name="city"
                        component={ValidatedInput}
                        label="City"
                        validator={cityValidators}
                        maxLength={50}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                    />
                    <Field
                        name="address"
                        component={ValidatedInput}
                        label="Mailing address"
                        validator={addressValidators}
                        maxLength={350}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                    />
                    <Field
                        name="sex"
                        component={ValidatedInput}
                        label="Sex"
                        inputType={DropDownList}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        data={[PersonSex.Male, PersonSex.Female, PersonSex.Other]}
                        defaultItem={defaultDropDownItem}
                    />
                    <Field
                        name="birthDate"
                        component={ValidatedInput}
                        label="Date of birth"
                        validator={birthDateValidators}
                        inputType={DatePicker}
                        dateInput={KeyboardNavigatableDateInput}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                        max={new Date()}
                    />
                    <Field
                        name="maritalStatus"
                        component={ValidatedInput}
                        label="Marital status"
                        inputType={DropDownList}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        data={[
                            MaritalStatus.Married,
                            MaritalStatus.Single,
                            MaritalStatus.NeverMarried,
                            MaritalStatus.Divorced,
                            MaritalStatus.Separated,
                            MaritalStatus.Widowed
                        ]}
                        defaultItem={defaultDropDownItem}
                    />
                    <Field
                        name="referredByContact"
                        component={ValidatedInput}
                        label="Referred by"
                        inputType={PersonPicker}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        ideaId={ideaId}
                        excludedPeopleIds={personId ? [personId] : undefined}
                    />
                </StackLayout>

                <div className="full-horizontal-separator" />

                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                    <H3>Professional details</H3>

                    <Field
                        name="jobTitle"
                        component={ValidatedInput}
                        label="Job title"
                        validator={jobTitleValidators}
                        maxLength={50}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                    />
                    <Field
                        name="company"
                        component={ValidatedInput}
                        label="Company"
                        inputType={CompanyPicker}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        ideaId={ideaId}
                        allowCreate={true}
                        placeholder="Add details..."
                    />
                    <Field
                        name="workPhoneNumber"
                        component={ValidatedInput}
                        label="Work phone"
                        validator={workPhoneValidators}
                        maxLength={50}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                    />
                    <Field
                        name="otherAddress"
                        component={ValidatedInput}
                        label="Other address"
                        validator={otherAddressValidators}
                        maxLength={350}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                    />
                    <Field
                        name="secondaryEmailAddress"
                        component={ValidatedInput}
                        label="Secondary email"
                        validator={secondaryEmailValidators}
                        maxLength={320}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                    />
                </StackLayout>

                <StackLayout orientation="vertical" align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-4">
                    <H3>Education</H3>

                    <Field
                        name="educationLevel"
                        component={ValidatedInput}
                        label="Education level"
                        inputType={DropDownList}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        data={[
                            EducationLevel.None,
                            EducationLevel.Primary,
                            EducationLevel.Secondary,
                            EducationLevel.Bachelor,
                            EducationLevel.Master,
                            EducationLevel.Doctorate
                        ]}
                        defaultItem={defaultDropDownItem}
                    />
                    <Field
                        name="educationField"
                        component={ValidatedInput}
                        label="Field of study"
                        validator={educationFieldValidators}
                        maxLength={50}
                        layout={ValidatedInputHorizontalLayout}
                        wrapperClass="k-align-self-stretch"
                        placeholder="Add details..."
                    />
                </StackLayout>
            </StackLayout>

            <ContactSidePanel className="k-align-self-stretch -w2">
                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-6">
                    <div>
                        <H3 className="!k-mb-4">Picture</H3>
                        <Field
                            name="picture"
                            component={ValidatedInput}
                            inputType={BadgeUploadInput}
                            upload={(f: File) => contactsService.uploadPersonImage(f)}
                        />
                    </div>
                    <div className="full-horizontal-separator" />
                    <div>
                        <H3 className="!k-mb-4">Relate to Customer segment</H3>
                        <Field
                            name="customerSegmentIds"
                            component={ValidatedInput}
                            inputType={BoxItemsPicker}
                            items={customerSegments}
                            emptyText="No customer segments available"
                            className="k-panel !k-px-2 !k-py-3"
                        />
                    </div>
                    <div className="full-horizontal-separator" />
                    <div>
                        <H3 className="!k-mb-4">Tags</H3>
                        <Field
                            name="tags"
                            component={ValidatedInput}
                            inputType={ContactTagsPicker}
                            ideaId={ideaId}
                            placeholder="Type to select or create tag..."
                            wrapperClass="k-mb-1"
                        />
                        <ManageContactsTagsButton ideaId={ideaId} />
                    </div>
                    <div className="full-horizontal-separator" />
                    <div>
                        <H3 className="!k-mb-4">Social</H3>
                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                            <ContactSocialEditor />
                        </StackLayout>
                    </div>
                </StackLayout>
            </ContactSidePanel>
        </StackLayout>
    );
}

export function usePhoneValidation() {
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState<string>();

    async function validate(phoneNumber?: string): Promise<boolean> {
        const phoneNumberErrorMessage = phoneNumber && ((await phoneValidator(phoneNumber, 'Phone')) || undefined);

        setPhoneNumberErrorMessage(phoneNumberErrorMessage);

        return !phoneNumberErrorMessage;
    }

    function onPhoneNumberChange() {
        setPhoneNumberErrorMessage(undefined);
    }

    return {
        phoneNumberErrorMessage,
        validate,
        onPhoneNumberChange
    };
}

export function useAdvancedPersonValidation() {
    const [reachOutFieldsErrorMessage, setReachOutFieldsErrorMessage] = useState<string>();
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState<string>();

    async function validate(email?: string, phoneNumber?: string): Promise<boolean> {
        const reachOutFieldsErrorMessage = !email && !phoneNumber ? 'Email or Phone is required' : undefined;
        const phoneNumberErrorMessage = phoneNumber && ((await phoneValidator(phoneNumber, 'Phone')) || undefined);

        setReachOutFieldsErrorMessage(reachOutFieldsErrorMessage);
        setPhoneNumberErrorMessage(phoneNumberErrorMessage);

        return !reachOutFieldsErrorMessage && !phoneNumberErrorMessage;
    }

    function onReachOutFieldChange(fieldName: string) {
        setReachOutFieldsErrorMessage(undefined);

        if (fieldName === 'phoneNumber') setPhoneNumberErrorMessage(undefined);
    }

    return {
        reachOutFieldsErrorMessage,
        phoneNumberErrorMessage,
        validate,
        onReachOutFieldChange
    };
}

export const CreatePersonDialog = canvasItemsZoneDependent<{
    ideaId: string;
    onCreated?: (company: FullPerson) => void;
    onCancel?: () => void;
    initialData?: { firstName?: string; lastName?: string; customerSegmentIds?: number[] };
}>(function({ ideaId, onCreated, onCancel, initialData }) {
    const customerSegments = useRequireCanvasBoxItemsInZone(BoxType.CustomerSegments);
    const [createDisabled, createPersonCallbackCreator] = useSingleClickButton();
    const [showAdditionalFields, setShowAdditionalFields] = useState<Boolean>();
    const phoneValidation = usePhoneValidation();

    return (
        <Dialog title="Add new contact" width={640} className="k-icp-dialog-no-padding k-icp-dialog-with-form" onClose={onCancel}>
            <Form
                onSubmit={createPersonCallbackCreator(async (data: Record<string, any>) => {
                    if (!(await phoneValidation.validate(data.phoneNumberDialog))) return;

                    const createData: PersonEditData = {
                        firstName: data.firstNameDialog,
                        lastName: data.lastNameDialog,
                        emailAddress: data.emailAddressDialog || undefined,
                        phoneNumber: data.phoneNumberDialog || undefined,
                        facebook: data.facebookDialog || undefined,
                        linkedIn: data.linkedInDialog || undefined,
                        twitter: data.twitterDialog || undefined,
                        picture: data.pictureDialog || undefined,
                        customerSegmentIds: data.customerSegmentIdsDialog,
                        tagIds: (data.tagsDialog as ContactTag[] | undefined)?.map(t => t.id)
                    };

                    const newPerson = await contactsService.createPerson(ideaId!, createData);
                    onCreated?.(newPerson);
                })}
                ignoreModified={true}
                initialValues={
                    initialData
                        ? {
                              firstNameDialog: initialData.firstName,
                              lastNameDialog: initialData.lastName,
                              customerSegmentIdsDialog: initialData.customerSegmentIds
                          }
                        : undefined
                }
                render={formRenderProps => (
                    <FormElement className="k-icp-component-border">
                        <div className="k-window-content">
                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                                <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-6">
                                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-flex-1 k-gap-4">
                                        <Field
                                            name="firstNameDialog"
                                            component={ValidatedInput}
                                            label="First name"
                                            validator={firstNameValidators}
                                            maxLength={50}
                                            layout={ValidatedInputHorizontalLayout}
                                            wrapperClass="k-icp-horizontal-input-narrow"
                                            placeholder="Add details..."
                                        />

                                        <Field
                                            name="lastNameDialog"
                                            component={ValidatedInput}
                                            label="Last name"
                                            validator={lastNameValidators}
                                            maxLength={50}
                                            layout={ValidatedInputHorizontalLayout}
                                            wrapperClass="k-icp-horizontal-input-narrow"
                                            placeholder="Add details..."
                                        />
                                    </StackLayout>

                                    <Field
                                        name="pictureDialog"
                                        component={ValidatedInput}
                                        inputType={BadgeUploadInput}
                                        upload={(f: File) => contactsService.uploadCompanyImage(f)}
                                        wrapperClass="k-flex-basis-0"
                                    />
                                </StackLayout>
                                <div className="full-horizontal-separator" />
                                <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-4">
                                    <H3>Reach out details</H3>
                                    <Field
                                        name="emailAddressDialog"
                                        component={ValidatedInput}
                                        label="Email"
                                        validator={emailValidators}
                                        maxLength={320}
                                        layout={ValidatedInputHorizontalLayout}
                                        wrapperClass="k-icp-horizontal-input-narrow"
                                        placeholder="Add details..."
                                    />
                                    <Field
                                        name="phoneNumberDialog"
                                        component={ValidatedInput}
                                        label="Phone number"
                                        validator={phoneValidators}
                                        maxLength={50}
                                        layout={ValidatedInputHorizontalLayout}
                                        wrapperClass="k-icp-horizontal-input-narrow"
                                        placeholder="Add details..."
                                        errorMessage={phoneValidation.phoneNumberErrorMessage}
                                        onChange={() => phoneValidation.onPhoneNumberChange()}
                                    />
                                </StackLayout>

                                {typeof customerSegments === 'undefined' ||
                                    (customerSegments.length > 0 && (
                                        <>
                                            <div className="full-horizontal-separator" />

                                            <Field
                                                name="customerSegmentIdsDialog"
                                                component={ValidatedInput}
                                                label="Customer segment"
                                                labelClassName="k-h3 !k-mb-3"
                                                inputType={BoxItemsPicker}
                                                items={customerSegments}
                                                emptyText="No customer segments available"
                                                itemsSpace={2}
                                            />
                                        </>
                                    ))}

                                {showAdditionalFields && (
                                    <>
                                        <div className="full-horizontal-separator" />
                                        <div>
                                            <Label className="k-h3 !k-mb-3">Social</Label>
                                            <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2">
                                                <ContactSocialEditor fieldsSuffix="Dialog" />
                                            </StackLayout>
                                        </div>
                                        <div className="full-horizontal-separator" />
                                        <Field
                                            name="tagsDialog"
                                            component={ValidatedInput}
                                            label="Tags"
                                            inputType={ContactTagsPicker}
                                            ideaId={ideaId}
                                            labelClassName="k-h3 !k-mb-3"
                                            placeholder="Type to select or create tag..."
                                        />
                                    </>
                                )}

                                <Button
                                    type="button"
                                    size="small"
                                    fillMode="link"
                                    themeColor="secondary"
                                    className="k-align-self-start"
                                    onClick={() => setShowAdditionalFields(s => !s)}
                                >
                                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                                        {showAdditionalFields ? 'Hide additional information' : 'Add more information'}
                                        {showAdditionalFields ? (
                                            <CollapseIcon className="k-icp-icon k-icp-icon-size-4" />
                                        ) : (
                                            <ExpandIcon className="k-icp-icon k-icp-icon-size-4" />
                                        )}
                                    </StackLayout>
                                </Button>
                            </StackLayout>
                        </div>

                        <DialogActionsBar layout="center">
                            <Button disabled={!formRenderProps.allowSubmit || createDisabled} type="submit" themeColor="primary">
                                Save contact
                            </Button>
                            <Button type="reset" onClick={onCancel}>
                                Cancel
                            </Button>
                        </DialogActionsBar>
                    </FormElement>
                )}
            />
        </Dialog>
    );
});

function BoxItemsPicker({
    id,
    value,
    items,
    onChange,
    emptyText,
    className,
    itemsSpace
}: {
    id?: string;
    value?: number[];
    items?: BoxItem[];
    onChange: (e: { value: number[] | undefined }) => void;
    emptyText?: string;
    className?: string;
    itemsSpace?: number;
}) {
    function ensureItemSelectedStatus(itemId: number, selected: boolean) {
        if (selected) {
            if (!value) onChange({ value: [itemId] });
            else if (!value.includes(itemId)) onChange({ value: [...value, itemId] });
        } else {
            if (!value || !value.includes(itemId)) return;

            const newValue = value.filter(i => i !== itemId);
            if (newValue.length) onChange({ value: newValue });
            else onChange({ value: undefined });
        }
    }

    return (
        <div className={className}>
            {items ? (
                items.length ? (
                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className={`k-gap-${itemsSpace || 4}`}>
                        {items.map(i => (
                            <StackLayout key={i.id} align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                                <Checkbox
                                    id={`${id ?? ''}_cb_${i.id}`}
                                    checked={value ? value.includes(i.id) : false}
                                    onChange={e => ensureItemSelectedStatus(i.id, e.value)}
                                />
                                <label className="k-checkbox-label" htmlFor={`${id ?? ''}_cb_${i.id}`}>
                                    <CanvasItemSimpleView item={i} />
                                </label>
                            </StackLayout>
                        ))}
                    </StackLayout>
                ) : (
                    emptyText || 'No items'
                )
            ) : (
                <LoadingIndicator size="big" className="-block-center" />
            )}
        </div>
    );
}

export function ContactTagsPicker({
    ideaId,
    value,
    onChange,
    id,
    placeholder,
    allowCreate = true
}: {
    ideaId: string;
    value?: ContactTag[];
    onChange?: (e: { value: ContactTag[] | undefined }) => void;
    id?: string;
    placeholder?: string;
    allowCreate?: boolean;
}) {
    const [loading, setLoading] = useState(false);
    const [loadedTags, setLoadedTags] = useState<ContactTag[]>();
    const latestFilterRef = useRef<string>();

    const loadTags = useCallback(
        async (filter?: string) => {
            latestFilterRef.current = filter;
            setLoading(true);
            const foundTags = await contactsService.getTags(ideaId, filter ? filter : undefined, filter ? undefined : 5);
            if (latestFilterRef.current !== filter) return;
            setLoading(false);
            setLoadedTags(foundTags);
        },
        [ideaId]
    );
    const loadTagsDebounced = useMemo(() => debounce(loadTags, 300), [loadTags]);

    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;
    const valueRef = useRef(value);
    valueRef.current = value;

    useEffect(() => {
        async function onTagUpdated(tagId: number) {
            if (!onChangeRef.current || !valueRef.current || !valueRef.current.some(t => t.id === tagId)) return;

            const updatedTag = await contactsService.getTag(ideaId, tagId);
            if (!valueRef.current) return;

            const updatedTagIndex = valueRef.current.findIndex(t => t.id === tagId);
            if (updatedTagIndex === -1) return;

            const updatedValue = [...valueRef.current];
            updatedValue.splice(updatedTagIndex, 1, updatedTag);

            onChangeRef.current({ value: updatedValue });
        }

        function onTagDeleted(tagId: number) {
            if (!onChangeRef.current || !valueRef.current || !valueRef.current.some(t => t.id === tagId)) return;

            onChangeRef.current({ value: valueRef.current.filter(t => t.id !== tagId) });
        }

        function onTagUpdatedInTab(tagData: TagEventData) {
            onTagUpdated(tagData.tagId);
        }

        function onTagDeletedInTab(tagData: TagEventData) {
            onTagDeleted(tagData.tagId);
        }

        function onTagUpdatedExternally(e: RealTimeUpdateContactTagEventData) {
            if (e.ideaId !== ideaId) return;
            onTagUpdated(e.tagId);
        }

        function onTagDeletedExternally(e: RealTimeUpdateContactTagEventData) {
            if (e.ideaId !== ideaId) return;
            onTagDeleted(e.tagId);
        }

        appEventHub.addEventListener('tag', 'updated', onTagUpdatedInTab);
        appEventHub.addEventListener('tag', 'deleted', onTagDeletedInTab);
        realTimeUpdatesEventHub.addEventListener('contact', 'tagUpdate', onTagUpdatedExternally);
        realTimeUpdatesEventHub.addEventListener('contact', 'tagDelete', onTagDeletedExternally);

        return () => {
            appEventHub.removeEventListener('tag', 'updated', onTagUpdatedInTab);
            appEventHub.removeEventListener('tag', 'deleted', onTagDeletedInTab);
            realTimeUpdatesEventHub.removeEventListener('contact', 'tagUpdate', onTagUpdatedExternally);
            realTimeUpdatesEventHub.removeEventListener('contact', 'tagDelete', onTagDeletedExternally);
        };
    }, [ideaId, loadTags]);

    return (
        <MultiSelect
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={
                onChange
                    ? async e => {
                          if (!e.value || !e.value.length) {
                              onChange({ value: undefined });
                              return;
                          }

                          if (allowCreate) {
                              const newValue = await Promise.all(
                                  (e.value as ContactTag[]).map(async t => {
                                      if (typeof t.id === 'undefined') {
                                          setLoading(true);
                                          return contactsService.ensureTag(ideaId, t.name);
                                      }

                                      return t;
                                  })
                              );
                              const newValueWithoutDuplicates = newValue.filter(function(t, index) {
                                  return newValue.findIndex(t2 => t2.id === t.id) === index;
                              });

                              onChange({ value: newValueWithoutDuplicates });
                          } else {
                              onChange({ value: e.value });
                          }

                          setLoading(false);
                      }
                    : undefined
            }
            textField="name"
            dataItemKey="id"
            loading={loading}
            tagRender={(tagData, tagElement) =>
                React.cloneElement(
                    tagElement,
                    {
                        ...tagElement.props,
                        removable: false,
                        className: combineClassNames(tagElement.props.className, 'k-icp-chip-solid-base-not-interactive')
                    },
                    <>
                        <span className="k-mr-1">{tagElement.props.text}</span>
                        <Button
                            type="button"
                            fillMode="flat"
                            size="small"
                            className="k-icp-svg-icon-button !k-p-0 !k-border-0"
                            onClick={tagElement.props.onRemove}
                        >
                            <RemoveIcon className="k-icp-icon" />
                        </Button>
                    </>
                )
            }
            data={loadedTags}
            allowCustom={allowCreate}
            filterable={true}
            onFilterChange={e => loadTagsDebounced(e.filter.value || undefined)}
            onOpen={() => {
                if (!loadedTags) setLoading(true);
                loadTagsDebounced(latestFilterRef.current);
            }}
            listNoDataRender={e => React.cloneElement(e, undefined, <div>{loading ? <LoadingIndicator size="small" /> : 'NO TAGS FOUND.'}</div>)}
        />
    );
}

function ManageContactsTagsButton({ ideaId }: { ideaId: string }) {
    const [showTagsManagementDialog, setShowTagsManagementDialog] = useState(false);

    return (
        <>
            <Button type="button" size="small" fillMode="flat" themeColor="secondary" onClick={() => setShowTagsManagementDialog(s => !s)}>
                Manage all tags
            </Button>

            {showTagsManagementDialog && <ContactsTagsManageDialog ideaId={ideaId} onClose={() => setShowTagsManagementDialog(false)} />}
        </>
    );
}

type ContactEditableTagStats = ContactTagStats & { isEditing: boolean };
const newTagId = -1;
function ContactsTagsManageDialog({ ideaId, onClose }: { ideaId: string; onClose: () => void }) {
    const [allTags, setAllTags] = useState<ContactEditableTagStats[]>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        contactsService.getTagsStats(ideaId).then(tags =>
            setAllTags(
                tags.map<ContactEditableTagStats>(t => ({ ...t, isEditing: false }))
            )
        );
    }, [ideaId]);

    function insertTag(newTag: ContactEditableTagStats) {
        setAllTags(tags => {
            if (!tags) return [newTag];

            return [newTag, ...tags.filter(t => t.id !== newTagId).map(t => (t.isEditing ? { ...t, isEditing: false } : t))];
        });
    }

    function addTag() {
        insertTag({ id: newTagId, name: '', contactCount: 0, isEditing: true });
    }

    function onTagCreated(tag: ContactTag) {
        insertTag({ ...tag, isEditing: false, contactCount: 0 });
        appEventHub.trigger('tag', 'created', { tagId: tag.id });
    }

    function onTagUpdated(tag: ContactTag) {
        setAllTags(tags => {
            if (!tags) return tags;

            return tags.map(t => (t.id === tag.id ? { ...t, ...tag, isEditing: false } : t));
        });

        appEventHub.trigger('tag', 'updated', { tagId: tag.id });
    }

    function cancelEdit() {
        setAllTags(tags => (tags ? tags.filter(t => t.id !== newTagId).map(t => (t.isEditing ? { ...t, isEditing: false } : t)) : tags));
    }

    function editTag(tagId: number) {
        setAllTags(tags => {
            if (!tags) return tags;

            return tags.filter(t => t.id !== newTagId).map(t => (t.id === tagId ? { ...t, isEditing: true } : t.isEditing ? { ...t, isEditing: false } : t));
        });
    }

    async function deleteTag(tagId: number) {
        const tagToDeleteIndex = allTags?.findIndex(t => t.id === tagId);
        const tagFoundInCollection = typeof tagToDeleteIndex !== 'undefined' && tagToDeleteIndex !== -1;
        let deletedTag: ContactEditableTagStats | undefined;
        if (tagFoundInCollection && allTags) {
            deletedTag = allTags[tagToDeleteIndex];
            setAllTags(allTags.filter(t => t.id !== tagId));
        }
        await contactsService.deleteTag(ideaId, tagId);
        appEventHub.trigger('tag', 'deleted', { tagId: tagId });
        dispatch(
            addNotification({ content: 'Tag deleted.', actionText: 'Undo' }, async () => {
                const restoredTag = contactsService.restoreTag(ideaId, tagId);
                appEventHub.trigger('tag', 'restored', { tagId: tagId });
                if (tagFoundInCollection) {
                    const restoredItem = { ...deletedTag!, ...restoredTag };
                    setAllTags(tags => {
                        if (!tags) return [restoredItem];

                        const tagsWithRestoredItem = [...tags];
                        tagsWithRestoredItem.splice(tagToDeleteIndex, 0, restoredItem);

                        return tagsWithRestoredItem;
                    });
                }
            })
        );
    }

    const allTagsRef = useRef(allTags);
    allTagsRef.current = allTags;
    useEffect(() => {
        async function onTagChange(e: RealTimeUpdateContactTagEventData) {
            if (e.ideaId !== ideaId) return;

            const freshTags = await contactsService.getTagsStats(ideaId);
            const currentTags = allTagsRef.current;
            const tagsIdsInEdit = currentTags?.filter(t => t.isEditing).map(t => t.id);
            const insertingTag = currentTags?.find(t => t.id === newTagId);
            const newAllTags: ContactEditableTagStats[] = [];
            if (insertingTag) newAllTags.push(insertingTag);
            freshTags.forEach(t => newAllTags.push({ ...t, isEditing: typeof tagsIdsInEdit !== 'undefined' && tagsIdsInEdit.includes(t.id) }));
            setAllTags(newAllTags);
        }

        realTimeUpdatesEventHub.addEventListener('contact', 'tagAdd', onTagChange);
        realTimeUpdatesEventHub.addEventListener('contact', 'tagUpdate', onTagChange);
        realTimeUpdatesEventHub.addEventListener('contact', 'tagDelete', onTagChange);
        realTimeUpdatesEventHub.addEventListener('contact', 'tagRestore', onTagChange);

        return () => {
            realTimeUpdatesEventHub.removeEventListener('contact', 'tagAdd', onTagChange);
            realTimeUpdatesEventHub.removeEventListener('contact', 'tagUpdate', onTagChange);
            realTimeUpdatesEventHub.removeEventListener('contact', 'tagDelete', onTagChange);
            realTimeUpdatesEventHub.removeEventListener('contact', 'tagRestore', onTagChange);
        };
    }, [ideaId]);

    return (
        <Dialog title="Manage tags" closeIcon={true} onClose={onClose} width={640} className="manage-tags-dialog">
            {allTags ? (
                allTags.length ? (
                    <Grid
                        data={allTags}
                        className="k-grid-no-scrollbar k-icp-grid-no-header -maxh100"
                        dataItemKey="id"
                        rowRender={(row, props) => {
                            if (!props.isInEdit || props.rowType !== 'data') return row;

                            const tag: ContactEditableTagStats = props.dataItem;
                            const isNewTag = tag.id === newTagId;
                            return React.cloneElement(
                                row,
                                undefined,
                                <td colSpan={props.children instanceof Array ? props.children.length : undefined}>
                                    <TagUpsertForm
                                        ideaId={ideaId}
                                        tagId={isNewTag ? undefined : tag.id}
                                        name={tag.name}
                                        onSave={isNewTag ? onTagCreated : onTagUpdated}
                                        onCancel={cancelEdit}
                                    />
                                </td>
                            );
                        }}
                        editField="isEditing"
                        onItemChange={e => {
                            if (e.field !== 'command') return;
                            const tag: ContactEditableTagStats = e.dataItem;
                            if (e.value === 'edit') editTag(tag.id);
                            else if (e.value === 'delete') deleteTag(tag.id);
                        }}
                    >
                        <GridToolbar>
                            <Button onClick={addTag}>
                                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                                    <AddIcon className="k-icp-icon k-icp-icon-size-4" /> Add new tag
                                </StackLayout>
                            </Button>
                        </GridToolbar>
                        <GridColumn field="name" />
                        <GridColumn cell={TagContactsCountGridCellTemplate} />
                        <GridColumn cell={ContactTagActionsCellTemplate} width={100} className="k-icp-actions-column" />
                    </Grid>
                ) : (
                    <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'middle' }} className="-h100">
                        <img src={tagImageUrl} width="86" height="87" alt="No tags" className="responsive-image" />
                        <P className="!k-mb-4">No tags available</P>
                        <Button onClick={addTag} fillMode="outline" themeColor="secondary">
                            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                                <AddIcon className="k-icp-icon k-icp-icon-size-4" /> Add new tag
                            </StackLayout>
                        </Button>
                    </StackLayout>
                )
            ) : (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            )}

            <DialogActionsBar layout="center">
                <Button type="button" onClick={onClose}>
                    Done
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}

const TagContactsCountGridCellTemplate = createCellTemplateFromComponent(function({ dataItem: tag }: CellTemplateProps<ContactEditableTagStats>) {
    return (
        <span className="k-fs-sm k-icp-subtle-text">
            {tag.contactCount} contact{tag.contactCount !== 1 ? 's' : ''} with this tag
        </span>
    );
});

const tagnameValidators = [requiredValidator('Name'), maxLengthValidator('Name', 50)];
function TagUpsertForm({
    ideaId,
    tagId,
    name,
    onSave,
    onCancel
}: {
    ideaId: string;
    tagId?: number;
    name: string;
    onSave: (tag: ContactTag) => void;
    onCancel: () => void;
}) {
    const [createDisabled, createTagCallbackCreator] = useSingleClickButton();

    return (
        <Form
            onSubmit={createTagCallbackCreator(async (data: Record<string, any>) => {
                const tagName = data.name;
                const savedTag = tagId ? await contactsService.updateTag(ideaId, tagId, tagName) : await contactsService.createTag(ideaId, tagName);
                onSave(savedTag);
            })}
            ignoreModified={true}
            initialValues={{ name }}
            render={formRenderProps => (
                <FormElement>
                    <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
                        <Field
                            name="name"
                            component={ValidatedInput}
                            validator={tagnameValidators}
                            maxLength={50}
                            placeholder="Add tag name..."
                            wrapperClass="k-flex-1 k-mr-3"
                            disabled={createDisabled}
                        />

                        <Button disabled={!formRenderProps.allowSubmit || createDisabled} type="submit">
                            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                                <CreateIcon className="k-icp-icon k-icp-icon-size-4" /> {tagId ? 'Update' : 'Create'}
                            </StackLayout>
                        </Button>
                        <Button type="reset" onClick={onCancel} disabled={createDisabled}>
                            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1">
                                <RemoveIcon className="k-icp-icon k-icp-icon-size-4" /> Cancel
                            </StackLayout>
                        </Button>
                    </StackLayout>
                </FormElement>
            )}
        />
    );
}

const ContactTagActionsCellTemplate = createCellTemplateFromComponent(function({ cellProps }: CellTemplateProps<ContactEditableTagStats>) {
    const onChange = cellProps.onChange;
    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-3">
            <Button
                className="k-icp-svg-icon-button"
                onClick={
                    onChange
                        ? e => {
                              onChange({ dataItem: cellProps.dataItem, dataIndex: cellProps.dataIndex, syntheticEvent: e, field: 'command', value: 'edit' });
                          }
                        : undefined
                }
            >
                <EditIcon className="k-icp-icon" />
            </Button>
            <Button
                className="k-icp-svg-icon-button"
                onClick={
                    onChange
                        ? e => {
                              onChange({
                                  dataItem: cellProps.dataItem,
                                  dataIndex: cellProps.dataIndex,
                                  syntheticEvent: e,
                                  field: 'command',
                                  value: 'delete'
                              });
                          }
                        : undefined
                }
            >
                <DeleteIcon className="k-icp-icon" />
            </Button>
        </StackLayout>
    );
});
