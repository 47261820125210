import { RadioGroup } from '@progress/kendo-react-inputs';
import { ReactNode } from 'react';
import { BoxItem } from '../../services/canvasService';
import { FormFieldProps } from '../ui/inputs';
import LoadingIndicator from '../ui/loadingIndicator';
import { CanvasItemSimpleView } from './canvasItemSimpleView';

export function CanvasItemFromBoxPicker({
    id,
    value,
    items,
    onChange,
    valid,
    disabled,
    emptyView
}: FormFieldProps<number> & {
    items?: BoxItem[];
    emptyView?: ReactNode;
}) {
    return (
        <div id={id}>
            {items ? (
                items.length ? (
                    <RadioGroup
                        valid={valid}
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                        data={items.map(i => {
                            const itemId = `${id ?? ''}_rb_${i.id}`;
                            return {
                                id: itemId,
                                value: i.id,
                                children: (
                                    <label className="k-radio-label" htmlFor={itemId}>
                                        <CanvasItemSimpleView item={i} />
                                    </label>
                                ),
                                className: '!k-mt-thin k-align-self-flex-start'
                            };
                        })}
                        className="!k-gap-2 k-radio-list-condensed"
                    />
                ) : (
                    emptyView || 'No items'
                )
            ) : (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            )}
        </div>
    );
}
