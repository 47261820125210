import { StackLayout, StackLayoutHandle, StackLayoutProps } from '@progress/kendo-react-layout';
import { useEffect, useRef } from 'react';

export function ClickableStackLayout({ onClick, ...props }: StackLayoutProps & { onClick?: (e: MouseEvent) => void }) {
    const stackLayoutRef = useRef<StackLayoutHandle>(null);
    useEffect(() => {
        if (!onClick || !stackLayoutRef.current || !stackLayoutRef.current.element) return;

        const currentElement = stackLayoutRef.current.element;
        currentElement.addEventListener('click', onClick);

        return () => currentElement.removeEventListener('click', onClick);
    }, [onClick]);

    return <StackLayout ref={stackLayoutRef} {...props} />;
}
